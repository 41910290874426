import React, {useEffect} from 'react';
import {Row, Col, Alert} from 'reactstrap';
import './midtdel.css';
import DataFiltrering from '../datafiltrering/DataFiltrering';
import {KrysstabellContainer} from "../krysstabell/KrysstabellContainer";
import {InformasjonstekstContainer} from '../informasjonstekst/Informasjonstekst';
import {Datasett} from '../datasett/DatasettInnhold';
import {RaderOgKolonner} from '../raderogkolonner'
import {FilterOversikt} from './FilterOversiktContainer'
import IngenTreff from './IngenTreff';
import {useDispatch, useSelector} from "react-redux";
import {selectStatusKode} from "./midtdelSelector";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {selectAlert} from "../appSelector";
import NullstillFilterKnapp from "../datafiltrering/NullstillFilterKnapp";
import {setValgtSide} from "../../actions";


/**
 * Midtel er en placeholder for Datasett, Datafiltrering og Krysstabell.
 * Her skal brukeren kunne filtrere data og velge hva slags data som brukeren
 * ønsker i krysstabellen.
 */
const Midtdel = () => {
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(setValgtSide("hovedside"));
    });

    const tilbakemeldingBruker = useSelector(selectStatusKode);
    const alert = useSelector(selectAlert)

    return (
        <div>
          <Row>
            <InformasjonstekstContainer/>
          </Row>
          <Row className='tabelloppsett-konteiner'>
            <Col className="midtdel" xl="3" lg="3" md="12" sm="12" xs="12">
              <section aria-label="Velg tabelloppsett">
              <h2 className="midtdel-tittel heading-decoration">Velg tabelloppsett</h2>
              <Row>
                <Col lg={{size: 12}} className="midtdel-forste-rad">
                  <Datasett/>
                </Col>
              </Row>
              <Row>
                <Col lg={{size: 12}} className="midtdel-rad">
                  <RaderOgKolonner/>
                </Col>
              </Row>
              <Row>
                <Col lg={{size: 12}} className="midtdel-rad">
                  <fieldset>
                      <legend style={{marginBottom: 0}}>
                          <h3 style={{marginTop: "1rem"}}>3. Velg filter</h3>
                      </legend>
                      <p id="filtrering-tekst"><i>Her kan du filtrere på hvilke ulykker du ønsker informasjon om.</i></p>
                      <div className="nullstill-filter-konteiner"><NullstillFilterKnapp/></div>
                      <DataFiltrering />
                  </fieldset>
                </Col>
              </Row>
              </section>
            </Col>
            <Col className="midtdel-main pr-0" xl="9" lg="9" md="12" sm="12" xs="12">
            <section aria-label="Din tabell"  className="midtdel-fix">
                <h2 className="mb-4 heading-decoration">Din tabell</h2>
                {tilbakemeldingBruker !== null ?
                  <IngenTreff tekst={tilbakemeldingBruker}/> :
                  (<React.Fragment>
                    <FilterOversikt/>
                    <KrysstabellContainer/>
                    {alert &&
                    <div className="alertBoks">
                      <Alert className="alert-system" isOpen={alert.melding} color={alert.farge}> <FontAwesomeIcon icon={alert.ikon} color={alert.ikonFarge}/>{alert.melding} </Alert>
                    </div>
                    }
                  </React.Fragment>)
                }
              </section>
            </Col>
          </Row>
        </div>
    );
}

export default Midtdel;