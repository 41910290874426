import {
    HENT_DEFINISJONER_ETO,
    HENT_FEATURE_TOGGLE,
    HENT_KODEVERK,
    HENT_OM_STATISTIKKEN_ETO,
    LAST_NED_TABELL,
    RESET_SOKETO,
    SEND_BRUKER_ETO,
    SEND_SOK_ETO,
    SEND_TILBAKEMEDLING,
    SET_ALERT,
    SET_BRUKER,
    SET_DATASETT,
    SET_DEFINISJONER_ETO,
    SET_FEATURE_TOGGLE,
    SET_FILTER_NULLSTILT,
    SET_FILTER_VERDI,
    SET_FILTER_VISES_I_TABELL,
    SET_INFO_TIL_BRUKER,
    SET_KODEVERK,
    SET_KOLONNE,
    SET_LOADING,
    SET_OM_STATISTIKKEN_ETO,
    SET_OM_STATISTIKKEN_VALGT,
    SET_RAD_EN,
    SET_RAD_TO,
    SET_RAD_TRE,
    SET_SOK_ETO_RESULTAT,
    SET_TAKK_TILBAKEMELDING,
    SET_VALGT_FILTER,
    RESET_FILTER_NULLSTILT,
    SET_INFOTEKST,
    HENT_INFOTEKST,
    SET_TRINE_VEDLIKEHOLD_BANNER,
    HENT_TRINE_VEDLIKEHOLD_BANNER,
    SET_TRINE_VEDLIKEHOLD_TEKST,
    HENT_TRINE_VEDLIKEHOLD_TEKST,
    SET_VIS_TILBAKEMELDINGMODAL, SET_VALGT_SIDE,
} from "./actionTypes";
import {SETT_AR, SETT_FYLKE, SETT_KOMMUNE} from '../components/standardrapporter/redux/actions';


export const hentKodeverk = () => (
  {type: HENT_KODEVERK}
);

export const hentFeatureToggle = () => (
  {type: HENT_FEATURE_TOGGLE}
);

export const hentTrineVedlikeholdBanner = () => (
    {type: HENT_TRINE_VEDLIKEHOLD_BANNER}
);

export const hentTrineVedlikeholdTekst = () => (
    {type: HENT_TRINE_VEDLIKEHOLD_TEKST}
);

export const setKodeverk = data => ({
  type: SET_KODEVERK, data
});

export const setFeatureToggle = data => ({
  type: SET_FEATURE_TOGGLE, data
});

export const setTrineVedlikeholdBanner = data => ({
    type: SET_TRINE_VEDLIKEHOLD_BANNER, data
});

export const setTrineVedlikeholdTekst = data => ({
   type: SET_TRINE_VEDLIKEHOLD_TEKST, data
});

export function setFilterVerdi(kategori, etoNavn, dataUsortert) {
  let data = dataUsortert;
  if (data) {
    data.sort((a, b) => (a.kode > b.kode) ? 1 : -1); // sorterer data basert på kode
    if(etoNavn === "ar") {
      data.reverse();
    }
  }
  return {
    type: SET_FILTER_VERDI,
    kategori,
    etoNavn,
    data,
  };
}

export function setFilterNullstilt(nullstilt) {
  return {
    type: SET_FILTER_NULLSTILT,
    data: nullstilt
  };
}

export function setRapportFilterVerdiAr(kategori, etoNavn, data) {
  return {
    type: SETT_AR,
    kategori,
    etoNavn,
    data,
  };
}

export function setRapportFilterVerdiKommune(kategori, etoNavn, data) {
  return {
    type: SETT_KOMMUNE,
    kategori,
    etoNavn,
    data,
  };
}

export function setRapportFilterVerdiFylke(kategori, etoNavn, data) {
  return {
    type: SETT_FYLKE,
    kategori,
    etoNavn,
    data,
  };
}

export const resetSokEto = () => ({
  type: RESET_SOKETO
});

export const resetFilterNullstilt = () => ({
  type: RESET_FILTER_NULLSTILT
});

export const setRadEn = data => ({
  type: SET_RAD_EN,
  data
});

export const setRadTo = data => ({
  type: SET_RAD_TO,
  data
});

export const setRadTre = data => ({
  type: SET_RAD_TRE,
  data
});

export const setKolonne = data => ({
  type: SET_KOLONNE,
  data
});

export const setDatasett = data => ({
  type: SET_DATASETT,
  data,
});

export const sendSokETO = (sokETO) => ({
  type: SEND_SOK_ETO,
  sokETO
});

export const setSokETOResultat = data => ({
  type: SET_SOK_ETO_RESULTAT,
  data
});

export const hentOmStatistikkenETO = () => ({
  type: HENT_OM_STATISTIKKEN_ETO
});

export const setOmStatistikkenETO = data => ({
  type: SET_OM_STATISTIKKEN_ETO,
  data
});

export const hentDefinisjonerETO = () => ({
  type: HENT_DEFINISJONER_ETO
});

export const setDefinisjonerETO = data => ({
  type: SET_DEFINISJONER_ETO,
  data
});

export const hentInformasjonstekst = () => ({
   type: HENT_INFOTEKST
});

export const setInformasjonstekst = data => ({
    type: SET_INFOTEKST,
    data
});

export const setOmStatistikkenValgt = data => ({
  type: SET_OM_STATISTIKKEN_VALGT,
  data
});

export const setInfoTilBruker = data => ({
  type: SET_INFO_TIL_BRUKER,
  data
});

export function setValgtFilter(navn, data) {
  return {   type: SET_VALGT_FILTER,
    navn, data };
}

export const setFilterVisesITabell = (data) => ({
  type: SET_FILTER_VISES_I_TABELL,
  data
});

export const setBruker = data => ({
  type: SET_BRUKER,
  data,
});

export const sendBrukerETO = data => ({
  type: SEND_BRUKER_ETO,
  data,
});

export const lastNedSokETOResultat = (sokETOResultat, filType) => ({
  type: LAST_NED_TABELL,
  sokETOResultat, filType
});


export const sendTilbakemelding = (tekst, epost) => ({
  type: SEND_TILBAKEMEDLING,
  tekst, epost
});

export const setLoading = loading => ({
  type: SET_LOADING,
  loading
});

export const setAlert = (melding, farge, ikon, ikonFarge) => ({
  type: SET_ALERT,
  melding,
  farge,
  ikon,
  ikonFarge
});

export const setTakkTilbakemelding = (vis) => ({
  type: SET_TAKK_TILBAKEMELDING,
  vis
});

export const setVisTilbakemeldingmodal = (vis) => ({
  type: SET_VIS_TILBAKEMELDINGMODAL,
  vis
});

export const setValgtSide = (side) => ({
    type: SET_VALGT_SIDE,
    side
})