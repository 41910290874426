import {connect} from 'react-redux';
import FilterNedtrekksliste from '../standardkomponenter/FilterNedtrekksliste';
import {
  FARTSGRENSER,
  FARTSGRENSER_ETO_NAVN,
  FYSISK_MIDTDELER,
  FYSISK_MIDTDELER_ETO_NAVN,
  KJOREFELTTYPE,
  KJOREFELTTYPE_ETO_NAVN,
  REGULERING_I_GANGFELT,
  REGULERING_I_GANGFELT_ETO_NAVN,
  REGULERING_I_VEGKRYSS,
  REGULERING_I_VEGKRYSS_ETO_NAVN,
  STEDSFORHOLD,
  STEDSFORHOLD_ETO_NAVN,
  ULYKKE_ETO_NAVN,
  VEGDEKKE,
  VEGDEKKE_ETO_NAVN,
  VEGKATEGORI,
  VEGKATEGORI_ETO_NAVN,
  VEGTYPER,
  VEGTYPER_ETO_NAVN
} from "../../ressurser/Konstanter";
import {setFilterVerdi, setValgtFilter} from "../../actions";
import {
  selectFartsgrenser,
  selectKodeverkFysiskMidtdeler,
  selectKodeverkKjorefeltstype,
  selectKodeverkStedsforhold,
  selectKodeverkVegdekke,
  selectKodeverkVegkategorier,
  selectKodeverkVegtype,
  selectReguleringIGangfelt,
  selectReguleringIVegkryss
} from "./datafiltreringSelector";

/** Veginnhold knytter FilterNedtrekksliste definert i ./standardkomponenter/filternedtrekksliste sammen med
 * redux store. For hver nedtrekksliste inneholder filen en funkjson for dispatch til redux store og en fil
 * funksjon for å hente ut staten valgt i redux store. */


const ulykkeFilterDispatch = dispatch => ({
  setFilter: (etoNavn, data) => {
    dispatch(setFilterVerdi(ULYKKE_ETO_NAVN, etoNavn, data));
  },
  setValg: (navn, valg) => {
    dispatch(setValgtFilter(navn, valg));
  }
});


const stedsforholdState = state => ({
    navn: STEDSFORHOLD,
    etoNavn: STEDSFORHOLD_ETO_NAVN,
    erMulti: true,
    valgmuligheter: selectKodeverkStedsforhold(state)
});
export const Stedsforhold = connect(
    stedsforholdState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const vegkategoriState = state => ({
  navn: VEGKATEGORI,
  etoNavn: VEGKATEGORI_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectKodeverkVegkategorier(state)
});
export const Vegkategori = connect(
    vegkategoriState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const vegdekkeState = state => ({
  navn: VEGDEKKE,
  etoNavn: VEGDEKKE_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectKodeverkVegdekke(state)
});
export const Vegdekke = connect(
    vegdekkeState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const vegtypeState = state => ({
  navn: VEGTYPER,
  etoNavn: VEGTYPER_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectKodeverkVegtype(state)
});
export const Vegtyper = connect(
    vegtypeState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const kjorefeltstypeState = state => ({
  navn: KJOREFELTTYPE,
  etoNavn: KJOREFELTTYPE_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectKodeverkKjorefeltstype(state)
});
export const Kjorefeltstype = connect(
    kjorefeltstypeState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const fysiskmidtdelerState = state => ({
  navn: FYSISK_MIDTDELER,
  etoNavn: FYSISK_MIDTDELER_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectKodeverkFysiskMidtdeler(state)
});
export const FysiskMidtdeler = connect(
    fysiskmidtdelerState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const reguleringivegkryssState = state => ({
  navn: REGULERING_I_VEGKRYSS,
  etoNavn: REGULERING_I_VEGKRYSS_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectReguleringIVegkryss(state)
});
export const ReguleringIVegkryss = connect(
    reguleringivegkryssState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const reguleringigangfeltState = state => ({
  navn: REGULERING_I_GANGFELT,
  etoNavn: REGULERING_I_GANGFELT_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectReguleringIGangfelt(state)
});
export const ReguleringIGangfelt = connect(
    reguleringigangfeltState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const fartsgrenserState = state => ({
  navn: FARTSGRENSER,
  etoNavn: FARTSGRENSER_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectFartsgrenser(state),
  visUtenKode: true
});
export const Fartsgrense = connect(
    fartsgrenserState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);