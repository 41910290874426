import React, { useEffect, useState, useCallback} from 'react';
import {Col, Row} from 'reactstrap';
import './standardrapporterSide.css';
import Rapportsok from './Rapportsok';
import Rapportliste from './rapportlister/Rapportliste';
import {
  arsrapporter,
  enhetsrapporter,
  kanBestillesArsrapporter,
  kanBestillesEnhetsrapporter, kanBestillesStedsforholdrapporter, kanBestillesTemarapporter, kanBestillesUlykkestyperapporter,
  stedsforholdrapporter,
  temarapporter,
  ulykkestyperapporter
} from './rapportlister/rapportLister';
import {STANDARDRAPPORTER_INFO, STANDARDRAPPORTER_OVERSKRIFT} from "../../ressurser/Konstanter";
import Tilbakeknapp from '../uukomponenter/Tilbakeknapp';
import {setValgtSide} from "../../actions";
import {useDispatch} from "react-redux";

const StandardrapporterSide = () => {

  const [sokUtfort, setSokUtfort] = useState(false);
  const dispatch = useDispatch();

  useEffect(()=>{
    document.title = "Standardrapporter | Trine";
    dispatch(setValgtSide("standardrapporter"));
  })

  const fokuserOverskrift = useCallback((overskriftElement) => {
    if (overskriftElement) {
      overskriftElement.focus();
    }
  });


    return (
    <>
      <div>
        <Tilbakeknapp />
      </div>
        <div>
          <Row>
            <Col xl = {{size: "12"}} lg ={{size: "8"}} md={{size: "8"}}>
              <h1 tabIndex="-1" ref={fokuserOverskrift} className="standardrapporteroverskrift">{STANDARDRAPPORTER_OVERSKRIFT}</h1>
              <p>{STANDARDRAPPORTER_INFO}</p>
            </Col>
          </Row>
          <Row className="m-4">
            <Col xl={3} className="velg-ar-og-fylke-konteiner">
              <Rapportsok sokUtfort={sokUtfort} setSokUtfort={(e) => setSokUtfort(e)}/>
            </Col>
            <Col xl={9}>
              <Row>
                <Rapportliste
                  overskrift="Årsrapporter"
                  rapportliste={arsrapporter}
                  sokUtfort={sokUtfort}
                  kanBestilles={kanBestillesArsrapporter}
                />
                <Rapportliste
                  overskrift="Rapporter enhetsgrupper"
                  rapportliste={enhetsrapporter}
                  sokUtfort={sokUtfort}
                  kanBestilles={kanBestillesEnhetsrapporter}
                />
                <Rapportliste
                  overskrift="Rapporter stedsforhold og strekninger"
                  rapportliste={stedsforholdrapporter}
                  sokUtfort={sokUtfort}
                  kanBestilles={kanBestillesStedsforholdrapporter}
                />
              </Row>
              <Row>
                <Rapportliste
                  overskrift="Rapporter ulykkestyper"
                  rapportliste={ulykkestyperapporter}
                  sokUtfort={sokUtfort}
                  kanBestilles={kanBestillesUlykkestyperapporter}
                />
                <Rapportliste
                  overskrift="Andre temarapporter"
                  rapportliste={temarapporter}
                  sokUtfort={sokUtfort}
                  kanBestilles={kanBestillesTemarapporter}
                />
              </Row>
            </Col>
          </Row>
        </div>
    </>
    );
  }


export default StandardrapporterSide;