import React from 'react';
import './persontypeKnapp.css';
import PropTypes from 'prop-types';

/**
 * Knapp som viser en tittel og et ikon. Returnerer handterklikk om knapp trykkes.
 */
export default function PersontypeKnapp({ tittel, handterKlikk, ikon }) {
  return (
        <button className="button--secondary button--large persontypeknapp" onClick={handterKlikk} title={tittel}>
          <span className='ikonwrapper'><img className="persontypeknapp-ikon" src={ikon} alt="" width="40px"/></span>
          <span className='tittel'>{tittel}</span>
      </button>
  );
}
PersontypeKnapp.propTypes = {
  tittel: PropTypes.string.isRequired,
  handterKlikk: PropTypes.func.isRequired,
  ikon: PropTypes.string.isRequired,
  ikonTekst: PropTypes.string
};
