import {lastNedSokETOResultat} from "../../actions";
import {connect} from "react-redux";
import LastNedTabell from './LastNedTabell'
import {LAST_NED_TABELL,} from "../../actions/actionTypes";
import {selectSokETO, selectSokETOResultat} from "../datafiltrering/datafiltreringSelector";


const nedlastningDispatch = dispatch => ({
  hentNedlastning: (sokETOResultat, filType) => {
    dispatch(lastNedSokETOResultat(sokETOResultat, filType))}
});

const nedlastningState = state => ({
  navn: LAST_NED_TABELL,
  sokETOResultat: selectSokETOResultat(state),
  sokETO: selectSokETO(state)
});

export const Nedlastning = connect(
    nedlastningState,
    nedlastningDispatch
)(LastNedTabell);