import nullstillFilter from "../../reducers/nullstillFilterReducer";

export const selectKodeverk = state => {
  let kodeverk = null;
  if (state.kodeverk) {
    kodeverk = state.kodeverk;
  }
  return kodeverk;
};

export const selectKodeverkVegkategorier = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.vegkategorier;
    }
    return kodeverk;
};

export const selectKodeverkStedsforhold = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.stedsforhold;
    }
    return kodeverk;
};

export const selectKodeverkVegdekke = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.vegdekket;
  }
  return kodeverk;
};

export const selectKodeverkVegtype = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.vegtyper;
  }
  return kodeverk;
};

export const selectKodeverkKjorefeltstype = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.kjorefelttyper;
  }
  return kodeverk;
};

export const selectKodeverkFysiskMidtdeler = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.midtdeler;
  }
  return kodeverk;
};

export const selectReguleringIVegkryss = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.reguleringVegkryss;
  }
  return kodeverk;
};

export const selectReguleringIGangfelt = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.reguleringGangfelt;
  }
  return kodeverk;
};

export const selectFartsgrenser = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.fartsgrenser;
  }
  return kodeverk;
};

export const selectTemperatur = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.temperaturIntervaller;
  }
  return kodeverk;
};

export const selectVerforhold = state => {
  let kodeverk = null;
  if (state.kodeverk  && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.verforhold;
  }
  return kodeverk;
};

export const selectLysforhold = state => {
  let kodeverk = null;
  if (state.kodeverk  && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.lysforhold;
  }
  return kodeverk;
};

export const selectForeforhold = state => {
  let kodeverk = null;
  if (state.kodeverk  && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.foreforhold;
  }
  return kodeverk;
};

export const selectGeografiskForhold = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.geografiskForhold;

        return kodeverk;
    }
};

export const selectFylke = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.fylker;

        return kodeverk;
    }
};


    //TODO clapat: la denne være her i tilfelle man trenger sortering igjen en gang
/*const sorterKodeverkFylke = (a, b) => {
    let sorteringen = 0;
    if (a.fylkesNummer >= b.fylkesNummer ) {
        sorteringen = 1;
    } else if (a.fylkesNummer <= b.fylkesNummer) {
        sorteringen = -1
    }
    return sorteringen
};*/

const sorterKodeverkKommune = (a, b) => {
    let sorteringen = 0;
    if (a.kommuneNummer >= b.kommuneNummer ) {
        sorteringen = 1;
    } else if (a.kommuneNummer <= b.kommuneNummer) {
        sorteringen = -1
    }
    return sorteringen
};
//TODO clapat: la denne være her i tilfelle man trenger sortering igjen en gang
/*const fjernDuplikater = (array, key)  => {
    let lookup = {};
    let result = [];
    let arrayLengde = array.length;
    for (let i=0; i< arrayLengde; i++ ) {
        if (!lookup[array[i][key]]) {
            lookup[array[i][key]] = true;
            result.push(array[i])
        }
    }
    return result;
};*/


export const selectKommuneEnkelt = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.kommuner;
    }
    return kodeverk;
};

/* denne selector sjekker først om det er noen fylker valgt, hvis ja, så selecter den bare de kommuner som er i den fylker*/
export const selectKommune = state => {
    let kodeverk = null;
    let geografiskForholdKodeverk = null;
    let valgtFylker = [];
    let filtrerteKommuner = [];
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        geografiskForholdKodeverk = state.kodeverk.filterETO.ulykke.geografiskForhold;
        kodeverk = state.kodeverk.filterETO.ulykke.kommuner;
        if (state.sokETO && state.sokETO.filterETO && state.sokETO.filterETO.ulykke && state.sokETO.filterETO.ulykke.fylker) {
            state.sokETO.filterETO.ulykke.fylker.forEach(fylke =>
                valgtFylker.push(fylke.kode)
            );
            valgtFylker.forEach(v => {
                geografiskForholdKodeverk.forEach(gk => {
                        if (gk.fylkesNummer === v) {
                            kodeverk.forEach(k => {
                                if (gk.kommuneNummer === k.kode) {
                                    filtrerteKommuner.push(k)
                                }
                            })
                        }
                    })
                }
            );
            return filtrerteKommuner.sort(sorterKodeverkKommune)
        }
        return kodeverk.sort(sorterKodeverkKommune)
    }
    return kodeverk;
};

export const selectBy = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.byer;
    }
    return kodeverk;
};

export const selectBydel = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.bydeler;
    }
    return kodeverk;
};

export const selectTettsted = state => {
    let kodeverk = null;
    if (state.kodeverk  && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.tettsteder;
    }
    return kodeverk;
};

export const selectPolitidistrikt = state => {
    let kodeverk = null;
    if (state.kodeverk  && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.politidistrikt;
    }
    return kodeverk;
};

export const selectKjonn = state => {
    let kodeverk = null;
    if (state.kodeverk  && state.kodeverk.filterETO && state.kodeverk.filterETO.person) {
        kodeverk = state.kodeverk.filterETO.person.kjonn;
    }
    return kodeverk;
};

export const selectAldersgruppe = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.person) {
        kodeverk = state.kodeverk.filterETO.person.ssbAlderIntervaller;
    }
    return kodeverk;
};

export const selectAldersgruppe5arig = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.person) {
        kodeverk = state.kodeverk.filterETO.person.femarigAlderIntervaller;
    }
    return kodeverk;
};

export const selectTrafikanttyper = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.person) {
        kodeverk = state.kodeverk.filterETO.person.trafikanttyper;
    }
    return kodeverk;
};

export const selectPlassering = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.person) {
        kodeverk = state.kodeverk.filterETO.person.plasseringer;
    }
    return kodeverk;
};

export const selectSkadegrad = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.person) {
        kodeverk = state.kodeverk.filterETO.person.skadegrad;
    }
    return kodeverk;
};

export const selectAr = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.ar;
    }
    return kodeverk;
};

export const selectManed = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {

        kodeverk = state.kodeverk.filterETO.ulykke.maneder;
    }
    return kodeverk;
};

export const selectUkedagstype = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.ukedagtyper;
    }
    return kodeverk;
};

export const selectTimetype = state => {
    let kodeverk = null;
    if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
        kodeverk = state.kodeverk.filterETO.ulykke.timetyper;
    }
    return kodeverk;
};

export const selectEnhetstypeHovedkategori = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.enhet) {
    kodeverk = state.kodeverk.filterETO.enhet.enhetstypeHovedkategorier;
  }
  return kodeverk;
};

export const selectEnhetstypeUnderkategori = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.enhet) {
    kodeverk = state.kodeverk.filterETO.enhet.enhetstypeUnderkategorier;
  }
  return kodeverk;
};

export const selectEnhetstype = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.enhet) {
    kodeverk = state.kodeverk.filterETO.enhet.enhetstyper;
  }
  return kodeverk;
};

export const selectRegistreringsland = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.enhet) {
    kodeverk = state.kodeverk.filterETO.enhet.registreringsland;
  }
  return kodeverk;
};

export const selectArsmodell = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.enhet) {
    kodeverk = state.kodeverk.filterETO.enhet.arsModeller;
  }
  return kodeverk;
};


export const selectMerkeKjoretoy = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.enhet) {
    kodeverk = state.kodeverk.filterETO.enhet.kjoretoyMerker;
  }
  return kodeverk;
};

export const selectDrivstofftype = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.enhet) {
    kodeverk = state.kodeverk.filterETO.enhet.drivstoff;
  }
  return kodeverk;
};

export const selectPakjortTypeHinder = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.enhet) {
    kodeverk = state.kodeverk.filterETO.enhet.hinder;
  }
  return kodeverk;
};

export const selectTilhenger = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.enhet) {
    kodeverk = state.kodeverk.filterETO.enhet.tilhengere;
  }
  return kodeverk;
};
export const selectAlvorlighetsgrad = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.alvorlighetsgrader;
  }
  return kodeverk;
};

export const selectUlykkestyper = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.ulykkestyper;
  }
  return kodeverk;
};

export const selectUlykkeskoder = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {
    kodeverk = state.kodeverk.filterETO.ulykke.ulykkeskoder;
  }
  return kodeverk;
};

export const selectTungtransport = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.enhet) {
    kodeverk = state.kodeverk.filterETO.enhet.tungtransport;
  }
  return kodeverk;
};

export const selectVogntog = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.enhet) {
    kodeverk = state.kodeverk.filterETO.enhet.vogntog;
  }
  return kodeverk;
};

export const selectDatasett = state => {
  let datasett = null;
    if (state.sokETO.datasett) {
      datasett = state.sokETO.datasett;
    }
  return datasett;
};

export const selectSokETO = state => {
  let sokETO = null;
  if (state.sokETO) {
    sokETO = state.sokETO;
  }
  return sokETO;
};

export const selectFilterETO = state => {
  let filterETO = null;
  if (state.sokETO && state.sokETO.filterETO) {
    filterETO = state.sokETO.filterETO;
  }
  return filterETO;
};
export const selectSokETOResultat = state => {
    let sokETOResultat = null;
    if (state.sokETOResultat) {
        sokETOResultat = state.sokETOResultat;
    }
    return sokETOResultat;
};

export const selectValgtFilter = state => {
  let valgtFilter = null;
  if (state.valgtFilter) {
    valgtFilter = state.valgtFilter;
  }
  return valgtFilter;
};

export const selectValgtFilterKommune = state => {
  let valgtKommune = null;
  if (state.valgtFilter && state.valgtFilter.Kommune) {
    valgtKommune = state.valgtFilter.Kommune;
  }
  return valgtKommune;
};

export const selectFilterVisesITabell = state => {
  let filterVisesITabell = null;
  if (state.filterVisesITabell) {
    filterVisesITabell = state.filterVisesITabell;
  }
  return filterVisesITabell;
};

export const selectKodeverkFilterETO = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO ) {
    kodeverk = state.kodeverk.filterETO;
  }
  return kodeverk;
};

export const selectSokETOResultatFilterETO = state => {
  let sokETOResultatFilterETO = null;
  if (state.sokETOResultat && state.sokETOResultat.filterETO) {
    sokETOResultatFilterETO = state.sokETOResultat.filterETO;
  }
  return sokETOResultatFilterETO;
};

export const selectFilterNullstilt = state => {
    let nullstilt = false;
    if(state.nullstillFilter) {
        nullstilt = state.nullstillFilter.nullstilt
    }
  return nullstilt;
};
