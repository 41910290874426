import React from 'react';
import './App.css';
import './../ressurser/css/framework.css';
import './../ressurser/css/global.css';
import {connect, useSelector} from 'react-redux';
import {
  hentFeatureToggle,
  hentKodeverk,
  setDatasett,
  hentInformasjonstekst,
  hentTrineVedlikeholdBanner, hentTrineVedlikeholdTekst
} from "../actions";
import {Route, Switch, withRouter} from 'react-router-dom';
import Header from './header/Header';
import {Fot, Tilbakemelding} from './footer/FooterContainer';
import VedlikeholdBanner from "./header/VedlikeholdBanner";
import {LoggingAvPublikum} from './loggingavpublikum/LoggingAvPublikumContainer';
import {NavigasjonContainer} from './navigasjon/NavigasjonContainer';
import OmStatistikken from "./infoomtrafikkulykkesregisteret/OmStatistikken";
import {selectAlert, selectOmStatistikkenValgt, selectSide} from "./appSelector"
import {ANTALL_PERSONSKADER} from "../ressurser/Konstanter";
import StandardrapporterSide from './standardrapporter/StandardrapporterSide';
import Midtdel from "./midtdel/Midtdel";
import Nettstedskart from "./nettstedskart/Nettstedskart";

class App extends React.Component {
  constructor(props) {
    super(props);
    const {setValgtData} = this.props;
    setValgtData(ANTALL_PERSONSKADER);
  }

  componentDidMount() {
    this.props.getKodeverk();
    this.props.getFeatureToggle();
    this.props.hentInformasjonstekst();
    this.props.getBannerVedlikehold();
    this.props.getTekstVedlikehold();
  };

  render() {
    return (
      <div className="App">
        <LoggingAvPublikum/>
        <Header/>
        <VedlikeholdBanner/>
        <main id="main">
          <Switch>
            <Route path="/om" exact component={OmStatistikken}/>
            <Route path="/nettstedskart" exact component={Nettstedskart}/>
            <Route path="/rapporter" exact component={StandardrapporterSide}/>
            <Route path="/" exact component={Midtdel}/>
          </Switch>
          <Tilbakemelding/>
        </main>
        <nav aria-label="navigasjon">
          <NavigasjonContainer/>
        </nav>
        <Fot/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  omStatistikkenValgt: selectOmStatistikkenValgt(state),
  alert: selectAlert(state)
});

const mapDispatchToProps = dispatch => ({
  getKodeverk: () => {
    dispatch(hentKodeverk());
  },
  getFeatureToggle: () => {
    dispatch(hentFeatureToggle());
  },
  getBannerVedlikehold: () => {
    dispatch(hentTrineVedlikeholdBanner());
  },
  getTekstVedlikehold: () => {
    dispatch(hentTrineVedlikeholdTekst())
  },
  setValgtData: data => {
    dispatch(setDatasett(data));
  },
  hentInformasjonstekst: () => {
    dispatch(hentInformasjonstekst());
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
