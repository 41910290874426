import React from 'react';
import PropTypes from 'prop-types'

const Knapp = ({navn, type, storrelse, handterKlikk, klasseNavn, disabled, style}) => {

    return (
        <div className={type === "button--primary" ? "button-row" : ""} style={style}>
            <button
                type="button"
                className={disabled ? "button button--disabled " +  storrelse  : "button " + type + " " + storrelse + " " + klasseNavn}
                onClick={handterKlikk}
                disabled={disabled}
                aria-disabled={disabled ? "true" : "false"}
            >
                {navn}
            </button>
        </div>
    );
};

Knapp.propTypes = {
    navn: PropTypes.string,
    storrelse: PropTypes.string,
    handterKlikk: PropTypes.func.isRequired,
    type: PropTypes.string,
    klasseNavn: PropTypes.string,
    disabled: PropTypes.bool
};

Knapp.defaultProps = {
    navn: "",
    storrelse: "button--large",
    type: "button--primary",
    klasseNavn: "",
    disabled: false
};


export default Knapp;