import {connect} from "react-redux";
import FilterNedtrekksliste from '../../standardkomponenter/FilterNedtrekksliste';
import {setRapportFilterVerdiAr, setRapportFilterVerdiFylke} from '../../../actions';
import {AR, AR_ETO_NAVN, FYLKE, FYLKE_ETO_NAVN, KOMMUNE_ETO_NAVN, ULYKKE_ETO_NAVN} from '../../../ressurser/Konstanter';
import {selectAr, selectFylke} from '../../datafiltrering/datafiltreringSelector';

const setFilterVerdiDispatchAr = dispatch => ({
  setFilter: (etoNavn, data) => {
    dispatch(setRapportFilterVerdiAr(ULYKKE_ETO_NAVN, etoNavn, data));
  }
});

const setFilterVerdiDispatchFylke = dispatch => ({
  setFilter: (etoNavn, data) => {
    dispatch(setRapportFilterVerdiFylke(ULYKKE_ETO_NAVN, etoNavn, data));
  }
});

const arState = state => ({
  navn: AR,
  etoNavn: AR_ETO_NAVN,
  erMulti: false,
  valgmuligheter: selectAr(state),
  visUtenKode: true
});
export const Ar = connect(
  arState,
  setFilterVerdiDispatchAr
)(FilterNedtrekksliste);

const fylkeState = state => ({
  navn: FYLKE,
  etoNavn: FYLKE_ETO_NAVN,
  erMulti: false,
  valgmuligheter: selectFylke(state),
  etoGeografisk: KOMMUNE_ETO_NAVN
});

export const Fylke = connect(
  fylkeState,
  setFilterVerdiDispatchFylke
)(FilterNedtrekksliste);


