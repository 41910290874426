import React from 'react';
import {
    ALVORLIGHETSGRAD,
    ANTALL_PERSONSKADER,
    ANTALL_PERSONSKADER_TITTEL,
    ANTALL_PERSONSKADEULYKKER, ANTALL_PERSONSKADEULYKKER_TITTEL, AR, SKADEGRAD
} from "../../ressurser/Konstanter";

/**
 * Placeholder for radioknapper
 * Datasett skal bestå av to radioknapper
 * Brukeren skal kunne toggle ønsket datasett som skal filtreres.
 *
 * Datasett blir brukt i DatasettInhhold. DatasettInnhold binder Datasettkomponentet sammen med Redux store.
 * Det vil da være mulig å bruke redux funksjonene mapStatetoProps og mapDispatchToProps.
 *
 */
export default class Datasett extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps){
    if(prevProps.datasett !== this.props.datasett && this.props.kodeverk.raderOgKolonnerETO){
      this.props.sendSokETO(this.props.sokETO);
    }
  }

  handleChange(changeEvent) {
      const {setValgtData, sokETO, setKolonne, setRad1, setRad2, setRad3, radEn, radTo, radTre } = this.props;
      setValgtData (changeEvent.target.value);
      if (changeEvent.target.value === ANTALL_PERSONSKADEULYKKER && radEn.id > 22) {
        setRad1({id: 0, navn:AR});
      }

      if (changeEvent.target.value === ANTALL_PERSONSKADEULYKKER && radTo && radTo.id > 22) {
        setRad2(null)
      }

      if (changeEvent.target.value === ANTALL_PERSONSKADEULYKKER && radTre && radTre.id > 22) {
        setRad3(null)
      }

      if (changeEvent.target.value === ANTALL_PERSONSKADER && sokETO.raderOgKolonnerValgETO.kolonne.id === 11) {
        setKolonne({id: 41, navn:SKADEGRAD});
      }

      if (changeEvent.target.value === ANTALL_PERSONSKADEULYKKER && (sokETO.raderOgKolonnerValgETO.kolonne.id > 22)) {
        setKolonne({id: 11, navn: ALVORLIGHETSGRAD});
      }

  }
  render() {
    return (
        <fieldset className="form-element form-element--inline" style={{marginBottom: 0}}>
          <legend>
              <h3>1. Velg datasett</h3>
          </legend>
          <input type="radio" name="gruppe_radio" value={ANTALL_PERSONSKADER} id="personskade_radio" onChange={this.handleChange} defaultChecked/>
          <label htmlFor="personskade_radio">{ANTALL_PERSONSKADER_TITTEL}</label>
          <input type="radio" name="gruppe_radio" value={ANTALL_PERSONSKADEULYKKER} id="personskadeulykker_radio" onChange={this.handleChange}/>
            <label htmlFor="personskadeulykker_radio">{ANTALL_PERSONSKADEULYKKER_TITTEL}</label>
        </fieldset>
        );
  }
}