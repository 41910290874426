import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import './theme.scss';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter as Router} from 'react-router-dom';
import ScrollToTop from "./components/standardkomponenter/ScrollToTop";

const {store, persistor} = configureStore();

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
          <ScrollToTop/>
          <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
