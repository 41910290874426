export const selectOmStatistikkenValgt = state => {
  let kodeverk = null;
  if (state.omStatistikkenKodeverk && state.omStatistikkenKodeverk.omStatistikkenValgt) {
    kodeverk = state.omStatistikkenKodeverk.omStatistikkenValgt;
  }
  return kodeverk;
};

export const selectAlert = state => {
  let alert = "";
  if (state.tilbakemeldingBruker) {
    alert = state.tilbakemeldingBruker.alert
  }
  return alert;
};

export const selectSide = state => {
  return state.valgtSide;
};