import {createSelector} from 'reselect';
import {selectDatasett} from "../datafiltrering/datafiltreringSelector";
import {ANTALL_PERSONSKADER, ANTALL_PERSONSKADEULYKKER, FYLKE, KOMMUNE} from "../../ressurser/Konstanter";

export const selectKodeverk = state => {
  let kodeverk = null;
  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.person) {
    kodeverk = state.kodeverk.raderOgKolonnerETO.alternativer;
  }
  return kodeverk;
};

export const selectFeatureToggle = state => {
  return state.featureToggle;
};

export const selectBannerVedlikehold = state => {
  return state.featureToggle ? state.featureToggle.vedlikehold : false;
};

export const selectSok = state => {
  let kodeverk = null;
  if (state.sokETO && state.sokETO.raderOgKolonnerValgETO) {
    kodeverk = state.sokETO.raderOgKolonnerValgETO;
  }
  return kodeverk;
};

export const selectSokRadEn = state => {
  let kodeverk = null;
  if (state.sokETO && state.sokETO.raderOgKolonnerValgETO && state.sokETO.raderOgKolonnerValgETO.rad1) {
    kodeverk = state.sokETO.raderOgKolonnerValgETO.rad1;
  }
  return kodeverk;
};

export const selectSokRadTo = state => {
  let kodeverk = null;
  if (state.sokETO && state.sokETO.raderOgKolonnerValgETO && state.sokETO.raderOgKolonnerValgETO.rad2) {
    kodeverk = state.sokETO.raderOgKolonnerValgETO.rad2;
  }
  return kodeverk;
};

export const selectSokRadTre = state => {
  let kodeverk = null;
  if (state.sokETO && state.sokETO.raderOgKolonnerValgETO && state.sokETO.raderOgKolonnerValgETO.rad3) {
    kodeverk = state.sokETO.raderOgKolonnerValgETO.rad3;
  }
  return kodeverk;
};

export const selectSokkolonne = state => {
  let kodeverk = null;
  if (state.sokETO && state.sokETO.raderOgKolonnerValgETO && state.sokETO.raderOgKolonnerValgETO.kolonne) {
    kodeverk = state.sokETO.raderOgKolonnerValgETO.kolonne;
  }
  return kodeverk;
};

const RAD1 = "RAD1";
const RAD2 = "RAD2";
const RAD3 = "RAD3";
const KOLONNE = "KOLONNE";

export const createSelectKodeverkRad1 = createSelector(
  [selectDatasett, selectKodeverk, selectSokRadEn, selectSokRadTo, selectSokRadTre, selectSokkolonne],
  (datasett, kodeverk, rad1, rad2, rad3, kolonne) => {
    return genererKodeverk(datasett, kodeverk, rad1, rad2, rad3, kolonne, RAD1);
  }
);

export const createSelectKodeverkRad2 = createSelector(
  [selectDatasett, selectKodeverk, selectSokRadEn, selectSokRadTo, selectSokRadTre, selectSokkolonne],
  (datasett, kodeverk, rad1, rad2, rad3, kolonne) => {
    return genererKodeverk(datasett, kodeverk, rad1, rad2, rad3, kolonne, RAD2);
  }
);

export const createSelectKodeverkRad3 = createSelector(
  [selectDatasett, selectKodeverk, selectSokRadEn, selectSokRadTo, selectSokRadTre, selectSokkolonne],
  (datasett, kodeverk, rad1, rad2, rad3, kolonne) => {
    return genererKodeverk(datasett, kodeverk, rad1, rad2, rad3, kolonne, RAD3);
  }
);

export const createSelectKodeverkKolonne = createSelector(
  [selectDatasett, selectKodeverk, selectSokRadEn, selectSokRadTo, selectSokRadTre, selectSokkolonne],
  (datasett, kodeverk, rad1, rad2, rad3, kolonne) => {
    return genererKodeverk(datasett, kodeverk, rad1, rad2, rad3, kolonne, KOLONNE);
  }
);

function genererKodeverk(datasett, kodeverk, rad1, rad2, rad3, kolonne, radNummer) {
  let valgtKodeverk = [];
  if (rad1 !== null) {
    if (rad1.id === 5 && radNummer !== RAD1) {
      valgtKodeverk.push({id: 4, navn: FYLKE})
    } else if (rad1.id === 4 && radNummer === KOLONNE) {
      valgtKodeverk.push({id:5, navn: KOMMUNE})
    }
    valgtKodeverk.push(rad1);
  }
  if (rad2 !== null) {
    if ((radNummer === RAD1 || radNummer === KOLONNE) && rad2.id === 4) {
      valgtKodeverk.push({id: 5, navn: KOMMUNE})
    } if (radNummer === KOLONNE && rad2.id === 5) {
      valgtKodeverk.push({id: 4, navn: FYLKE})
    }
    valgtKodeverk.push(rad2);
  }

  if (rad3 !== null) {
    if ((radNummer === RAD3 && rad2.id === 5 && rad1.id !== 4) ||
      (radNummer === RAD1 && rad2.id === 4)
    ){
      valgtKodeverk.push({id: 4, navn: FYLKE})
    } else if (((radNummer === RAD1 || radNummer === RAD2 || radNummer === KOLONNE) && rad3.id === 4)) {
      valgtKodeverk.push({id: 5, navn: KOMMUNE})
    }
    valgtKodeverk.push(rad3);
  }

  if (kolonne !== null) {
    if (kolonne.id === 4 && (radNummer === RAD1 || radNummer === RAD2 || radNummer === RAD3)) {
      valgtKodeverk.push({id: 5, navn: KOMMUNE})
    } if (kolonne.id === 5 && (radNummer === RAD1 || radNummer === RAD2 || radNummer === RAD3)) {
      valgtKodeverk.push({id: 4, navn: FYLKE})
    }
    valgtKodeverk.push(kolonne);
  }

  let alvorlighetsgrad = null;
  if (kodeverk !== null) {
    if (datasett === ANTALL_PERSONSKADEULYKKER) {
      kodeverk = kodeverk.slice(0, 23)
    } else if (datasett === ANTALL_PERSONSKADER) { //fjern alvorlighetsgrad
      alvorlighetsgrad = kodeverk.filter(x => x.id === 11);
      if (alvorlighetsgrad !== null && alvorlighetsgrad.length > 0) {
        alvorlighetsgrad = alvorlighetsgrad[0];
      }
    }

    valgtKodeverk.forEach(list => {
      kodeverk = kodeverk.filter(e => e !== null && e.id !== list.id && e !== alvorlighetsgrad)
    })
  }

  return kodeverk;
}