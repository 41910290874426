import {
    HENT_DEFINISJONER_ETO,
    HENT_KODEVERK,
    HENT_OM_STATISTIKKEN_ETO,
    SEND_SOK_ETO,
    LAST_NED_TABELL,
    SEND_BRUKER_ETO,
    HENT_FEATURE_TOGGLE, HENT_INFOTEKST, HENT_TRINE_VEDLIKEHOLD_BANNER, HENT_TRINE_VEDLIKEHOLD_TEKST
} from "../actions/actionTypes";
import {
    URL_HENT_KODEVERK,
    URL_SEND_SOK_ETO,
    URL_HENT_OM_STATISTIKKEN_ETO,
    URL_BRUKER,
    URL_HENT_DEFINISJONER_ETO,
    URL_LAST_NED_CSV,
    URL_LAST_NED_PDF,
    URL_LAST_NED_XLSX,
    URL_SEND_SPORSMAL,
    URL_HENT_FEATURE_TOGGLE, URL_HENT_INFOTEKST, URL_HENT_TRINE_BANNER_VEDLIKEHOLD, URL_HENT_TRINE_BANNER_TEKST
} from "./konstanter";
import {
    setKodeverk,
    setSokETOResultat,
    setOmStatistikkenETO,
    setDefinisjonerETO,
    setInfoTilBruker,
    setLoading,
    setAlert,
    setTakkTilbakemelding,
    setFeatureToggle, setInformasjonstekst, setTrineVedlikeholdBanner, setTrineVedlikeholdTekst
} from "../actions";
import {INGEN_TREFF, CSV, PDF, XLSX, APPLICATION_JSON, SAME_ORIGIN, POST, GET, GIKK_GALT, FORBUDT, IKKE_HENTE} from "../ressurser/Konstanter";
import {SEND_TILBAKEMEDLING} from "../actions/actionTypes";
import {URL_SEND_TILBAKEMELDING} from "./konstanter";
import {faExclamationCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

const dataService = store => next => action => {

    next(action);
    switch (action.type) {
        case HENT_KODEVERK:
            fetch(URL_HENT_KODEVERK, {
                method: GET,
                credentials: SAME_ORIGIN
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error();
                })
                .then(data => {
                    next(setKodeverk(data));
                })
                .then(() => {
                    next(setLoading(true));
                    const stateSokETO = store.getState().sokETO;
                    const sokETO = JSON.stringify(stateSokETO);
                    if (stateSokETO && !stateSokETO.raderOgKolonnerValgETO) return null;
                    fetch(URL_SEND_SOK_ETO, {
                        method: POST,
                        headers: {'Content-Type': APPLICATION_JSON},
                        body: sokETO,
                        credentials: SAME_ORIGIN
                    })
                        .then(response => {
                            if (response.ok) {
                                return response.json();
                            }
                            throw new Error();
                        })
                        .then(data => {
                            next(setSokETOResultat(data));
                            next(setLoading(false));
                        })
                        .catch(error => {
                            next(setInfoTilBruker(GIKK_GALT));
                            next(setLoading(false));
                        });

                })
                .catch(error => {
                    next(setInfoTilBruker(IKKE_HENTE));
                });
            break;

        case HENT_FEATURE_TOGGLE:
            fetch(URL_HENT_FEATURE_TOGGLE, {
                method: GET,
                credentials: SAME_ORIGIN
            })
                .then(response => {
                    if(response.ok) {
                        return response.json();
                    } else {
                        throw new Error();
                    }
                })
                .then(data => {
                    next(setFeatureToggle(data));
                })
                .catch(error => {
                   next(setInfoTilBruker(GIKK_GALT));
                });
            break;

        case HENT_TRINE_VEDLIKEHOLD_BANNER:
            fetch(URL_HENT_TRINE_BANNER_VEDLIKEHOLD, {
                method: GET,
                credentials: SAME_ORIGIN
            })
                .then(response => {
                    if(response.ok) {
                        return response.json();
                    } else {
                        throw new Error()
                    }
                })
                .then(data => {
                    next(setTrineVedlikeholdBanner(data));
                })
                .catch(error => {
                    next(setInfoTilBruker(GIKK_GALT));
                });
            break;

        case HENT_TRINE_VEDLIKEHOLD_TEKST:
            fetch(URL_HENT_TRINE_BANNER_TEKST, {
                method: GET,
                credentials: SAME_ORIGIN
            })
                .then(response => {
                    if(response.ok) {
                        return response.text();
                    } else {
                        throw new Error()
                    }
                })
                .then(data => {
                    next(setTrineVedlikeholdTekst(data));
                })
                .catch(error => {
                    next(setInfoTilBruker(GIKK_GALT));
                });
            break;


        case SEND_SOK_ETO:
            let responseStatus = null;
            next(setLoading(true));
            next(setInfoTilBruker(null));
            fetch(URL_SEND_SOK_ETO, {
                method: POST,
                headers: {'Content-Type': APPLICATION_JSON},
                body: JSON.stringify(action.sokETO),
                credentials: SAME_ORIGIN
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    responseStatus = response.status;
                    throw new Error(responseStatus.toString(10));
                })
                .then(data => {
                    next(setSokETOResultat(data));
                    next(setLoading(false));
                })
                .catch(error => {
                    switch (error.message) {
                        case("204"):
                            next(setInfoTilBruker(INGEN_TREFF));
                            break;
                        case("403"):
                            next(setInfoTilBruker(FORBUDT));
                            break;
                        default:
                            next(setInfoTilBruker(GIKK_GALT))
                    }
                    next(setLoading(false));
                });

            break;

        case SEND_BRUKER_ETO:
            fetch(URL_BRUKER, {
                method: POST,
                headers: {'Content-Type': APPLICATION_JSON},
                body: JSON.stringify(action.data),
                credentials: SAME_ORIGIN
            })
                .catch(error => {
                    console.error(error);
                });
            break;

        case LAST_NED_TABELL:
            let urlEksportering;
            switch (action.filType) {
                case PDF:
                    urlEksportering = URL_LAST_NED_PDF;
                    break;
                case CSV:
                    urlEksportering = URL_LAST_NED_CSV;
                    break;
                case XLSX:
                    urlEksportering = URL_LAST_NED_XLSX;
                    break;
                default:
                    break;
            }
            fetch(urlEksportering, {
                method: POST,
                headers: {'Content-Type': APPLICATION_JSON},
                body: JSON.stringify(action.sokETOResultat),
                credentials: SAME_ORIGIN
            })
                .then(response => {
                    if (response.ok) {
                        return response.blob();
                    }
                    throw new Error("Error");
                })
                /**
                 * blob er et filobjekt fra backend.
                 * a er et html tag som brukes til hyperlenker.
                 * koden opretter lenken, trykker på den, og deretter fjerner den.
                 */
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'TabellSVV' + action.filType;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    next(setAlert("  Beklager, kunne ikke hente " + action.filType + ". Prøv igjen senere!", 'danger', faExclamationCircle, '#b63434'));
                }).then(() => {
                setTimeout(() => {
                    next(setAlert(null))
                }, 4000)
            });
            break;


        case SEND_TILBAKEMEDLING:
            let urlMelding, body;
            if (action.epost === '') {
                urlMelding = URL_SEND_TILBAKEMELDING
                body = JSON.stringify({"tilbakemelding": action.tekst})
            } else {
                urlMelding = URL_SEND_SPORSMAL
                body = JSON.stringify({"tilbakemelding": action.tekst, "epost": action.epost})
            }

            fetch(urlMelding, {
                method: POST,
                headers: {'Content-Type': APPLICATION_JSON},
                body: body,
                credentials: SAME_ORIGIN
            })
                .then(response => {
                    if (response.ok) {
                        next(setTakkTilbakemelding(true));
                    } else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    next(setAlert("  Ooops, det gikk noe feil. Vi kunne ikke ta imot tilbakemeldingen din. ", 'danger', faExclamationCircle, '#b63434'));
                    next(setTakkTilbakemelding(false));
                }).then(() => {
                setTimeout(() => {
                    next(setAlert(null))
                }, 4000)
            });
            break;

        case HENT_OM_STATISTIKKEN_ETO:
            fetch(URL_HENT_OM_STATISTIKKEN_ETO, {
                method: GET,
                credentials: SAME_ORIGIN
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error("Error");
                })
                .then(data => {
                    next(setOmStatistikkenETO(data));
                }).catch(error => {
                next(setAlert("  Det gikk noe feil ved henting av 'Om statistikken' Vi beklager problemer.", 'var(--orange-regular)', faExclamationTriangle, 'var(--orange-dark)'));
            }).then(() => {
                setTimeout(() => {
                    next(setAlert(null))
                }, 4000);
            });
            break;

        case HENT_DEFINISJONER_ETO:
            fetch(URL_HENT_DEFINISJONER_ETO, {
                method: GET,
                credentials: SAME_ORIGIN
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error();
                })
                .then(data => {
                    next(setDefinisjonerETO(data));
                })
                .catch(error => {
                    next(setAlert("  Det gikk noe feil ved henting av definisjoner. Vi beklager problemer.", 'var(--orange-regular)', faExclamationTriangle, 'var(--orange-dark)'));
                }).then(() => {
                setTimeout(() => {
                    next(setAlert(null))
                }, 4000);
            });
            break;
        case HENT_INFOTEKST:
            fetch(URL_HENT_INFOTEKST, {
                method: GET,
                credentials: SAME_ORIGIN
            })
                .then(response => {
                    if(response.ok) {
                        return response.json()
                    }
                    throw new Error();
                })
                .then(data => {
                    next(setInformasjonstekst(data))
                })
                .catch(error => {
                    next(setAlert("  Det gikk noe feil ved henting av informasjonstekst. Vi beklager problemer.", 'var(--orange-regular)', faExclamationTriangle, 'var(--orange-dark)'));
                }).then(() => {
                    setTimeout(() => {
                        next(setAlert(null))
                    }, 4000);
                });
            break;
        default:
    }
};

export default dataService;