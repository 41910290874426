import { connect } from 'react-redux';
import Logging from './LoggingAvPublikum';
import {
  setBruker,
  sendBrukerETO
} from "../../actions";
import {selectBruker,selectBrukerTyper} from "./LoggingAvPublikumSelector";

const brukerState = state => ({
  brukertyper: selectBrukerTyper(state),
  erBrukerValgt: selectBruker(state)
});
const setBrukerDispatch = dispatch => ({
  setBruker: data => {
    dispatch(setBruker(data));
  },
  sendBrukerETO: (brukerETO) => {
    dispatch(sendBrukerETO(brukerETO))}
});
export const LoggingAvPublikum = connect(
    brukerState,
    setBrukerDispatch,
)(Logging);