import {SET_FEATURE_TOGGLE, SET_TRINE_VEDLIKEHOLD_BANNER, SET_TRINE_VEDLIKEHOLD_TEKST} from "../actions/actionTypes";

const featureToggle = (state = {}, action) => {
    switch (action.type) {
        case SET_FEATURE_TOGGLE:
            return {
                ...state,
                beta: action.data
            };
        case SET_TRINE_VEDLIKEHOLD_BANNER:
            return {
                ...state,
                vedlikehold: {
                    ...state.vedlikehold,
                    visBanner: action.data
                }
            };
        case SET_TRINE_VEDLIKEHOLD_TEKST:
            return {
                ...state,
                vedlikehold: {
                    ...state.vedlikehold,
                    tekst: action.data
                }
            };
        default:
            return state;
    }
};

export default featureToggle;