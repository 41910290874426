import React, {useState} from 'react';
import { connect } from 'react-redux';
import './header.css';
import Logo from '../../ressurser/SvvLogo.svg';
import {Link} from "react-router-dom";
import {selectFeatureToggle} from "../raderogkolonner/raderOgKolonnerSelector";
import Skiplenke from "../uukomponenter/Skiplenke"

import {OM_STATISTIKKEN, STANDARDRAPPORTER, TRAFIKKULYKKESRESGISTERET} from '../../ressurser/Konstanter';
import {selectSide} from "../appSelector";
import menu from "../../ressurser/icons/menu.svg"
import lukk from "../../ressurser/icons/lukk.svg"


/**
*Header er øvre del av nettsiden og består av selskapet's logo, navn og mulighet til å velge språk.
*/
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handterTilbakeKlikk = this.handterTilbakeKlikk.bind(this);
    this.handterOmStatistikkenKlikk = this.handterOmStatistikkenKlikk.bind(this);
    this.handterStandardRapporterKlikk = this.handterStandardRapporterKlikk.bind(this);
    this.state = {
      visMeny: false
    }
  }

  handterTilbakeKlikk() {
    document.title = "Trine"
    this.setState({hovedside: true, omStatistikken: false, standardRapporter: false, visMeny: false})
  }

  handterOmStatistikkenKlikk() {
    this.setState({hovedside: false, omStatistikken: true, standardRapporter: false, visMeny: false})
  }

  handterStandardRapporterKlikk() {
    this.setState({hovedside: false, omStatistikken: false, standardRapporter: true, visMeny: false})
  }

  handterBurgerMenu = () => {
    this.setState({
      visMeny: !this.state.visMeny
    })
  }

  render() {
    const { valgtSide } = this.props;
    
    if (this.state.visMeny) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return (
      <header className="header-global site-header header2">
        <Skiplenke />
         <nav className='header-navigasjon' aria-label="header navigasjon">
          <div>
            <Link to="/" onClick={this.handterTilbakeKlikk} title="Gå til forsiden">
              <img src={Logo} className="header-logo" alt="Statens vegvesen logo" />
            </Link>
          </div>
          <button type="button" onClick={this.handterBurgerMenu} className={this.state.visMeny ? "burger-menu-button close-header" : "burger-menu-button"}><span><img src={menu} alt=""/></span> Meny </button>
          <button type="button" onClick={this.handterBurgerMenu} className={this.state.visMeny ? "close-menu-button open" : "close-menu-button"}><span><img src={lukk} alt=""/></span> Lukk </button>
          <ul className={this.state.visMeny ? "menu close-header" : "menu"}>
            <li className={valgtSide.valgtSide === "hovedside" ? "header-link-aktiv" : "header-link"}>
              <Link to={"/"} 
                onClick={this.handterTilbakeKlikk}
              >
                  {TRAFIKKULYKKESRESGISTERET}
              </Link>
            </li>
            <li className={valgtSide.valgtSide === "om" ? "header-link-aktiv" : "header-link"}>
              <Link to={"/om"} 
                onClick={this.handterOmStatistikkenKlikk} 
              >
                  {OM_STATISTIKKEN}
              </Link>
            </li>
            <li className={valgtSide.valgtSide === "standardrapporter" ? "header-link-aktiv" : "header-link"}>
              <Link to={"/rapporter"} 
                onClick={this.handterStandardRapporterKlikk} 
              >
                  {STANDARDRAPPORTER}
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  featureToggle: selectFeatureToggle(state),
  valgtSide: selectSide(state)
});

export default connect(mapStateToProps)(Header);
