import {
  HENTER_STANDARDRAPPORTER,
  MOTTA_FYLKER,
  MOTTA_TILGJENGELIGE_RAPPORTER,
  SETT_AR, SETT_AR_PUBLISERT,
  SETT_BY,
  SETT_BYDEL,
  SETT_FYLKE,
  SETT_KOMMUNE
} from "../components/standardrapporter/redux/actions";

export const FULLFOR_INIT = "FULLFOR_INIT";


export const INITIAL_STATE = {
  ar: "null",
  arPublisert: false,
  fylke: "Alle",
  kommune: "Alle",
  by: "Alle",
  bydel: "Alle",
  tilgjengeligeRapporter: [],
  fylker: {}
};

const standardrapporter = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FULLFOR_INIT:
      return {
        ...state,
        fylker: action.fylker
      };

    case SETT_AR:
      return {
        ...state,
        ar: action.data,
        arPublisert: false,
        tilgjengeligeRapporter: []
      };
    case SETT_FYLKE:
      return {
        ...state,
        fylke: action.data,
        tilgjengeligeRapporter: []
      };
    case SETT_KOMMUNE:
      return {
        ...state,
        kommune: action.data,
        tilgjengeligeRapporter: []
      };
    case SETT_AR_PUBLISERT:
      return {
        ...state,
        arPublisert: action.publisert
      };
    case HENTER_STANDARDRAPPORTER:
      return {
        ...state
      };
    case MOTTA_TILGJENGELIGE_RAPPORTER:
      return {
        ...state,
        tilgjengeligeRapporter: action.rapporter
      };
    case MOTTA_FYLKER:
      return {
        ...state,
        fylker: action.fylker
      };
    default:
      return state;
  }
};

export default standardrapporter;

