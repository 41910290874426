import {
    ALDER_LOGISK_FEILMELDING, ALDER_LOGISK_FEILMELIND_RAD_KOLONNE,
    ALDERSGRUPPE,
    ALDERSGRUPPE_5ARIG,
    KOLONNE, MANED,
    RAD_EN,
    RAD_TO,
    RAD_TRE, UKEDAG, UKEDAG_MANED_FEILMELDING, UKEDAG_MANED_FEILMELDING_RAD_KOLONNE
} from "../../ressurser/Konstanter";

/**
 * Denne filen inneholder hjelpefunksjoner for logisk kontroll
 */

/**
 * Denne funksjonen skjekker for logisk feil i filter. Den returnerer true dersom ett aldersgruppefilter er valgt.
 * @param filter Valgt filter <br/>
 * @param navn Navnet på nedtrekkslisten <br/>
 * @param kodeverkAlle Hele kodeverket, trengs for sammenlikning <br/>
 * @returns {boolean}
 * @public
 */
export function logiskKontrollFilter(filter, navn, kodeverkAlle) {
    if (filter.filterETO) {
        let aldersgruppeIRadOgKolonner = false;
        let aldersgruppe5arigIRadOgKolonner = false;
        let ukedagerRadOgKolonner = false;
        let manedRadOgKolonner = false;
        for (let i in filter.raderOgKolonnerValgETO) {
            if (filter.raderOgKolonnerValgETO[i]) {
                if (filter.raderOgKolonnerValgETO[i].navn === ALDERSGRUPPE) {
                    aldersgruppeIRadOgKolonner = true
                } else if (filter.raderOgKolonnerValgETO[i].navn === ALDERSGRUPPE_5ARIG) {
                    aldersgruppe5arigIRadOgKolonner = true;
                }
                if(filter.raderOgKolonnerValgETO[i].navn === MANED) {
                    manedRadOgKolonner = true;
                } else if (filter.raderOgKolonnerValgETO[i].navn === UKEDAG) {
                    ukedagerRadOgKolonner = true;
                }
            }
        }
        switch (navn) {
            case ALDERSGRUPPE:
                return (filter.filterETO.person.femarigAlderIntervaller || aldersgruppe5arigIRadOgKolonner);
            case ALDERSGRUPPE_5ARIG:
                return (filter.filterETO.person.ssbAlderIntervaller || aldersgruppeIRadOgKolonner);
            case MANED:
                return (filter.filterETO.ulykke.ukedagtyper || ukedagerRadOgKolonner);
            case UKEDAG:
                return (filter.filterETO.ulykke.maneder || manedRadOgKolonner);
            default:
                return false
        }
    }else{return false}
}
/**
 * Denne funksjonen returnerer en feilmelding dersom ett aldersgruppefilter er valgt
 * @param navn Navnet på nedtrekkslisten
 * @returns {string}
 * @public
 */
export function genererFeilmeldingFilter(navn){
    switch(navn){
        case ALDERSGRUPPE:
        case ALDERSGRUPPE_5ARIG:
            return ALDER_LOGISK_FEILMELDING;
        case MANED:
        case UKEDAG:
            return UKEDAG_MANED_FEILMELDING;
        default:
            return ""
    }
}

/**
 * Denne funksjonen skjekker for logiske feil i rad og kolonne. Dersom en rad eller kolonne har valgt aldersgruppe eller aldersgruppe 5årig
 * vil funksjonen returnere indeksen på den andre aldersgruppe kategorien slik at nedtrekkslisten kan disable denne valgmuligheten
 * @param navn Navnet på nedtrekkslisten <br/>
 * @param valgmuligheter De tilgjenglige valgmulighetene for nedtrekkslisten <br/>
 * @param raderOgKolonnerValgETO De valgte radene og kolonnene <br/>
 * @returns {number}
 * @public
 */
export function logiskKontrollRadOgKolonner(navn, valgmuligheter, raderOgKolonnerValgETO) {
    let index = -1;
    let navnVerdi = "";
    //Må finnes en bedre måte å gjøre denne switch-casen på?
    switch (navn) {
        case RAD_EN:
            navnVerdi = "rad1";
            break;
        case RAD_TO:
            navnVerdi = "rad2";
            break;
        case RAD_TRE:
            navnVerdi = "rad3";
            break;
        case KOLONNE:
            navnVerdi = "kolonne";
            break;
        default:
            break;
    }
    for(let i in raderOgKolonnerValgETO){
        if(i === navnVerdi) {
            //do nothing
        }else if(raderOgKolonnerValgETO[i]){
            if(raderOgKolonnerValgETO[i].navn === ALDERSGRUPPE){
                index= valgmuligheter.findIndex((element)=>{return element && element.navn===ALDERSGRUPPE_5ARIG});
            }else if(raderOgKolonnerValgETO[i].navn === ALDERSGRUPPE_5ARIG){
                index = valgmuligheter.findIndex((element)=>{return element&&element.navn===ALDERSGRUPPE});
            }
            if(raderOgKolonnerValgETO[i].navn === MANED){
                index= valgmuligheter.findIndex((element)=>{return element && element.navn===UKEDAG});
            }else if(raderOgKolonnerValgETO[i].navn === UKEDAG){
                index = valgmuligheter.findIndex((element)=>{return element&&element.navn===MANED});
            }

        }
    }
    return index;
}

/**
 * Denne funksjonen genererer en feilmelding dersom aldersgruppe eller aldersgruppe 5årig er valgt som rad eller kolonne.
 * Dette er for å informere brukeren om hvorfor valgmuligheten er blitt disablet.
 * @param verdi Verdien på valget i nedtrekksliten.
 * @returns {string}
 * @public
 */
export function genererFeilmeldingRadOgKolonne(verdi){
    switch (verdi.navn){
        case ALDERSGRUPPE_5ARIG:
        case ALDERSGRUPPE:
            return ALDER_LOGISK_FEILMELIND_RAD_KOLONNE;
        case MANED:
        case UKEDAG:
            return UKEDAG_MANED_FEILMELDING_RAD_KOLONNE;
        default:
            return ""
    }
}