import React from 'react';
import Modal from "../uukomponenter/Modal.js"
import './loggingavpublikum.css';
import PersontypeKnapp from "../uukomponenter/PersontypeKnapp.js"
import Knapp from "../uukomponenter/Knapp"
import {IKKE_SVAR_TEKST, INFO_TEKST, OVERSKRIFT_TEKST} from "./Konstanter"
import IkonStudent from "../../ressurser/icons/student.svg"
import Sporsmalstegn from "../../ressurser/icons/sporsmalstegn.svg"
import Interesseorganisasjon from "../../ressurser/icons/interesseorganisasjon.svg"
import Journalist from "../../ressurser/icons/journalist.svg"
import Privatperson from "../../ressurser/icons/privatperson.svg"
import Jobberistaten from "../../ressurser/icons/jobberistaten.svg"

/**
 * Logging av publikum klasse rendrer en MODAl som åpnes over hovedvinduet. Inne i modalen finner man persontypeknapp hvor man
 * kan sende info om hvem man er. Ved trykk kalles knappTrykket() som sender informasjonen til backend.
 *
 */
const LoggingAvPublikum = (props) => {

  const knappTrykket = (verdi) => {
    props.setBruker(true);
    if(verdi !== null) {
      props.sendBrukerETO({'brukerType': verdi});
    }
  }

  const velgNavn = (value) => {
    if(props.brukertyper){
      return props.brukertyper[value].navn;
    }
    else{
      return "";
    }
  }
  const innhold = (
      <div className="innhold">
          <p className='m-b-sm'>{INFO_TEKST}</p>
        <div className="m-b-sm persontype_knapper">
            <PersontypeKnapp tittel={velgNavn(0)} ikon={IkonStudent} handterKlikk={() => knappTrykket(props.brukertyper[0])}/>
            <PersontypeKnapp tittel={velgNavn(1)} ikon={Journalist} handterKlikk={() => knappTrykket(props.brukertyper[1])}/>
            <PersontypeKnapp tittel={velgNavn(2)} ikon={Jobberistaten} handterKlikk={() => knappTrykket(props.brukertyper[2])}/>
            <PersontypeKnapp tittel={velgNavn(3)} ikon={Interesseorganisasjon} handterKlikk={() => knappTrykket(props.brukertyper[3])}/>
            <PersontypeKnapp tittel={velgNavn(4)} ikon={Privatperson} handterKlikk={() => knappTrykket(props.brukertyper[4])}/>
            <PersontypeKnapp tittel={velgNavn(5)} ikon={Sporsmalstegn} handterKlikk={() => knappTrykket(props.brukertyper[5])}/>
          </div>
      </div>
    )

  const innholdBunn = (<Knapp navn={IKKE_SVAR_TEKST} handterKlikk={(e) => knappTrykket(null)}/>)

  return (
    props.brukertyper ? <Modal
        innholdTop={<h1 className="overskriftLoggingAvPublikum">{OVERSKRIFT_TEKST}</h1>}
        innhold={innhold} innholdBunn={innholdBunn} open={!props.erBrukerValgt.bruker}
        setOpen={(e) => knappTrykket(null)}
        brukRef={true}/> :
        <></>
    );
    
}

export default LoggingAvPublikum;
