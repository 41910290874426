/**
 * Js fil som inneholder alle konstanter i dette prosjektet
 * @type {string}
 */

export const OM_STATISTIKKEN_TEKST = 'Les bakgrunn for statistikken og \n definisjoner på viktige ord i kodeverket \n vårt';
export const OM_STATISTIKKEN = 'Om statistikken';
export const VEGKART = 'Vegkart';
export const VEGKART_TEKST = 'Hvis du ønsker å se tallene fra \n Trafikkulykkesregisteret ut i fra posisjon i \n landet, kan du klikke deg rundt på \n vegkart.no';
export const STANDARDRAPPORTER = 'Standardrapporter';
export const STANDARDRAPPORTER_TEKST = 'Søk etter og les standardrapporter fra Trafikkulykkesregisteret. Disse gis ut på årlig basis.';
export const GATILTRAFIKKULYKKER = 'Gå til trafikkulykkesregisteret'
export const TRAFIKKULYKKESRESGISTERET = 'Trafikkulykkesregisteret';
export const TRAFIKKULYKKER_TEKST = 'Søk i trafikkulykkesregisteret og lag dine egne krysstabeller med opptil 3 rader.';
export const TID = 'Tid';
export const VEG = 'Veg';
export const STED = "Sted";

export const EPOST_BESTILL_RAPPORT = 'truls@vegvesen.no';

export const ULYKKE_ETO_NAVN='ulykke';
export const FARTSGRENSER = 'Fartsgrense (km/t)';
export const FARTSGRENSER_ETO_NAVN = 'fartsgrenser';
export const VEGTYPER = 'Vegtyper';
export const VEGTYPER_ETO_NAVN = 'vegtyper';
export const VEGKATEGORI = 'Vegkategori';
export const VEGKATEGORI_ETO_NAVN = 'vegkategorier';
export const STEDSFORHOLD = 'Stedsforhold';
export const STEDSFORHOLD_ETO_NAVN = 'stedsforhold';
export const VEGDEKKE = 'Vegdekke';
export const VEGDEKKE_ETO_NAVN = 'vegdekket';
export const KJOREFELTTYPE = 'Kjørefeltstype';
export const KJOREFELTTYPE_ETO_NAVN = 'kjorefelttyper';
export const FYSISK_MIDTDELER = 'Fysisk midtdeler/midtrekkverk';
export const FYSISK_MIDTDELER_ETO_NAVN = 'midtdeler';
export const REGULERING_I_VEGKRYSS = 'Regulering i vegkryss';
export const REGULERING_I_VEGKRYSS_ETO_NAVN = 'reguleringVegkryss';
export const REGULERING_I_GANGFELT = 'Regulering i gangfelt';
export const REGULERING_I_GANGFELT_ETO_NAVN = 'reguleringGangfelt';
export const KLIMA = 'Vær og føre';
export const LYSFORHOLD = 'Lysforhold';
export const LYSFORHOLD_ETO_NAVN = 'lysforhold';
export const FOREFORHOLD = 'Føreforhold';
export const FOREFORHOLD_ETO_NAVN = 'foreforhold';
export const VERFORHOLD = 'Værforhold';
export const VERFORHOLD_ETO_NAVN = 'verforhold';
export const TEMPERATUR = 'Temperatur';
export const TEMPERATUR_ETO_NAVN = 'temperaturIntervaller';
export const FYLKE = 'Fylke';
export const FYLKE_ETO_NAVN = 'fylker';
export const KOMMUNE = 'Kommune';
export const KOMMUNE_ETO_NAVN = 'kommuner';
export const BY = 'By';
export const BY_ETO_NAVN = 'byer';
export const BYDEL = 'Bydel';
export const BYDEL_ETO_NAVN = 'bydeler';
export const TETTSTED = 'Tettsted';
export const POLITIDISTRIKT = 'Politidistrikt';
export const TETTSTED_ETO_NAVN = 'tettsteder';
export const POLITIDISTRIKT_ETO_NAVN = 'politidistrikt';
export const ULYKKESTYPEROGALVORLIGHETSGRAD = 'Ulykkestyper og alvorlighetsgrad';
export const ALVORLIGHETSGRAD = 'Alvorlighetsgrad';
export const ALVORLIGHETSGRAD_ETO_NAVN = 'alvorlighetsgrader';
export const ULYKKESTYPER = 'Ulykkestyper';
export const ULYKKESTYPER_ETO_NAVN = 'ulykkestyper';
export const ULYKKESKODER = 'Ulykkeskoder';
export const ULYKKESKODER_ETO_NAVN = 'ulykkeskoder';
export const PERSON = "Person";
export const PERSON_ETO_NAVN = "person";
export const KJONN = 'Kjønn';
export const KJONN_ETO_NAVN = 'kjonn';
export const ALDERSGRUPPE = 'Aldersgruppe';
export const ALDERSGRUPPE_ETO_NAVN = 'ssbAlderIntervaller';
export const ALDERSGRUPPE_5ARIG = 'Aldersgruppe 5-årig';
export const ALDERSGRUPPE_5ARIG_ETO_NAVN = 'femarigAlderIntervaller';
export const TRAFIKANTTYPER = 'Trafikkanttyper';
export const TRAFIKANTTYPER_ETO_NAVN = 'trafikanttyper';
export const PLASSERING = 'Plassering';
export const PLASSERING_ETO_NAVN = 'plasseringer';
export const SKADEGRAD = 'Skadegrad';
export const SKADEGRAD_ETO_NAVN = 'skadegrad';


export const AR = "År";
export const AR_ETO_NAVN = "ar";
export const MANED = "Måned";
export const MANED_ETO_NAVN = "maneder";
export const UKEDAG = "Ukedagstype";
export const UKEDAGSTYPE_ETO_NAVN = "ukedagtyper";
export const TIMETYPE = "Timetype";
export const TIMETYPE_ETO_NAVN = "timetyper";

export const ENHET = 'Enhet';
export const ENHET_ETO_NAVN = 'enhet';
export const ENHETSTYPE_HOVEDKATEGORI = 'Enhetstype hovedkategori';
export const ENHETSTYPE_HOVEDKATEGORI_ETO_NAVN = 'enhetstypeHovedkategorier';
export const ENHETSTYPE_UNDERKATEGORI = 'Enhetstype underkategori';
export const ENHETSTYPE_UNDERKATEGORI_ETO_NAVN = 'enhetstypeUnderkategorier';
export const ENHETSTYPE= 'Enhetstype';
export const ENHETSTYPE_ETO_NAVN= 'enhetstyper';
export const REGISTRERINGSLAND = 'Registreringsland';
export const REGISTRERINGSLAND_ETO_NAVN = 'registreringsland';
export const ARSMODELL = 'Årsmodell';
export const ARSMODELL_ETO_NAVN = 'arsModeller';
export const MERKE_KJORETOY = 'Kjøretøymerke';
export const MERKE_KJORETOY_ETO_NAVN = 'kjoretoyMerker';
export const DRIVSTOFFTYPE = 'Drivstofftype';
export const DRIVSTOFFTYPE_ETO_NAVN = 'drivstoff';
export const PAKJORT_TYPE_HINDER = 'Påkjørt type hinder';
export const PAKJORT_TYPE_HINDER_ETO_NAVN = 'hinder';
export const TILHENGER = 'Tilhenger';
export const TILHENGER_ETO_NAVN = 'tilhengere';
export const TUNGTRANSPORT = 'Tungtransport';
export const TUNGTRANSPORT_ETO_NAVN= 'tungtransport';
export const VOGNTOG = 'Vogntog';
export const VOGNTOG_ETO_NAVN = 'vogntog';

export const RAD_EN = 'Rad 1';
export const RAD_TO = 'Rad 2';
export const RAD_TRE = 'Rad 3';
export const KOLONNE = 'Kolonne';
export const DATASETT = 'Datasett';

export const ALDER_LOGISK_FEILMELDING = 'Det er ikke tillatt å velge både aldersgruppe og aldersgruppe 5 årig';
export const ALDER_LOGISK_FEILMELIND_RAD_KOLONNE = 'Obs: Dette valget deaktiverer en annen valgmulighet av hensyn til personvern.';
export const UKEDAG_MANED_FEILMELDING = 'Det er ikke tillat å velge både måned og ukedag.';
export const UKEDAG_MANED_FEILMELDING_RAD_KOLONNE = 'Obs: Dette valget deaktiverer en annen valgmulighet av hensyn til personvern.';

export const ANTALL_PERSONSKADER = 'personskader';
export const ANTALL_PERSONSKADEULYKKER = 'personskadeulykker';

export const ANTALL_PERSONSKADER_TITTEL = 'Antall personskader';
export const ANTALL_PERSONSKADEULYKKER_TITTEL = 'Antall ulykker';
export const INGEN_TREFF = "Fant ingen treff på dette søket. Endre søket ditt og prøv igjen!";
export const FORBUDT = "Kombinasjonen av søket ditt er forbudt. Se igjennom steg 2 og steg 3 for mer informasjon.";
export const GIKK_GALT = "Obs! Noe gikk galt. Prøv igjen litt senere.";
export const IKKE_HENTE = "Obs! Noe gikk galt. Jeg kunne ikke hente data fra serveren. Derfor er alle felter deaktivert. Vi jobber allerede med saken, kom gjerne tilbake litt senere.";

export const INFORMASJONSTEKST_BETA = 'Betaversjon, enkelte oppsett kan inneholde feil.';

export const SPORSMALTEGNTEKST_FILTER = "Dersom du ønsker å filtrere på hvilke ulykker du ønsker å få informasjon om i tabellen, kan du gjøre det her (f.eks. filtrere på utvalgte fylker, kommuner, år, ulykkestyper etc.). ";
export const SPORSMALTEGNTEKST_DATASETT = "De fleste ulykkene fører ofte til at flere personer blir drept/skadd. Du må her velge om du ønsker at tabellen du skal lage skal vise antall personskader (i ulykkene) eller antall personskadeulykker. Standardoppsett er at antall personskader vises som tallverdier. ";
export const SPORSMALTEGNTEKST_RADKOLONNE = "Du kan velge hvilken informasjon som skal vises i tabellen. Du kan velge opptil 3 typer informasjonselementer å vise i rader, og 1 som skal vises i kolonnene. Standardoppsett er at år vises i radene og skadegrad for personene vises i kolonnene. ";

export const STANDARDRAPPORTER_FILTER_OVERSKRIFT = "Velg år og fylke";
export const STANDARDRAPPORTER_OVERSKRIFT = "Søk i biblioteket etter standardrapporter";
export const STANDARDRAPPORTER_INFO = "Her kan du søke i biblioteket etter standardrapporter. Velg år og fylke  i nedtrekksmenyen, og trykk på søk. Hvis" +
  " rapportene ikke er tilgjengelig kan du bestille disse på epost ved å trykke på bestill. Du er nødt til å velge et år for å kunne utføre et søk og få frem resultatene.";

export const PDF = '.pdf';
export const CSV = '.csv';
export const XLSX = '.xlsx';

export const POST = 'POST';
export const GET = 'GET';
export const SAME_ORIGIN = 'same-origin';
export const APPLICATION_JSON = 'application/json';

//Konstanter for knapp
export const LITEN_KNAPP = "button--small";
export const SEKUNDAER_KNAPP = "button--secondary";