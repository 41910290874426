import React, { useEffect, useState, useCallback} from "react";
import PropTypes from "prop-types";
import "./modal.css"
import LukkIkon from "../../ressurser/icons/button-close-default.svg";
import FocusTrap from "focus-trap-react";

const Modal = ({innholdTop, innhold, innholdBunn, open, setOpen, brukRef}) => {

//Focus trap tvinger nettleseren til å iterere gjennom knappene på modalen og ikke gå til elementene bak.
//Focus trap aktiveres og deaktiveres mhp om modalen er synlig på siden eller ikke.
//Ref setter fokus til header.

    const [activeTrap, setActivateTrap] = useState(open);


    const fokuserOverskrift = useCallback((overskriftElement) => {
        if (overskriftElement && brukRef) {
          overskriftElement.focus();
        }
      }, []);


    useEffect(()=>{
      const keyDownHandler = event => {    
          if (event.key === 'Escape' && open) {
            event.preventDefault();
            setOpen(false)
          }
        };
      document.addEventListener('keydown', keyDownHandler);
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[])

    useEffect(() => {
        if(!open){
            unmountTrap()
            return;
        }
        mountTrap()
      }, [open]);
 
    const mountTrap = () => {
        setActivateTrap(true);
      };
    
      const unmountTrap = () => {
        setActivateTrap(false);
      };    

    const trap = activeTrap
    ? <FocusTrap
        focusTrapOptions={{
          onDeactivate: unmountTrap
        }}
      >
    <div className={"modal" + (open ? "__visible" : "")}>
        <div className="modal_innhold">
            <div className="modal_top">
              <span tabIndex="-1" ref={fokuserOverskrift} className="modal_overskrift">{innholdTop}</span>
              <button aria-label="Lukk" className="modal_lukk" type="button" onClick={setOpen ? () => setOpen(false) : undefined}>
                <img alt="" src={LukkIkon} className="lukk_ikon"/>
              </button>
            </div>
            {innhold}
            <div className="knapp-rad">
                {innholdBunn}
            </div>    
        </div>
    </div>
    </FocusTrap>: false;


    return(
        <div aria-modal="true" role="dialog" title="Meld inn grunnen til at du er her.">
            <div className={"modal__backdrop" + (open ? "__visible" : "")}/>             
            {trap}
        </div>
    );
};

Modal.propTypes = {
    innholdTop: PropTypes.object.isRequired,
    innhold: PropTypes.object.isRequired,
    innholdBunn: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func,
    brukRef:PropTypes.bool
}


Modal.defaultProps = {
    setOpen: undefined,
    brukRef: false
}

export default Modal;