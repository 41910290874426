import React from 'react'
import './LastNedTabell.css';
import { faFilePdf, faFileExcel, faFileCsv} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CSV, PDF, XLSX} from "../../ressurser/Konstanter";
import {cloneDeep} from "lodash";
import {FYLKE, KOMMUNE} from "../raderogkolonner/RaderOgKolonnerKonstanter";

/**
 * DropwdownNedlastning er en dropdown for nestlastning av tabell.
 * I dropdown kan man velge å laste ned tabellen i tre ulike filtyper, pdf, csv, og xlxs.
 */
export default class LastNedTabell extends React.Component{
  constructor(props){
    super(props);
    this.nedlastning = this.nedlastning.bind(this);
  }


  // Tar inn kodeverk på formen "<kode> - <navn>" og returnerer kun <navn>
  strippKodeFraKodeverk(kodeverk) {
    return kodeverk.replace(/^\d*\s-\s/, "");
  }

  // Fjerner kodeverkskode fra datasett med mindre det er snakk om fylke eller kommune
  strippKodeFraDatasett(krysstabell) {
    return krysstabell.map(rad => {
      const radStrippet = {};

      Object.keys(rad).forEach(key => {
        if (key === 'verdi' || key.toString() === KOMMUNE.toString() || key.toString() === FYLKE.toString()) {
          radStrippet[key] = rad[key];
        } else {
          radStrippet[key] = this.strippKodeFraKodeverk(rad[key]);
        }
      });

      return radStrippet;
    });
  }

  // Fjerner kodeverkskode fra tabellverdier med mindre det er snakk om fylke eller kommune
  strippKodeFraTabellverdier(raderOgKolonnerValgETO, tabellverdier) {
    if(raderOgKolonnerValgETO.rad1 && raderOgKolonnerValgETO.rad1.id !== KOMMUNE && raderOgKolonnerValgETO.rad1.id !== FYLKE) {
      tabellverdier.rad1 = tabellverdier.rad1.map(verdi => this.strippKodeFraKodeverk(verdi));
    }
    if(raderOgKolonnerValgETO.rad2 && raderOgKolonnerValgETO.rad2.id !== KOMMUNE && raderOgKolonnerValgETO.rad2.id !== FYLKE) {
      tabellverdier.rad2 = tabellverdier.rad2.map(verdi => this.strippKodeFraKodeverk(verdi));
    }
    if(raderOgKolonnerValgETO.rad3 && raderOgKolonnerValgETO.rad3.id !== KOMMUNE && raderOgKolonnerValgETO.rad3.id !== FYLKE) {
      tabellverdier.rad3 = tabellverdier.rad3.map(verdi => this.strippKodeFraKodeverk(verdi));
    }
    if(raderOgKolonnerValgETO.kolonne && raderOgKolonnerValgETO.kolonne.id !== KOMMUNE && raderOgKolonnerValgETO.kolonne.id !== FYLKE) {
      tabellverdier.kolonne1 = tabellverdier.kolonne1.map(verdi => this.strippKodeFraKodeverk(verdi));
    }

    return tabellverdier;
  }

  nedlastning(filtype){
    const {sokETO, sokETOResultat, hentNedlastning} = this.props;
    const { raderOgKolonnerValgETO } = sokETO;
    const sokETOResultatImmutable = cloneDeep(sokETOResultat);

    sokETOResultatImmutable.data = this.strippKodeFraDatasett(sokETOResultatImmutable.data);
    sokETOResultatImmutable.tabellverdier = this.strippKodeFraTabellverdier(raderOgKolonnerValgETO, sokETOResultatImmutable.tabellverdier);

    hentNedlastning(sokETOResultatImmutable, filtype);
  }

  render(){
    const {sokETOResultat} = this.props;
    if(sokETOResultat){
      return(
        <div className='lastnedkonteiner'>
          <p id="lastnedtabellid" className='lastnedtabell'>Last ned:</p>
          <div className='lastnedknapper'>
            <button aria-describedby='lastnedtabellid' className='btnlastned' onClick={() => this.nedlastning(PDF)}>PDF <FontAwesomeIcon icon={faFilePdf} /></button>
            <button aria-describedby='lastnedtabellid' className='btnlastned' onClick={() => this.nedlastning(XLSX)}>Excel <FontAwesomeIcon icon={faFileExcel}/> </button>
            <button aria-describedby='lastnedtabellid' className='btnlastned' onClick={() => this.nedlastning(CSV)}>CSV <FontAwesomeIcon icon={faFileCsv} /></button>
          </div>
        </div>
      );
    }
    else{
      return(
          null
      );
    }
  }
}