import React, { useCallback, useEffect} from 'react';
import './OmStatistikken.css';
import '../uukomponenter/accordion.css';
import Accordion from "../uukomponenter/Accordion";
import {
    PERSONVERN, FEILKILDER, BAKGRUNN, KRYSSTABELL
} from "./tekstKonstanter";
import Tilbakeknapp from '../uukomponenter/Tilbakeknapp';
import {useDispatch} from "react-redux";
import {setValgtSide} from "../../actions";

/**
 * Infoside er hovedkomponenten på siden om info om trafikkulykker og fungerer som en klasse som visualiserer alle elementene.
 */
const Definisjoner = () => {
    return(
        <div className="infoside">
            <h4 style={{fontWeight: 600}}>Sted</h4>
            <p><span className="kursiv-tekst">Fylke, kommune, by og bydel</span> er stedsinformasjon som gjelder oppdatert
                informasjon om stedet der ulykken skjedde. Dvs. hvis f.eks. en ulykke skjedde i Nord-Trøndelag i år 2000, så er ulykken
                nå registrert under Trøndelagsfylket. Statens vegvesen har ikke informasjon for ulykkene om historiske fylker og kommuner etc.
                For tettsted så lagres informasjonen om dette, slik at tettstedet omhandler informasjon om det var tettbebyggelse eller ikke
                på ulykkestidspunktet der ulykken skjedde. All stedsinformasjon defineres ut fra koordinater for hvor ulykken skjedde.</p>
            <p><span className="kursiv-tekst">Tettsted</span> defineres som et sted med hussamlinger der det er registrert bosatt
                minst 200 personer og der avstanden mellom husstandene normalt ikke skal overstige 50 meter (kilde: Datasett,
                Norges tettsteder, SSB).</p>

            <h4 className="om-statistikken-overskrift">Tid</h4>
            <p>Følgende tidsinformasjon er tilgjengelig: år, måned, ukedag og timetype. Du kan benytte informasjonen til å
                filtrere på tidspunkt for ulykkene, og/eller se fordelinger av personskadeulykkene eller personskadene i tabellen.</p>

            <h4 className="om-statistikken-overskrift">Ulykkestyper</h4>
            <p><span className="kursiv-tekst">Ulykkestypene</span> er generert ut fra ulykkeskodene (som også er tilgjengelige i tabellen).</p>
            <p><span className="kursiv-tekst">Ulykkeskodene</span> beskriver ulykkens art som ga direkte personskader i ulykken.
                Dvs. hvis to biler i motsatt kjøreretning havner i front-til front kollisjon, så er det en møteulykke hvis dette sammenstøtet
                fører til personskader. Ulykkeskodene er fragmentert informasjon slik at du eksempelvis kan se om møteulykkene
                har skjedd på rett vegstrekning, eller i kurve.</p>

            <h4 className="om-statistikken-overskrift">Alvorlighetsgrad</h4>
            <p>Der skadegrad angir alvorlighetsgraden på skadene til en person, angir alvorlighetsgrad alvorligste skadegrad i ulykken.
                Dersom en ulykke har ført til at en person er drept og en annen person er hardt skadet, så bli ulykkens alvorighetsgrad
                satt til dødsulykke."</p>

            <h4 className="om-statistikken-overskrift">Vær og føre</h4>
            <p>Værforhold, føreforhold, lysforhold og temperatur defineres av politiet på ulykkesstedet. Disse informasjonselementene
                er dermed en subjektiv vurdering av klimatiske forhold på ulykkesstedet da ulykken inntraff.</p>

            <h4 className="om-statistikken-overskrift">Veg</h4>
            <p>Informasjon om vegen hentes i hovedsak ut fra Nasjonal vegdatabank (NVDB) ut ifra koordinater politiet har angitt for ulykken.
                NVDB gir informasjon om vegreferanse på ulykkesstedet, og ut ifra vegreferansen blir faktainformasjon om bl.a.
                vegkategori, vegtype, stedsforhold, fartsgrenser, vegdekke, kjørefeltstype, regulering i kryss og gangfelt lagret på ulykken.</p>

            <h4 className="om-statistikken-overskrift">Enhet</h4>
            <p>Informasjon om kjøretøy er i henhold til offisielle definisjoner i Forskrift om tekniske krav og godkjenning av kjøretøy,
                deler og utstyr (Kjøretøyforskriftens § 2). For norske kjøretøy er all informasjon hentet fra det sentrale motorvognregisteret.</p>

            <h4 className="om-statistikken-overskrift">Person</h4>
            <p>Skadegrad for person gjelder alvorlighetsgraden av skadene en person har blitt påført som følge av en ulykke.</p>
            <p><span className="kursiv-tekst">Skadegrad: drept</span> gjelder personer som dør som følge av ulykken inntil
                30 dager etter ulykkestidspunktet.</p>
            <p><span className="kursiv-tekst">Skadegrad: hardt skadd</span> gjelder skader som enten truer pasientens liv eller
                fører til varige mén (meget alvorlig skadd), eller større, men ikke livstruende skader (alvorlig skadd).</p>
            <p><span className="kursiv-tekst">Skadegrad: lettere skadd</span> gjelder personer med mindre brudd, skrammer og
                skader som ikke krever sykehusinnlegging.</p>
        </div>
    );
}

const OmStatistikken = () => {
    const dispatch = useDispatch();
  
    const fokuserOverskrift = useCallback((overskriftElement) => {
      if (overskriftElement) {
        overskriftElement.focus();
      }
    });

    useEffect(()=>{
      document.title = "Om statistikken | Trine";
      dispatch(setValgtSide("om"));
    })


    return (
    <>
      <div className="omStatistikken">
          <Tilbakeknapp/>
          <h1 ref={fokuserOverskrift} tabIndex="-1" className="normaltekst">Om statistikken</h1>
          <p className="normaltekst">
            Her finner du bakgrunnsinformasjon om statistikken som ligger i Trafikkulykkesregisteret. Statistikken omfatter politi-rapporterte vegtrafikkulykker
            (dødsulykker eller ulykker med betydelig personskade) i Norge.
            Trafikkulykkene som inngår i statistikken har skjedd på veg åpen for allmenn ferdsel, og involverer minst ett kjøretøy.
          </p>
          <p className='normaltekst'>
              Statistikken baserer seg på data fra politiet og Statistisk sentralbyrå (SSB), og oppdateres årlig når SSB har publisert offisielle endelige tall (vanligvis i mai/juni etterfølgende år).
              Dataene er lisensiert med Norsk lisens for offentlige data (NLOD). 
          </p>
          <h2 className="utvidet-info heading-decoration">Utvidet informasjon</h2>
          <div className="accordion-begrensning">
              <Accordion innhold={() => <p>{BAKGRUNN}</p>} tittel="Bakgrunn" headerNiva="3" />
              <Accordion innhold={() => Definisjoner()} tittel="Definisjoner" headerNiva="3"/>
              <Accordion innhold={() => <p>{FEILKILDER}</p>} tittel="Feilkilder" headerNiva="3"/>
              <Accordion innhold={() => <p>{KRYSSTABELL}</p>} tittel="Krysstabell" headerNiva="3"/>
              <Accordion innhold={() => <p>{PERSONVERN}
                  <a target="_blank" href='https://www.vegvesen.no/om+statens+vegvesen/om+organisasjonen/personvern' rel="noopener noreferrer">Statens vegvesens personvernerklæring</a>
              </p>} tittel="Personvern" headerNiva="3"/>
          </div>
        </div>
    </>
    );
  }

export default OmStatistikken;
