import {sendTilbakemelding, setTakkTilbakemelding, setVisTilbakemeldingmodal} from "../../actions";
import TilbakemeldingModal from '../uukomponenter/TilbakemeldingModal';
import Footer from './Footer';
import {connect} from "react-redux";
import {selectTakkTilbakemelding, selectVisTilbakemeldingmodal} from "../midtdel/midtdelSelector";

const sendTilbakemeldingDispatch = dispatch => ({
  sendTilbakemelding: (tekst, epost) => {
    dispatch(sendTilbakemelding(tekst, epost))},
  setTakkTilbakemelding: vis => {
    dispatch(setTakkTilbakemelding(vis))
  },
  setVisTilbakemeldingmodal: vis => {
    dispatch(setVisTilbakemeldingmodal(vis))
  }
});

const mapStateToProps = state => ({
  takkTilbakemelding: selectTakkTilbakemelding(state),
  visTilbakemeldingmodal: selectVisTilbakemeldingmodal(state)
});

export const Tilbakemelding = connect(
    mapStateToProps,
    sendTilbakemeldingDispatch,
)(TilbakemeldingModal);

export const Fot = connect(
  mapStateToProps,
  sendTilbakemeldingDispatch,
)(Footer);
