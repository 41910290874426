import React, { useState } from 'react';
import Select, {components} from 'react-select';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import '../../components/standardkomponenter/nedtrekksliste.css';

const DroppNedIndikator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            {props.erDeaktivert ? <FontAwesomeIcon icon={faAngleDown} /> :
            <FontAwesomeIcon icon={faAngleDown} color="orange"/> }
        </components.DropdownIndicator>
    );
};

function IngenMuligheterMelding() {
    return <span>Ingen Resultater</span>;
}

const personligeStiler = (erMulti) => ({
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#ffffff",
        cursor: "pointer",
        borderRadius: 'none',
        marginTop:0,
    }),
    control: (provided, state) => ({
        ...provided,
        boxShadow: 'none',
        border: "2px solid #444F55",
        borderRadius: 'none',
        '&:hover': { borderBottomColor: "#ff9600"},
        '&:focus': { borderBottomColor: "#ff9600"},
        borderBottomColor: state.isFocused ? "#ff9600" : "#444f55",
        color: "#444f55",
        backgroundColor: "#ffffff",
        cursor: "pointer",
        padding: "0px 8px",
        paddingLeft:0,
        paddingRight:0,
        minHeight:"30px"
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#f5f5f5" : "#ffffff",
        border: state.isFocused ? "1px solid #444f55" : 0,
        '&:hover': {backgroundColor: "#f5f5f5", border: "1px solid #444f55"},
        lineHeight: "1rem",
        color: "hsl(0, 0%, 20%)"
    }),
    menuList: (provided) => ({
        ...provided,
        "::-webkit-scrollbar":{
            width: "12px",
            borderRadius: 0,
        },
       "::-webkit-scrollbar-track":{
           backgroundColor:"#f1f1f1"
       },
        "::-webkit-scrollbar-thumb":{
            background:"#444f55",
        },
    }),
    placeholder: (styles) => ({
        ...styles,
        color: "#444f55",
        fontWeight: 400,
        fontSize: "16px"
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingTop:0,
        margin: '0 0 0 4px',
        overflow:"visible",
    }),
    input: (styles) => ({
        ...styles,
        height:"35px",
        marginTop: 0,
      }),
    multiValue:(styles)=>({
        ...styles,
        border:0,
        height:"33px",
        margin:"2px 0 2px 2px"
    }),
    singleValue:(styles)=>({
        ...styles,
        margin:0,
        marginLeft:"2px",
        paddingLeft:"6px",
        fontWeight: 500
    }),
    dropdownIndicator:(styles)=>({
        ...styles,
        color: "#444f55"
    }),
    indicatorSeparator:(styles)=>({
        ...styles,
        backgroundColor: "#ffffff"
    }),
    indicatorsContainer:(styles)=>({
        ...styles,
        color: "#444f55"
    })
})


export default function NedtrekksListe({navn, verdi, valgmuligheter, onChange, erDeaktivert, feilmelding, ingenKodeverk, erMulti, slettKnapp, selectRef}) {
    const [erMenyApen, settErMenyApen] = useState(false);
    const vedMenyApen = () => settErMenyApen(true);
    const vedMenyLukke = () => settErMenyApen(false);
    const AriaMelding = ({focused}) => {
        return focused.label + ". Valg nr. " + focused.index + " av " + valgmuligheter.length;
    }

    return (
        <>
            <form className="nedtrekksliste" aria-describedby={erDeaktivert ? "feilmelding-"+navn.replace(/\s/g, "") : "Nedtrekksliste-" + navn.replace(/\s/g, "")}>
                <label className="nedtrekksliste-label" id={"label-"+navn.replace(/\s/g, "")} htmlFor={"select-" + navn.replace(/\s/g, "")}>
                    {navn}
                </label>
                <div role="listbox" aria-label={navn}>
                <div className='nedtrekklistmedfjernknapp' aria-label={navn}>
                    <Select
                        ref={selectRef}
                        autoFocus
                        styles={personligeStiler(erMulti)}
                        id={"Nedtrekksliste-" + navn.replace(/\s/g, "")}
                        role="combobox"
                        aria-live="polite"
                        ariaLiveMessages={{AriaMelding}}
                        label={"Nedtrekksmeny"}
                        aria-labelledby={"label-"+navn.replace(/\s/g, "")}
                        inputId={"select-" + navn.replace(/\s/g, "")}
                        value={verdi}
                        name={navn}
                        closeMenuOnSelect={!erMulti}
                        onMenuOpen={vedMenyApen}
                        onMenuClose={vedMenyLukke}
                        options={valgmuligheter}
                        isMulti={erMulti}
                        autocomplete={true}
                        placeholder={navn === 'Fylke' ? 'Alle' : 'Velg...'}
                        isSearchable
                        noResultsText={"Ingen resultater funnet."}
                        components={{DroppNedIndikator, IngenMuligheterMelding}}
                        isClearable={erMulti && verdi && verdi[0].label!==null ? true:false}
                        onChange={onChange}
                        isOptionDisabled={option => option.tillat === false}
                        isDisabled={erDeaktivert || ingenKodeverk}
                        controlShouldRenderValue = {!erMulti || verdi[0].label!==null ? true:false }
                        onKeyUp={(e) => console.log(e.target)}
                    />
                    {slettKnapp}
                </div>
                </div>
            </form>
            {erDeaktivert && (<p id={"feilmelding-"+navn.replace(/\s/g, "")} className="nedtrekksliste-ulovligfilter-tekst" aria-live="polite">{feilmelding}</p> )}
            {!erMulti && (<p id={"feilmelding-"+navn.replace(/\s/g, "")} className="nedtrekksliste-ulovlig-rad-kolonne-tekst" aria-live="polite">{feilmelding}</p>)}
        </>
    )
}

NedtrekksListe.propTypes = {
    navn: PropTypes.string.isRequired,
    valgmuligheter: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.array.isRequired]),
    onChange: PropTypes.func.isRequired,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    }),
    erDeaktivert: PropTypes.bool,
    slettKnapp: PropTypes.object
};
