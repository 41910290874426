import React, {useState} from "react";
import PropTypes from "prop-types";
import "./accordion.css"

const Accordion = (props) => {
    const {tittel, innhold, ikon, undertittel, active, customstyle, headerNiva} = props
    const [isActive, setIsActive] = useState(active);

    const toggleIsActive = () => setIsActive(!isActive)
    const Tag = "h" + headerNiva;

    return (
        <div className={"accordion" + (ikon ? " accordion--image" : "") + (isActive ? " is-active " : " ") + "_js-accordion " + customstyle}>
            <Tag className={"accordion__heading " + ikon}>
                <button type="button" aria-controls={"content-"+tittel.replace(/\s/g, "")} onClick={() => toggleIsActive()} className="button button--text-only _js-accordion-button" aria-expanded={(isActive ? "true" : "false")} id={"accordion-control-"+tittel.replace(/\s/g, "")}>
                    {tittel} <br/>
                    {undertittel && undertittel()}
                </button>
            </Tag>
            <div className={"accordion__content _js-accordion-content editorial"} style={{paddingLeft:0}} id ={"content-"+tittel.replace(/\s/g, "")} aria-hidden={(isActive ? "false" : "true")}>
                {innhold()}
            </div>
        </div>
    );
}

Accordion.propTypes = {
    tittel: PropTypes.string.isRequired,
    innhold: PropTypes.func.isRequired,
    ikon: PropTypes.string,
    undertittel: PropTypes.func,
    active: PropTypes.bool,
    customstyle: PropTypes.string,
    headerNiva: PropTypes.string.isRequired
}

Accordion.defaultProps = {
    ikon: "",
    undertittel: null,
    active: false,
    customstyle: ""
}

export default Accordion;