import React from 'react';
import {connect} from 'react-redux';
import {resetSokEto} from "../../actions";
import {selectFilterNullstilt} from "./datafiltreringSelector";
import nullstillIkon from "../../ressurser/icons/kryss.svg";
import "./NullstillFilterKnapp.css";


const  NullstillFilterKnapp = (props) => {

    const handterKlikk = () => {
        props.nullstillSokEto();
    }

    
    return (!props.nullstillFilter && props.nullstillFilter!==null ? (
        <button aria-label="Nullstill filter" className="nullstill-filter" type="button" onClick={() => handterKlikk()}>
            <img alt="" src={nullstillIkon} className="nullstill-ikon"/>
            <p className='nullstill-filter-tekst'>Nullstill filter</p>
        </button>    
        ):(<></>))
};

const mapDispatchToProps = dispatch => ({
   nullstillSokEto: () => dispatch(resetSokEto())
});

const mapStateToProps = state => ({
    nullstillFilter: selectFilterNullstilt(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(NullstillFilterKnapp);
