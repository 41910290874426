import React from 'react';
import './footer.css';
import chatikon from "../../ressurser/icons/chat.svg"
import {Link} from "react-router-dom";

/**
 * Footer er en container som inneholder nødvending informasjon
 * om bedriften. Det skal finnes kontaktinformasjon og om selskapet.
 */
class Footer extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <>
        <footer className="footer-global">
            <nav>
              <div className='footer-container'>
                <div className='footer-seksjon'>
                  <h2 className="footer-info-tittel">Statens vegvesen</h2>
                  <ul  aria-label="Statens vegvesen">
                    <li><a target="_blank" rel="noopener noreferrer" href='https://www.vegvesen.no/om-oss/om-organisasjonen/'>Om vegvesenet</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href='https://www.vegvesen.no/om-oss/om-organisasjonen/offentlig-journal/'>Offentlig journal</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href='https://www.vegvesen.no/fag/publikasjoner/hoeringer/'>Offentlige høringer</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href='https://www.vegvesen.no/om-oss/kontakt-oss/skjema/ '>Skjemaer</a></li>
                    <li>
                      <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href='https://www.vegvesen.no/om-oss/om-organisasjonen/om-statens-vegvesen/folg-oss-i-sosiale-medier/'>
                        Følg vegvesenet på sosiale medier
                      </a>
                    </li>
                      <li>
                          <p className="trafikkinfo-tekst">
                              Org.nr: 971 032 081
                          </p>
                      </li>
                      <li>
                          <p className="trafikkinfo-tekst">Har du viktig trafikkinformasjon, ring tlf: 175</p>
                      </li>
                  </ul>
                </div>
                <div className='footer-seksjon'>
                  <h2 className="footer-info-tittel">Om trine.atlas.vegvesen.no</h2>
                  <ul  aria-label="Om trine.atlas.vegvesen.no">
                    <li>
                      <Link to="/nettstedskart">
                        <span className="nettstedskart">Nettstedskart</span>
                      </Link>
                    </li>
                      <li>
                        <a target="_blank"
                           rel="noopener noreferrer"
                           href='https://uustatus.no/nb/erklaringer/publisert/d1c12d1d-a7e4-4716-8804-a6ec448daf4d'>Tilgjengelighetserklæring
                        </a>
                      </li>
                      <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href='https://www.vegvesen.no/om-oss/om-organisasjonen/personvern/statens-vegvesens-personvernerklaering/ '>Personvernerklæring
                        </a>
                      </li>
                  </ul>
                </div>
                <div className='footer-seksjon'>
                  <button className="button--primary" onClick={() => this.props.setVisTilbakemeldingmodal(true)}>
                      <span className="link-card__img-container">
                          <img src={chatikon} alt=""/>
                        </span>
                    Gi oss tilbakemelding
                  </button>
                </div>
              </div>
            </nav>
        </footer>
      </>
    );
  }
}

export default Footer;
