import FilterNedtrekksliste from "../standardkomponenter/FilterNedtrekksliste";
import {connect} from "react-redux";
import {
    ALDERSGRUPPE,
    ALDERSGRUPPE_5ARIG,
    ALDERSGRUPPE_5ARIG_ETO_NAVN,
    ALDERSGRUPPE_ETO_NAVN,
    KJONN,
    KJONN_ETO_NAVN,
    PERSON_ETO_NAVN,
    PLASSERING,
    PLASSERING_ETO_NAVN,
    SKADEGRAD,
    SKADEGRAD_ETO_NAVN,
    TRAFIKANTTYPER,
    TRAFIKANTTYPER_ETO_NAVN
} from "../../ressurser/Konstanter";
import {
    selectAldersgruppe,
    selectAldersgruppe5arig,
    selectKjonn,
    selectKodeverk,
    selectPlassering,
    selectSkadegrad,
    selectSokETO,
    selectTrafikanttyper
} from "./datafiltreringSelector";
import {setFilterVerdi, setValgtFilter} from "../../actions";


const personFilterDispatch = dispatch => ({
    setFilter: (etoNavn, data) => {
        dispatch(setFilterVerdi(PERSON_ETO_NAVN, etoNavn, data));
    },
    setValg: (navn, valg) => {
        dispatch(setValgtFilter(navn, valg));
    }
});

const kjonnState = state => ({
    navn: KJONN,
    etoNavn: KJONN_ETO_NAVN,
    erMulti: true,
    valgmuligheter: selectKjonn(state)
});
export const Kjonn = connect(
    kjonnState,
    personFilterDispatch
)(FilterNedtrekksliste);


const aldersgruppeState = state => ({
    navn: ALDERSGRUPPE,
    etoNavn: ALDERSGRUPPE_ETO_NAVN,
    erMulti: true,
    valgmuligheter: selectAldersgruppe(state),
    filter: selectSokETO(state),
    kodeverkAlle: selectKodeverk(state),
    visUtenKode: true
});
export const Aldersgruppe = connect(
    aldersgruppeState,
    personFilterDispatch
)(FilterNedtrekksliste);

const aldersgruppe5arigState = state => ({
    navn: ALDERSGRUPPE_5ARIG,
    etoNavn: ALDERSGRUPPE_5ARIG_ETO_NAVN,
    erMulti: true,
    valgmuligheter: selectAldersgruppe5arig(state),
    filter: selectSokETO(state),
    kodeverkAlle: selectKodeverk(state),
    visUtenKode: true
});
export const Aldersgruppe5arig = connect(
    aldersgruppe5arigState,
    personFilterDispatch
)(FilterNedtrekksliste);


const trafikanttyperState = state => ({
    navn: TRAFIKANTTYPER,
    etoNavn: TRAFIKANTTYPER_ETO_NAVN,
    erMulti: true,
    valgmuligheter: selectTrafikanttyper(state)
});
export const Trafikanttyper = connect(
    trafikanttyperState,
    personFilterDispatch
)(FilterNedtrekksliste);

const plasseringState = state => ({
    navn: PLASSERING,
    etoNavn: PLASSERING_ETO_NAVN,
    erMulti: true,
    valgmuligheter: selectPlassering(state)
});
export const Plassering = connect(
    plasseringState,
    personFilterDispatch
)(FilterNedtrekksliste);

const skadegradState = state => ({
    navn: SKADEGRAD,
    etoNavn: SKADEGRAD_ETO_NAVN,
    erMulti: true,
    valgmuligheter: selectSkadegrad(state)
});
export const Skadegrad = connect(
    skadegradState,
    personFilterDispatch
)(FilterNedtrekksliste);
