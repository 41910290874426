import {RESET_SOKETO, RESET_FILTER_NULLSTILT} from "../actions/actionTypes";

const nullstillFilter = (state = {nullstilt: null},  action) => {
    if(action.type === RESET_SOKETO) {
        return {
            ...state,
            nullstilt: true
        }
    } else if (action.type === RESET_FILTER_NULLSTILT){
        return {
            ...state,
            nullstilt: false
        }
    } else {
        return state;
    }
};

export default nullstillFilter;