import {connect} from 'react-redux';
import FilterNedtrekksliste from '../standardkomponenter/FilterNedtrekksliste';
import {
  ALVORLIGHETSGRAD,
  ALVORLIGHETSGRAD_ETO_NAVN,
  ULYKKE_ETO_NAVN,
  ULYKKESKODER,
  ULYKKESKODER_ETO_NAVN,
  ULYKKESTYPER,
  ULYKKESTYPER_ETO_NAVN,
} from "../../ressurser/Konstanter";
import {setFilterVerdi, setValgtFilter} from "../../actions";
import {selectAlvorlighetsgrad, selectUlykkeskoder, selectUlykkestyper} from "./datafiltreringSelector";

const ulykkeFilterDispatch = dispatch => ({
  setFilter: (etoNavn, data) => {
    dispatch(setFilterVerdi(ULYKKE_ETO_NAVN, etoNavn, data));
  },
  setValg: (navn, valg) => {
    dispatch(setValgtFilter(navn, valg));
  }
});


const alvorlighetsgradState = state => ({
  navn: ALVORLIGHETSGRAD,
  etoNavn: ALVORLIGHETSGRAD_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectAlvorlighetsgrad(state)
});
export const Alvorlighetsgrad = connect(
    alvorlighetsgradState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const ulykkesyperState = state => ({
  navn: ULYKKESTYPER,
  etoNavn: ULYKKESTYPER_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectUlykkestyper(state)
});
export const Ulykkestyper = connect(
    ulykkesyperState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const ulykkeskoderState = state => ({
  navn: ULYKKESKODER,
  etoNavn: ULYKKESKODER_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectUlykkeskoder(state)
});
export const Ulykkeskoder = connect(
    ulykkeskoderState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


