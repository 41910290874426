import {SET_SOK_ETO_RESULTAT} from "../actions/actionTypes";

const sokETOResultat = (state = null, action) =>{
  switch(action.type){
    case SET_SOK_ETO_RESULTAT:
      return action.data;
    default:
      return state;
  }
};

export default sokETOResultat;