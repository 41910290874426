import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import './navigasjon.css';
import Linkkort from '../uukomponenter/Linkkort';
import veikart from "../../ressurser/icons/veikart.svg"
import statistikk from "../../ressurser/icons/statistikk.svg"
import info from "../../ressurser/icons/info.svg"

import {OM_STATISTIKKEN, OM_STATISTIKKEN_TEKST, STANDARDRAPPORTER, STANDARDRAPPORTER_TEKST, VEGKART, VEGKART_TEKST} from '../../ressurser/Konstanter';

/**
 * Navigasjon er en placeholder for alle navigasjonsknapper på denne nettsiden.
 */
class Navigasjon extends React.Component {
  constructor(props) {
    super(props);
    this.knappTrykket = this.knappTrykket.bind(this);
  }

  knappTrykket(verdi) {
    this.props.setOmStatistikkenValgt(verdi);
    this.props.getOmStatistikken();
    this.props.getDefinisjoner();
  };

  render() {
    return (
      <Container fluid className="navigasjon">
        <Row>
          <Col xl={{size: '4', offset: '0'}} md={{size: '8', offset: '2'}} sm={{size: '10', offset: '1'}} xs={{size: '10', offset: '1'}}>
            <Linkkort id={VEGKART} tittel={VEGKART} ikon={veikart} ikonTekst={VEGKART_TEKST} link="https://vegkart.atlas.vegvesen.no/"/>
          </Col>
          <Col xl={{size: '4', offset: '0'}} md={{size: '8', offset: '2'}} sm={{size: '10', offset: '1'}} xs={{size: '10', offset: '1'}}>
            <Linkkort id={OM_STATISTIKKEN.replace(/\s/g, '')} tittel={OM_STATISTIKKEN} ikon={info} ikonTekst={OM_STATISTIKKEN_TEKST} link="/om"/>
          </Col>
          <Col xl={{size: '4', offset: '0'}} md={{size: '8', offset: '2'}} sm={{size: '10', offset: '1'}} xs={{size: '10', offset: '1'}}>
            <Linkkort id={STANDARDRAPPORTER} tittel={STANDARDRAPPORTER} ikon={statistikk} ikonTekst={STANDARDRAPPORTER_TEKST} link="/rapporter"/>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Navigasjon;
