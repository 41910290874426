import FilterNedtrekksliste from "../standardkomponenter/FilterNedtrekksliste";
import {connect} from "react-redux";
import {
  FYLKE,
  FYLKE_ETO_NAVN,
  KOMMUNE,
  KOMMUNE_ETO_NAVN,
  POLITIDISTRIKT, POLITIDISTRIKT_ETO_NAVN,
  TETTSTED,
  TETTSTED_ETO_NAVN,
  ULYKKE_ETO_NAVN
} from "../../ressurser/Konstanter";
import {
  selectFylke,
  selectGeografiskForhold,
  selectKommune,
  selectKommuneEnkelt,
  selectPolitidistrikt,
  selectTettsted,
  selectValgtFilterKommune
} from "./datafiltreringSelector";
import {setFilterVerdi} from "../../actions";


const ulykkeFilterDispatch = dispatch => ({
  setFilter: (etoNavn, data) => {
    dispatch(setFilterVerdi(ULYKKE_ETO_NAVN, etoNavn, data));
  }
});

const setFilterVerdiDispatch = dispatch => ({
  setFilter: (etoNavn, data) => {
    dispatch(setFilterVerdi(ULYKKE_ETO_NAVN, etoNavn, data));
  }
});

const fylkeState = state => ({
  navn: FYLKE,
  etoNavn: FYLKE_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectFylke(state),
  geografisk: selectKommuneEnkelt(state),
  etoGeografisk: KOMMUNE_ETO_NAVN,
  valgtFilter: selectValgtFilterKommune(state)
});

export const Fylke = connect(
  fylkeState,
  setFilterVerdiDispatch
)(FilterNedtrekksliste);

const kommuneState = state => ({
  navn: KOMMUNE,
  etoNavn: KOMMUNE_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectKommune(state),
  geografisk: selectFylke(state),
  etoGeografisk: FYLKE_ETO_NAVN,
  sammensattGeografisk: selectGeografiskForhold(state),
});

export const Kommune = connect(
  kommuneState,
  setFilterVerdiDispatch
)(FilterNedtrekksliste);

const tettstedState = state => ({
  navn: TETTSTED,
  etoNavn: TETTSTED_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectTettsted(state)
});

export const Tettsted = connect(
  tettstedState,
  ulykkeFilterDispatch
)(FilterNedtrekksliste);

const politidistriktState = state => ({
  navn: POLITIDISTRIKT,
  etoNavn: POLITIDISTRIKT_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectPolitidistrikt(state)
});

export const Politidistrikt = connect(
    politidistriktState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);