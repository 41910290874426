import {connect} from 'react-redux';
import FilterNedtrekksliste from '../standardkomponenter/FilterNedtrekksliste';
import {
  FOREFORHOLD,
  FOREFORHOLD_ETO_NAVN,
  LYSFORHOLD,
  LYSFORHOLD_ETO_NAVN,
  TEMPERATUR,
  TEMPERATUR_ETO_NAVN,
  ULYKKE_ETO_NAVN,
  VERFORHOLD,
  VERFORHOLD_ETO_NAVN
} from "../../ressurser/Konstanter";
import {setFilterVerdi, setValgtFilter} from "../../actions";
import {selectForeforhold, selectLysforhold, selectTemperatur, selectVerforhold,} from "./datafiltreringSelector";

const ulykkeFilterDispatch = dispatch => ({
  setFilter: (etoNavn, data) => {
    dispatch(setFilterVerdi(ULYKKE_ETO_NAVN, etoNavn, data));
  },
  setValg: (navn, valg) => {
    dispatch(setValgtFilter(navn, valg));
  }
});

const temperaturState = state => ({
  navn: TEMPERATUR,
  etoNavn: TEMPERATUR_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectTemperatur(state)
});
export const Temperatur = connect(
    temperaturState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);

const foreforholdState = state => ({
  navn: FOREFORHOLD,
  etoNavn: FOREFORHOLD_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectForeforhold(state)
});
export const Foreforhold = connect(
    foreforholdState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);

const lysforholdState = state => ({
  navn: LYSFORHOLD,
  etoNavn: LYSFORHOLD_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectLysforhold(state)
});
export const Lysforhold = connect(
    lysforholdState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);

const verforholdState = state => ({
  navn: VERFORHOLD,
  etoNavn: VERFORHOLD_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectVerforhold(state)
});
export const Verforhold = connect(
    verforholdState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);
