

export const selectSistOppdatert = state => {
    let sistOppdatert = null;
    if (state.kodeverk && state.kodeverk.nyesteAr) {
        sistOppdatert = state.kodeverk.nyesteAr;
    }
    return sistOppdatert;
};

export const selectInformasjonstekst = state => {
    return state.informasjonstekst;
};