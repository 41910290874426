export const HENT_KODEVERK = "HENT_KODEVERK";

export const SET_KODEVERK ="SET_KODEVERK";

export const HENT_FEATURE_TOGGLE = "HENT_FEATURE_TOGGLE";

export const HENT_TRINE_VEDLIKEHOLD_BANNER = "HENT_TRINE_VEDLIKEHOLD_BANNER";

export const HENT_TRINE_VEDLIKEHOLD_TEKST = "HENT_TRINE_VEDLIKEHOLD_TEKST";

export const SET_FEATURE_TOGGLE = "SET_FEATURE_TOGGLE";

export const SET_TRINE_VEDLIKEHOLD_BANNER = "SET_TRINE_VEDLIKEHOLD_BANNER";

export const SET_TRINE_VEDLIKEHOLD_TEKST = "SET_TRINE_VEDLIKEHOLD_TEKST";

export const SEND_SOK_ETO = "SEND_SOK_ETO";

export const SET_FILTER_VERDI = "SET_FILTER_VERDI";

export const SET_RAPPORT_FILTER_VERDI = "SET_RAPPORT_FILTER_VERDI";

export const SET_SOK_ETO_RESULTAT = "SET_SOK_ETO_RESULTAT";

export const SET_VEGTYPE_FILTER = 'SET_VEGTYPE_FILTER';

export const SET_SKADEGRAD_FILTER = 'SET_SKADEGRAD_FILTER';

export const RESET_SOKETO = 'RESET_SOKETO';

export const RESET_FILTER_NULLSTILT = 'RESET_FILTER_NULLSTILT';

export const SET_DATASETT = "SET_DATASETT";

export const SET_RAD_EN = "SET_RAD_EN";

export const SET_RAD_TO = "SET_RAD_TO";

export const SET_RAD_TRE = "SET_RAD_TRE";

export const SET_KOLONNE = "SET_KOLONNE";

export const HENT_OM_STATISTIKKEN_ETO = "HENT_OM_STATISTIKKEN_ETO";

export const SET_OM_STATISTIKKEN_ETO = "SET_OM_STATISTIKKEN_ETO";

export const HENT_DEFINISJONER_ETO = "HENT_DEFINISJONER_ETO";

export const SET_DEFINISJONER_ETO = "SET_DEFINISJONER_ETO";

export const HENT_INFOTEKST = "HENT_INFOTEKST";

export const SET_INFOTEKST = "SET_INFOTEKST";

export const SET_OM_STATISTIKKEN_VALGT = "SET_OM_STATISTIKKEN_VALGT";

export const SET_INFO_TIL_BRUKER = "SET_INFO_TIL_BRUKER";

export const SET_BRUKER = "SET_BRUKER";

export const SEND_BRUKER_ETO = "SEND_BRUKER_ETO";

export const SET_VALGT_FILTER = "SET_VALGT_FILTER";

export const SET_FILTER_VISES_I_TABELL = "SET_FILTER_VISES_I_TABELL";

export const LAST_NED_TABELL = "LAST_NED_TABELL";

export const SEND_TILBAKEMEDLING = "SEND_TILBAKEMELDING";

export const SET_LOADING = "SET_LOADING";

export const SET_ALERT = "SET_ALERT";

export const SET_TAKK_TILBAKEMELDING = "SET_TAKK_TILBAKEMELDING";

export const SET_VIS_TILBAKEMELDINGMODAL = "SET_VIS_TILBAKEMELDINGMODAL";

export const SET_FILTER_NULLSTILT = "SET_FILTER_NULLSTILT";

export const SET_VALGT_SIDE = "SET_VALGT_SIDE";