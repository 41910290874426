import { connect } from 'react-redux';
import RaderOgKolonnerNedtrekksliste from './RaderOgKolonnerNedtrekksliste';
import RaderOgKolonnerKlasse from "./RaderOgKolonnerKlasse";
import {RAD_EN,RAD_TO,RAD_TRE, KOLONNE} from "../../ressurser/Konstanter";
import {
    setRadEn,
    setRadTo,
    setRadTre,
    setKolonne,
    sendSokETO
} from "../../actions";
import {
  selectSokRadEn,
  selectSokRadTo,
  selectSokRadTre,
  selectSokkolonne,
  selectSok,
  createSelectKodeverk, createSelectKodeverkRad1, createSelectKodeverkRad2, createSelectKodeverkRad3, createSelectKodeverkKolonne
} from "./raderOgKolonnerSelector";
import {selectSokETO} from "../datafiltrering/datafiltreringSelector";

/** RaderOgKolonnerContainer knytter RaderOgKolonnerNedtreksliste sammen med
 * redux store. For hver nedtrekksliste inneholder filen en funkjson for dispatch til redux store og en fil
 * funksjon for å hente ut staten valgt i redux store.
 * */

const RaderOGKolonnerState = state => ({
  valgRadEn: selectSokRadEn(state),
  valgRadTo: selectSokRadTo(state),
  valgRadTre: selectSokRadTre(state),
  valgKolonne: selectSokkolonne(state),
  radEnSatt: selectSokRadEn(state),
  valgSokETO: selectSok(state),
  sokETO: selectSokETO(state)
});
const setRadogKolonnedispatch = dispatch => ({
  setRadogKolonneTo: data => {
    dispatch(setRadTo(data));
  },
  setRadogKolonneTre: data => {
    dispatch(setRadTre(data));
  },
  sendSokETO: (sokETO) => {
    dispatch(sendSokETO(sokETO))}
});
export const RaderOgKolonner = connect(
    RaderOGKolonnerState,
    setRadogKolonnedispatch,
)(RaderOgKolonnerKlasse);

const radenDispatch = dispatch => ({
  setRadOgKolonne: data => {
    dispatch(setRadEn(data));
  },
  sendSokETO: (sokETO) => {
    dispatch(sendSokETO(sokETO))}
});
const radenState = state => ({
  navn: RAD_EN,
  valgmuligheter: createSelectKodeverkRad1(state),
  verdi: selectSokRadEn(state),
  erMulti: false,
  startverdi: 0,
  soppelbotte: false,
  sokETO: selectSokETO(state)
});
export const RadEn = connect(
    radenState,
    radenDispatch
)(RaderOgKolonnerNedtrekksliste);

const radtoDispatch = dispatch => ({
  setRadOgKolonne: data => {
    dispatch(setRadTo(data));
  },
  sendSokETO: (sokETO) => {
    dispatch(sendSokETO(sokETO))}
});
const radtoState = state => ({
  navn: RAD_TO,
  valgmuligheter: createSelectKodeverkRad2(state),
  verdi: selectSokRadTo(state),
  erMulti: false,
  startverdi: 0,
  soppelbotte: true,
  sokETO: selectSokETO(state)
});
export const RadTo = connect(
    radtoState,
    radtoDispatch
)(RaderOgKolonnerNedtrekksliste);

const radtreDispatch = dispatch => ({
  setRadOgKolonne: data => {
    dispatch(setRadTre(data));
  },
  sendSokETO: (sokETO) => {
    dispatch(sendSokETO(sokETO))}
});
const radtreState = state => ({
  navn: RAD_TRE,
  valgmuligheter: createSelectKodeverkRad3(state),
  verdi: selectSokRadTre(state),
  erMulti: false,
  startverdi: 0,
  soppelbotte: true,
  sokETO: selectSokETO(state)

});
export const RadTre = connect(
    radtreState,
    radtreDispatch
)(RaderOgKolonnerNedtrekksliste);

const kolonneDispatch = dispatch => ({
  setRadOgKolonne: data => {
    dispatch(setKolonne(data));
  },
  sendSokETO: (sokETO) => {
    dispatch(sendSokETO(sokETO))}
});
const kolonneState = state => ({
  navn: KOLONNE,
  valgmuligheter: createSelectKodeverkKolonne(state),
  verdi: selectSokkolonne(state),
  erMulti: false,
  startverdi: 41,
  soppelbotte: false,
  sokETO: selectSokETO(state)

});
export const Kolonne = connect(
    kolonneState,
    kolonneDispatch
)(RaderOgKolonnerNedtrekksliste);
