import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import monitorReducersEnhancer from './enhancers/monitorReducer';
import loggerMiddleware from './middleware/logger';
import dataService from './middleware/data-service';
import rootReducer from './reducers';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

const persistConfig = {
  key: 'root',
  storage,
  whitelist:['bruker']
};

export default function configureStore(preloadedState) {
    const middlewares = [dataService, loggerMiddleware, thunkMiddleware];
    const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middlewares));

    const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
    const composedEnhancers = compose(...enhancers);

    const persistedReducer = persistReducer(persistConfig, rootReducer,);
    const store = createStore(persistedReducer, preloadedState, composedEnhancers);
    const persistor = persistStore(store);
    return {store, persistor};
}