export const arsrapporter = {
  arsrapport: "Årsrapport",
  arsrapport_riksveg: "Årsrapport riksveg",
  arsrapport_fylkesveg: "Årsrapport fylkesveg",
  arsrapport_kommunal_veg: "Årsrapport kommunal veg",
  nokkeltall: "Nøkkeltall" };

export const kanBestillesArsrapporter= [
  "arsrapport",
  "arsrapport_riksveg",
  "arsrapport_fylkesveg",
  "arsrapport_kommunal_veg",
  "nokkeltall"];

export const enhetsrapporter = {
  personbilulykker: "Personbilulykker",
  tungbilulykker: "Tungbilulykker",
  bussulykker: "Bussulykker",
  sykkelulykker: "Sykkelulykker",
  fotgjengerulykker: "Fotgjengerulykker",
  mc_og_mopedulykker: "MC og mopedulykker" };

export const kanBestillesEnhetsrapporter = ["personbilulykker", "tungbilulykker", "fotgjengerulykker", "mc_og_mopedulykker"];

export const stedsforholdrapporter = {
  tunnelulykker: "Tunnelulykker",
  ulykker_pa_bru: "Ulykker på bru",
  ulykker_pa_motorveg: "Ulykker på motorveg",
  ulykker_pa_tent_t_vegnettet: "Ulykker på TENT-T vegnettet",
  ulykker_og_transportkorridorene: "Ulykker og transportkorridorene" };

export const kanBestillesStedsforholdrapporter = [];

export const ulykkestyperapporter = {
  moteulykker: "Møteulykker",
  utforkjoringsulykker: "Utforkjøringsulykker",
  kryssulykker: "Kryssulykker",
  ulykker_samme_kjoreretning: "Ulykker samme kjøreretning" };

export const kanBestillesUlykkestyperapporter = ["moteulykker", "utforkjoringsulykker"];

export const temarapporter = {
  fartsgrense_og_ulykker: "Fartsgrense og ulykker",
  bilforer_65_pluss_ulykker: "Bilfører 65+, ulykker",
  del_vegen: "Del vegen",
  tiltaksplanens_ulykkesrapport: "Tiltaksplanens ulykkesrapport" };

export const kanBestillesTemarapporter =  ["tiltaksplanens_ulykkesrapport"];
