import React from 'react';
import { Link } from 'react-router-dom';

//Breadcrumb for å navigere seg tilbake til forsiden

const Tilbakeknapp = () => {

    const handterKlikk = () => {
        document.title = "Trine"
    }

    return (
        <nav className="breadcrumbs" style={{marginTop: "2rem"}} aria-label="tilbake til hovedsiden">
            <Link to="/" onClick={handterKlikk}>Forsiden</Link>
        </nav>
    );
};

export default Tilbakeknapp;