export const selectStatusKode = state => {
    let tilbakemelding = null;
    if (state.tilbakemeldingBruker) {
        tilbakemelding = state.tilbakemeldingBruker.tilbakemelding
    }
    return tilbakemelding;
};

export const selectLoading = state => {
    let loading = null;
    if (state.tilbakemeldingBruker && state.tilbakemeldingBruker.loading) {
        loading = state.tilbakemeldingBruker.loading
    }
    return loading;
};

export const selectTakkTilbakemelding = state => {
    let vis = null;
    if (state.tilbakemeldingBruker) {
        vis = state.tilbakemeldingBruker.takkTilbakemelding
    }
    return vis;
};

export const selectVisTilbakemeldingmodal = state => {
    let vis = null;
    if (state.tilbakemeldingBruker) {
        vis = state.tilbakemeldingBruker.visTilbakemeldingmodal
    }
    return vis;
};
