import * as PropTypes from 'prop-types';
import React from "react";
import {connect} from "react-redux";
import {hentTilgjengeligeRapporter, settAr, settFylke, settKommune} from "./redux/actions";
import selectStandardrapporter from "./redux/selectors";
import RapportFilter from './rapporterfilter/RapportFilter';
import Knapp from "../uukomponenter/Knapp";
import "./Rapportsok.css";

class Rapportsok extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {kanSoke: true}
    this.settAr = this.settAr.bind(this);
    this.settFylke = this.settFylke.bind(this);
    this.settKommune = this.settKommune.bind(this);
    this.hentTilgjengeligeRapporter = this.hentTilgjengeligeRapporter.bind(this);
  };

  componentDidUpdate(prevProps) {
    const {standardrapporter, setSokUtfort} = this.props;
    const {ar, fylke, kommune} = standardrapporter;

    if (prevProps.standardrapporter.ar !== ar || prevProps.standardrapporter.fylke !== fylke || prevProps.standardrapporter.kommune !== kommune) setSokUtfort(false);
  }

  settAr(ar) {
    const {settAr} = this.props;
    settAr(ar);
  };

  settFylke(fylke) {
    const {settFylke} = this.props;
    settFylke(fylke);
  };

  settKommune(kommune) {
    const {settKommune} = this.props;
    settKommune(kommune);
  };

  hentTilgjengeligeRapporter(ar, fylke, kommune, sokMulig) {
    if(!sokMulig) {
      this.setState({kanSoke: false})
    }
    else {
      this.setState({kanSoke: true})
      const {hentTilgjengeligeRapporter, setSokUtfort} = this.props;
      hentTilgjengeligeRapporter(ar, fylke, kommune);
      setSokUtfort(true);
    }
  };

  render() {
    const {standardrapporter, setSokUtfort} = this.props;
    const {ar, fylke, kommune} = standardrapporter;

    const sokMulig = ar.toString() !== "null";

    let fylkeBehandlet = "Alle";
    let kommuneBehandlet = "Alle";
    let arBehandlet = null;
    if (fylke && fylke.label) {
      fylkeBehandlet = fylke.label.split("- ")[1];
    }
    if (kommune && kommune.label) {
      kommuneBehandlet = kommune.label;
    }
    if (ar && ar.label) {
      arBehandlet = ar.label;
    }

    return (
      <React.Fragment>
          <fieldset className="mt-3">
            <legend className='velg-ar-og-fylke'>
                <h2 className='heading-decoration'>Velg år og fylke</h2>
            </legend>
            <RapportFilter setSokUtfort={setSokUtfort}/>
            <Knapp
              navn="SØK"
              handterKlikk={() => this.hentTilgjengeligeRapporter(arBehandlet, fylkeBehandlet, kommuneBehandlet, sokMulig)}
              style={{marginTop: 0, paddingRight: "10px", justifyContent: "left"}}/>
              {!this.state.kanSoke && (
                <div className="varselboks block info-message info-message--warning">
                  <p className="advarsel-tekst" role="alert">Du må velge ønsket år for å kunne utføre et søk!</p>
                </div>
                  )}
          </fieldset>
      </React.Fragment>
    );
  }
}

Rapportsok.propTypes = {
  standardrapporter: PropTypes.object.isRequired,
  settAr: PropTypes.func.isRequired,
  settFylke: PropTypes.func.isRequired,
  hentTilgjengeligeRapporter: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  standardrapporter: selectStandardrapporter(state)
});

const mapDispatchToProps = dispatch => ({
  settAr: ar => dispatch(settAr(ar)),
  settFylke: fylke => dispatch(settFylke(fylke)),
  settKommune: kommune => dispatch(settKommune(kommune)),
  hentTilgjengeligeRapporter: (ar, fylke, kommune) =>
    dispatch(hentTilgjengeligeRapporter(ar, fylke, kommune))
});

export default connect(mapStateToProps, mapDispatchToProps)(Rapportsok);
