import React, {useState, useRef, useCallback} from 'react';
import './tilbakemeldingmodal.css';
import Modal from "../uukomponenter/Modal"
import Tekstinput from '../uukomponenter/Tekstinput';
import Knapp from "../uukomponenter/Knapp";
import Checked from "../../ressurser/icons/checked-green.svg";
import Tekstboks from '../uukomponenter/Tekstboks';


/**
 * Modal som består av Container, Modal, Input, og Knapp elementer.
 * Når "Gi oss tilbakemelding" i Fotter bli trykket skal en Modal åpnes.
 * Komponentet består av to modaler hvor den enen tar imor input fra bukeren,
 * og den andren forteller brukeren at tilbakemelding ble sendt inn.
 */
const TilbakemeldingModal = (props) => {

const [epostVerdi, setEpostVerdi] = useState("")
const [meldingVerdi, setMeldingVerdi] = useState("")
const [feilmeldingVerdi, setFeilmeldingVerdi] = useState("")
const [visFeilmelding, setVisFeilmelding] = useState(false)

const epostRef = useRef(null);
const tekstboksRef = useRef(null);

/** Knapp som sender inn tilbakemelding til SVV.
   * Dispatch kall blir utløst som videre blir sender et Fetch kall i data-service.
   * Knapp endrer state for å holde oversikt over hvilket modal som skal vises.
   * @oaram {]
   * @public
   */
const sendTilbakemelding = () => {
    if(epostVerdi.length>0 && !validerEpost()){epostRef.current.focus(); return;}
    if(meldingVerdi.length<1){
        setFeilmeldingVerdi("Det er ikke tillatt å sende en tom tilbakemelding.");
        setVisFeilmelding(true)
        tekstboksRef.current.focus();
        return;
    }
    props.sendTilbakemelding(meldingVerdi, epostVerdi);
    avsluttModal()
}

const validerEpost = () => {
    const pattern = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");
    if(pattern.test(epostVerdi)){
      return true;
    }
    return false;
  }

const validerMelding = (v) => {
    if(!v && meldingVerdi.length<1) return false;
    setVisFeilmelding(false)
    setFeilmeldingVerdi("Det er ikke tillat å bruke spesialtegn.")
    const pattern = new RegExp("^[^/\\()~@#$^&*¤]*$");
    if(pattern.test(v)){
        return true;
    }
    setMeldingVerdi(meldingVerdi.substring(0, meldingVerdi.length))
    return false;
  }

const avsluttModal = () => {
    setEpostVerdi("");
    setMeldingVerdi("");
    setFeilmeldingVerdi("");
    setVisFeilmelding(false);
    props.setVisTilbakemeldingmodal(false)
}

const innhold = (
    <div className='innholdKonteiner'>
        <p>Vi ønsker å vite hva du synes om å søke i Trafikkulykkeregisteret. Tilbakemeldingene dine er verdifulle når vi skal forbedre tjenesten vår.</p>
        <Tekstinput 
            navn='Din e-post (valgfritt). Eksempel: dinepost@email.no'
            verdi = {epostVerdi}
            oppdaterFelt={setEpostVerdi}
            logiskSjekk={validerEpost}
            maxLength={70}
            logiskSjekkOnBlur
            feilmelding="Ugyldig e-postadresse. La feltet stå tomt, eller fyll inn en gyldig e-postadresse."
            inputRef={epostRef}
            type="email"
            />
        <Tekstboks 
            navn='Din tilbakemelding'
            verdi = {meldingVerdi}
            inputId="dinTilbakemelding"
            oppdaterFelt={setMeldingVerdi}
            maxLength={1000}
            logiskSjekk={validerMelding}
            feilmelding={feilmeldingVerdi}
            inputRef={tekstboksRef}
            visFeilmelding={visFeilmelding}
            />
    </div>
);

const fokuserOverskrift = useCallback((overskriftElement) => {
    if (overskriftElement) {
      overskriftElement.focus();
    }
  }, []);

const innholdTakk = (
    <div className="innholdTakkKonteiner">
        <h1 className="overskriftTakk" tabIndex="-1" ref={fokuserOverskrift}>Tusen takk!</h1>
        <p>Vi har fått tilbakemeldingen din og ser frem til å lese den!</p>
    </div>
)


return(
<>
    <Modal 
        innholdTop={<h1 className="overskriftInnholdTop">Gi oss din tilbakemelding!</h1>}
        innhold={innhold} 
        innholdBunn={<Knapp handterKlikk={() => sendTilbakemelding()} navn="Send tilbakemelding"/>} 
        open={props.visTilbakemeldingmodal ? props.visTilbakemeldingmodal : false} 
        setOpen={(e) => {e==false ? avsluttModal() : props.setVisTilbakemeldingmodal(e)}}
        brukRef={true}
        />
    <Modal 
        innholdTop={<img aria-hidden className='img-Checked-Green' alt=" " src={Checked}/>} 
        innhold={innholdTakk} 
        innholdBunn={<></>} 
        open={props.takkTilbakemelding ? props.takkTilbakemelding : false} 
        setOpen={(e) => props.setTakkTilbakemelding(e)}/>
</>
);
}

export default TilbakemeldingModal;