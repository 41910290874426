import {SET_VALGT_SIDE} from "../actions/actionTypes";

const valgtSide = (state = [], action) => {
    switch (action.type){
        case SET_VALGT_SIDE:
            return {
                ...state,
                valgtSide: action.side
            };
        default:
            return state;
    }
}

export default valgtSide;