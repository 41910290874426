import {SET_OM_STATISTIKKEN_ETO, SET_DEFINISJONER_ETO, SET_OM_STATISTIKKEN_VALGT} from "../actions/actionTypes";

const omStatistikkenKodeverk = (state = {}, action) =>{
  switch(action.type){
    case SET_OM_STATISTIKKEN_ETO:
      return {
        ...state,
        omStatistikken: action.data
      };
    case SET_DEFINISJONER_ETO:
      return {
          ...state,
          definisjoner: action.data
      };
    case SET_OM_STATISTIKKEN_VALGT:
      return{
        ...state,
        omStatistikkenValgt: action.data
      };

    default:
      return state;
  }
};

export default omStatistikkenKodeverk;