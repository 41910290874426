import { connect } from 'react-redux';
import Navigasjon from './Navigasjon';

import {
  hentDefinisjonerETO,
  hentOmStatistikkenETO,
  setOmStatistikkenValgt
} from "../../actions";
import {
  selectOmStatistikkenValgt,
} from "./navigasjonSelector";

/** RaderOgKolonnerContainer knytter RaderOgKolonnerNedtreksliste sammen med
 * redux store. For hver nedtrekksliste inneholder filen en funkjson for dispatch til redux store og en fil
 * funksjon for å hente ut staten valgt i redux store.
 * */

const NavigasjonState = state => ({
  omStatistikkenValgt: selectOmStatistikkenValgt(state),
});
const Navigasjondispatch = dispatch => ({
  setOmStatistikkenValgt: data => {
    dispatch(setOmStatistikkenValgt(data));
  },
  getOmStatistikken: () => {
    dispatch(hentOmStatistikkenETO());
  },
  getDefinisjoner: () => {
    dispatch(hentDefinisjonerETO());
  }
});
export const NavigasjonContainer = connect(
    NavigasjonState,
    Navigasjondispatch,
)(Navigasjon);
