import React from 'react';
import './slettKnapp.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons/index";

/**
 * En standard knappkomponent
 * @param knappStorrelse
 * @param farge
 * @param tittel
 * @param hoverTekst
 * @param handterKlikk
 * @returns {*}
 * @constructor
 */
export default function SlettKnapp({ hoverTekst, handterKlikk }) {
  return (
      <button className="slettknapp" aria-label={hoverTekst} type="button" onClick={handterKlikk}>
        <FontAwesomeIcon className="slettKnapp-ikon" icon={faTrashAlt}/>
        <p className='slettknapptekst'>Fjern</p>
      </button>
  );
}

SlettKnapp.propTypes = {
  hoverTekst: PropTypes.string,
  handterKlikk: PropTypes.func.isRequired
};

SlettKnapp.defaultProps = {
  hoverTekst: 'Knapp'
};
