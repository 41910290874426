import {SET_INFOTEKST} from "../actions/actionTypes";

const informasjonstekst = (state = {}, action) => {
    if (action.type === SET_INFOTEKST) {
        return action.data
    } else {
        return state;
    }
};

export default informasjonstekst;