import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {genererFeilmeldingFilter, logiskKontrollFilter} from './LogiskKontroll'
import {NYE_ENHETSTYPER, ENDREDE_ENHETSTYPER} from "../../ressurser/EndredeKodeverk";
import {ENHETSTYPE_ETO_NAVN} from "../../ressurser/Konstanter";
import {selectFilterNullstilt} from "../datafiltrering/datafiltreringSelector";
import {resetFilterNullstilt} from "../../actions/index";
import NedtrekksListe from "../uukomponenter/NedtrekksListe";
import {sendSokETO, setFilterVisesITabell} from "../../actions";
import {selectSokETO, selectValgtFilter, selectFilterETO} from "../datafiltrering/datafiltreringSelector";

const allOption = [{
  label:null,
  value:"*"
}];

/** FilterNedtrekksliste er en wrapper rundt Nedtrekksliste og sender og mottar oppdateringer til og fra Redux store*/
class FilterNedtrekksliste extends React.Component {
  constructor(props) {
    super(props);
    this.state = ({
      verdi: this.props.erMulti ? allOption : "null",
      erDeaktivert: false,
      feilmelding: ""
    });
    this.onChange = this.onChange.bind(this);
    this.options = this.options.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { nullstillFilter, erMulti } = this.props;
    const { verdi } = prevState;
    if(nullstillFilter && nullstillFilter !== prevProps.nullstillFilter && verdi) {
      this.setState({
        verdi: erMulti ? allOption : "null"
      });
    }
  }

  /** onChange tar seg av det som skjer i det bruker gjør endringer i Nedtrekksliste
   * Dersom bruker velger ingen filter, eller alle filter skal filter være alle. Det gir ikke mening å ikke ha filter.
   * Hva slags resultater har vi dersom bruker velger ingen veityper for eksempel? Minst en må være valgt
   * I else blokka filteres "Alle" muligheten bort, slik at bare de aktive filtrene vises.
   * @oaram {]
   * @public
   */
  onChange(data) {
    const {setFilter, valgmuligheter, etoNavn, etoGeografisk, kommunerSokETO, filterIkkeNullstilt} = this.props;
    filterIkkeNullstilt();
    let transformertData = [];

    if (!this.props.erMulti) {
      setFilter(etoNavn, data);
      this.setState({
        verdi: data
      });
    } else {
      if (data === null || data.length < 1 || data.value === "*") {
        setFilter(etoNavn, undefined, etoGeografisk, kommunerSokETO);
        this.setState({
          verdi: allOption
        });
      } else {
        const filtrertAlle = data.filter(d => d.value !== "*");
        filtrertAlle.forEach(verdi => {
          valgmuligheter.forEach(kodeverk => {
            if (verdi.value === kodeverk.id) {
              transformertData.push(kodeverk);
            }
          })
        });
        setFilter(etoNavn, transformertData);
        this.setState({
          verdi: filtrertAlle
        });
      }
    }
  }


  /**
   * Denne funksjonen gjør om kodeverket til formatet det skal vises i nedtrekkslisten
   * @public
   */
  options() {
    const {visUtenKode, valgmuligheter, etoNavn} = this.props;
    if (visUtenKode) {
      return valgmuligheter.map(a => ({
        value: a.id,
        label: a.navn
      }));
    } else if(etoNavn === ENHETSTYPE_ETO_NAVN) {
      return valgmuligheter.map(a => {
        return {
          value: a.id,
          label: a.kode + " - " + a.navn,
          endretKodeverk: NYE_ENHETSTYPER.includes(a.kode) || ENDREDE_ENHETSTYPER.includes(a.kode)
        }
      });
    }
    return valgmuligheter.map(a => ({
      value: a.id,
      label: a.kode + " - " + a.navn
    }));
  }

  /**
   * Funksjon som bruker prop'ene i logisk kontroll og endere state dersom logisk kontroll feiler
   * @public
   */
  sjekkLogiskKontroll() {
    const {navn, filter, kodeverkAlle} = this.props;
    const {erDeaktivert} = this.state;
    if (this.props.kodeverk === null) {
      this.setState({
        erDeaktivert: true,
      });
    } else if (filter && kodeverkAlle && logiskKontrollFilter(filter, navn, kodeverkAlle) && !erDeaktivert) {
      this.setState({
        erDeaktivert: true,
        feilmelding: genererFeilmeldingFilter(navn)
      });
    } else if (filter && kodeverkAlle && !logiskKontrollFilter(filter, navn, kodeverkAlle) && erDeaktivert) {
      this.setState({
        erDeaktivert: false
      })
    }
  }

  inneholderEndredeEllerNyeKodeverk(valg) {
    if(Array.isArray(valg)) {
      const endret = valg.filter(v => v.endretKodeverk);
      return endret && endret.length > 0;
    }
    return false;
  }

  /**
   *
   * @returns {<Nedtrekksliste/> med gitt props}
   */
  render() {
    const {navn, valgmuligheter, erMulti} = this.props;
    const {verdi, erDeaktivert, feilmelding} = this.state;
    const kodeverk = valgmuligheter ? this.options() : "null";

    this.sjekkLogiskKontroll();
    return (
      <div className={'nedtrekk-container'}>
        <NedtrekksListe
          navn={navn}
          verdi={verdi}
          valgmuligheter={kodeverk}
          ingenKodeverk={kodeverk === "null"}
          onChange={this.onChange}
          erDeaktivert={erDeaktivert}
          feilmelding={feilmelding}
          erMulti={erMulti}
          />
          {this.inneholderEndredeEllerNyeKodeverk(verdi) &&
          <small className={'nedtrekksliste-ulovligfilter-tekst'}>En eller flere av de valgte verdiene er nye eller endret i 2020</small>}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  filterIkkeNullstilt: () => {
    dispatch(resetFilterNullstilt())
  },
    sendSokETO: (sokETO) => {
      dispatch(sendSokETO(sokETO))
    },
    setFilterVisesITabell: (data) => {
      dispatch(setFilterVisesITabell(data))
    }
});

const mapStateToProps = state => ({
  nullstillFilter: selectFilterNullstilt(state),
  sokETO: selectSokETO(state),
  valgtFilter: selectValgtFilter(state),
  filterETO: selectFilterETO(state),
});


export default connect(mapStateToProps, mapDispatchToProps)(FilterNedtrekksliste);

FilterNedtrekksliste.propTypes = {
  /** Teksten over nedtrekkslisten som beskriver hva nedtrekkslisten filtrerer på*/
  navn: PropTypes.string.isRequired,
  /** Boolsk verdi som gir mulighet til å velge om nedtrekkslisten kun skal kunne velge en verdi, eller om den skal kunne velge flere*/
  erMulti: PropTypes.bool.isRequired,
  kodeverkAlle: PropTypes.object
};