import React from 'react';
import { Row, Spinner, Col} from 'reactstrap';
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './krysstabell.css';
import * as d3 from "d3";
import {FYLKE, KOMMUNE} from "../raderogkolonner/RaderOgKolonnerKonstanter";
import Tabell from '../uukomponenter/Tabell';
import {Nedlastning} from "../midtdel/LastNedTabellContainer";
import warning from "../../ressurser/icons/warning.svg";

/**
 * Komponent som rendrer selve krysstabellen. Komponenten henter data fra Redux og konverterer dataen
 * til ett format som ReactTable kan tolke, og sender dataen inn til ReactTable
 */
export default class Krysstabell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = ({
      loading: false.valueOf,
      data: []
    });
  }

  /**
   * Denne metoden restrukturerer dataen fra redux til ett format ReactTable kan bruke
   * @returns {Array}
   * @public
   */
  restrukturerHeader() {
    let headerData = [];
    let headerLengde = this.props.dataSett.header.length;
    /*Iterer over de valgte radene og legg til i arrayet headerData */
    for (let i = 0; i < headerLengde - 1; i++) {
      headerData.push({
        id: this.props.dataSett.header[i].id.toString(),
        navn: this.props.dataSett.header[i].navn,
        type: this.props.dataSett.header[i].type
      });
    }
    /*Iterer over de mulige verdiene for valgt kolonne og legg til i arrayet headerData*/
    this.props.dataSett.tabellverdier.kolonne1.forEach((kategori) => {
      headerData.push({
        id: this.props.dataSett.header[headerLengde - 1].id.toString(),
        navn: kategori,
        type: "kolonne"
      })
    });
    /*Legg til en kolonne på slutten av arrayet som holder summen til de ulike radene*/
    headerData.push({
      id: "sum",
      navn: "Sum",
      type: "kolonne"
    });
    return headerData;
  }

  /**
   * Sjekker om rader inneholder både fylke og kommune samtidig, returnerer da true
   * @returns {boolean}
   */
  sjekkKommuneOgFylke() {
    let kommuneOgFylke = false;
    let antallTilfeller = 0;
    Object.keys(this.props.dataSett.data["0"]).forEach(o => {
      if (o.toString() === "5" || o.toString() === "4") {
        antallTilfeller++;
      }
    });
    if (antallTilfeller >= 2) kommuneOgFylke = true;
    return kommuneOgFylke;
  }

  leggTilAlleRader(headerData, antallRader) {
    let aggregert2 = [];
    if (this.sjekkKommuneOgFylke()) {
      aggregert2 = this.leggTilAlleRaderKommuneFylkeSpesial(headerData, antallRader);
    } else {
      aggregert2 = this.leggTilAlleRaderNormal(headerData, antallRader);
    }
    return aggregert2;
  }

  /**
   * Denne metoden gjør det somme som "leggTilAlleRaderNormal", men med en sjekk for hvor kommune og fylke ligger i radene
   * Dette trigger deretter en særegen loop tilpasset de ulike kombinasjonene av fylke og kommune
   * @param headerData
   * @param antallRader
   * @returns {Array}
   */
  leggTilAlleRaderKommuneFylkeSpesial(headerData, antallRader) {
    const {sokRad1, sokRad2} = this.props;
    let fylkeForsteRad = false;
    let kommuneAndreRad = false;
    let fylkeAndreRad = false;
    if (sokRad1 && sokRad1.id === 4) fylkeForsteRad = true;
    if (sokRad2 && sokRad2.id === 5) kommuneAndreRad = true;
    if (sokRad2 && sokRad2.id === 4) fylkeAndreRad = true;

    let aggregert2 = [];
    if (antallRader === 2) {
      for (let index2 in this.props.dataSett.tabellverdier.rad2) {
        aggregert2.push({
          [headerData[0].id]: this.props.dataSett.tabellverdier.rad1[index2],
          [headerData[1].id]: this.props.dataSett.tabellverdier.rad2[index2]
        });
      }
    }

    if (antallRader === 3) {
      if (fylkeForsteRad && kommuneAndreRad) { // Trigger på FYLKE - KOMMUNE - ANNET
        for (let index2 in this.props.dataSett.tabellverdier.rad2) { // Kommune
          for (let index3 in this.props.dataSett.tabellverdier.rad3) { // Annet
            aggregert2.push({
              [headerData[0].id]: this.props.dataSett.tabellverdier.rad1[index2], // Fylke
              [headerData[1].id]: this.props.dataSett.tabellverdier.rad2[index2], // Kommune
              [headerData[2].id]: this.props.dataSett.tabellverdier.rad3[index3] // Annet
            })
          }
        }
      } else if (fylkeForsteRad) { // Trigger på FYLKE - ANNET - KOMMUNE
        for (let index2 in this.props.dataSett.tabellverdier.rad2) {
          for (let index3 in this.props.dataSett.tabellverdier.rad3) {
            aggregert2.push({
              [headerData[0].id]: this.props.dataSett.tabellverdier.rad1[index3], // Fylke
              [headerData[1].id]: this.props.dataSett.tabellverdier.rad2[index2], // Annet
              [headerData[2].id]: this.props.dataSett.tabellverdier.rad3[index3] // Kommune
            })
          }
        }
      } else if (fylkeAndreRad) { // Trigger på ANNET - FYLKE - KOMMUNE
        for (let index1 in this.props.dataSett.tabellverdier.rad1) {
          for (let index3 in this.props.dataSett.tabellverdier.rad3) {
            aggregert2.push({
              [headerData[0].id]: this.props.dataSett.tabellverdier.rad1[index1], // Annet
              [headerData[1].id]: this.props.dataSett.tabellverdier.rad2[index3], // Fylke
              [headerData[2].id]: this.props.dataSett.tabellverdier.rad3[index3] // Kommune
            })
          }
        }
      }
    }

    return aggregert2;
  }

  /**
   * Denne metoden legger til alle radene som finns i de valgte radene.
   * Dette gjøres ved å iterere over de valgte radene og legge til alle elementer som finns der
   * Dersom det er to rader skal alle verdiene i rad 2 legges til under det første elementet i rad 1
   * @param {Array} headerData den restrukturerte headerDataen fra restrukturereHeader()<br/>
   * @param {number} antallRader
   * @returns {Array}
   * @public
   */
  leggTilAlleRaderNormal(headerData, antallRader) {
    let aggregert2 = [];
    for (let index1 in this.props.dataSett.tabellverdier.rad1) {
      if (antallRader >= 2) {
        for (let index2 in this.props.dataSett.tabellverdier.rad2) {
          if (antallRader === 3) {
            for (let index3 in this.props.dataSett.tabellverdier.rad3) {
              aggregert2.push({
                [headerData[0].id]: this.props.dataSett.tabellverdier.rad1[index1],
                [headerData[1].id]: this.props.dataSett.tabellverdier.rad2[index2],
                [headerData[2].id]: this.props.dataSett.tabellverdier.rad3[index3]
              })
            }
          } // 3 rader slutt
          else {
            aggregert2.push({
              [headerData[0].id]: this.props.dataSett.tabellverdier.rad1[index1],
              [headerData[1].id]: this.props.dataSett.tabellverdier.rad2[index2]
            })
          }
        } // 2 rader slutt
      } else {
        aggregert2.push({
          [headerData[0].id]: this.props.dataSett.tabellverdier.rad1[index1]
        })
      }
    } // 1 Rad slutt
    return aggregert2;
  }

  /**
   * Denne metoden restruturerer dataen fra backend som representerer treffene vi har fått på søket.
   * Dataen fra backend gir en liste med en og en celleverdi til tabellen. Denne funksjonen aggregerer
   * dataen slik at vi får en liste hvor ett element er en hel rad i tabellen. Dette gjøres ved hjelp av rammeverket d3.
   * @param {Array} headerData den restrukturerte headerDataen fra restrukturereHeader()
   * @returns {Array}
   * @public
   */
  restrukturerData(headerData) {
    let raData = JSON.parse(JSON.stringify(this.props.dataSett.data)); //gjør at accessor kan være en streng av tall

    // Looper gjennom raData for å sette verdier til ints:
    if (raData) for (let i = 0; i < raData.length; i++) {
      raData[i].verdi = parseInt(raData[i].verdi, 10);
    }

    let headerLengde = this.props.dataSett.header.length;
    let aggregert = this.leggTilAlleRader(headerData, headerLengde - 1);

    if (headerLengde === 4) { //3 rader
      d3.nest()
        .key(d => {
          return d[headerData[0].id]
        }) //Aggregerer alle tabellverdier med samme verdi for rad1
        .key(d => {
          return d[headerData[1].id]
        }) //Aggregerer alle tabellverdier med samme verdi for rad2
        .key(d => {
          return d[headerData[2].id]
        }) //Aggregerer alle tabellverdier med samme verdi for rad3
        .rollup(function (d) {
          let verdier = {};
          for (let i = 0; i < d.length; i++) { // samler alle verdiene for hver rad i ett objekt
            verdier[d[i][headerData[headerLengde - 1].id]] = d[i].verdi;
          }
          let verdierArray = Object.entries(verdier);
          for (let i = 0; i < d.length; i++) { //finne rett plassering i aggregert for å plassere datapunktet fra backend
            let index = aggregert.findIndex(function (element) {
              if (element[headerData[0].id] === d[i][headerData[0].id] && element[headerData[1].id] === d[i][headerData[1].id] && element[headerData[2].id] === d[i][headerData[2].id]) {
                return true
              } else return false
            });
            aggregert[index] = {
              [headerData[0].id]: d[i][headerData[0].id],
              [headerData[1].id]: d[i][headerData[1].id],
              [headerData[2].id]: d[i][headerData[2].id]
            };
            aggregert[index]["Sum"] = 0;
            for (let n = 0; n < verdierArray.length; n++) { //kalkuler summen for hver rad
              aggregert[index][verdierArray[n][0]] = verdierArray[n][1];
              aggregert[index]["Sum"] += parseInt(verdierArray[n][1]);
            }
          }
          return {verdier}
        })
        .object(raData);

    } else if (headerLengde === 3) {
      d3.nest()
        .key(d => {
          return d[headerData[0].id]
        }) //Aggregerer alle tabellverdier med samme verdi for rad1
        .key(d => {
          return d[headerData[1].id]
        }) //Aggregerer alle tabellverdier med samme verdi for rad2
        .rollup(function (d) {
          let verdier = {};
          for (let i = 0; i < d.length; i++) { // samler alle verdiene for hver rad i ett objekt
            verdier[d[i][headerData[headerLengde - 1].id]] = d[i].verdi;
          }
          let verdierArray = Object.entries(verdier);

          for (let i = 0; i < d.length; i++) { //finne rett plassering i aggregert for å plassere datapunktet fra backend
            let index = aggregert.findIndex(function (element) {
              if (element[headerData[0].id] === d[i][headerData[0].id] && element[headerData[1].id] === d[i][headerData[1].id]) {
                return true
              } else return false
            });
            aggregert[index] = {
              [headerData[0].id]: d[i][headerData[0].id],
              [headerData[1].id]: d[i][headerData[1].id]
            };
            aggregert[index]["Sum"] = 0;
            for (let n = 0; n < verdierArray.length; n++) { //kalkuler summen for hver rad
              aggregert[index][verdierArray[n][0]] = verdierArray[n][1];
              aggregert[index]["Sum"] += parseInt(verdierArray[n][1]);
            }
          }
          return {verdier}
        })
        .object(raData);

    } else if (headerLengde === 2) {
      d3.nest()
        .key(d => {
          return d[headerData[0].id]
        }) //Aggregerer alle tabellverdier med samme verdi for rad1
        .rollup(function (d) {
          let verdier = {};
          for (let i = 0; i < d.length; i++) { //finne rett plassering i aggregert for å plassere datapunktet fra backend
            verdier[d[i][headerData[headerLengde - 1].id]] = d[i].verdi;
          }
          let verdierArray = Object.entries(verdier);

          for (let i = 0; i < d.length; i++) {
            let index = aggregert.findIndex(function (element) {
              if (element[headerData[0].id] === d[i][headerData[0].id]) {//finn index til objektet med verdien vi ønsker å sette inn
                return true
              } else {
                return false
              }
            });
            aggregert[index] = {
              [headerData[0].id]: d[i][headerData[0].id]
            };
            aggregert[index]["Sum"] = 0;
            for (let n = 0; n < verdierArray.length; n++) { //kalkuler summen for hver rad
              aggregert[index][verdierArray[n][0]] = verdierArray[n][1];
              aggregert[index]["Sum"] += parseInt(verdierArray[n][1]);
            }
          }
          return {verdier}
        })
        .object(raData);
    }
    return aggregert
  }

  /**
   * Denne metoden kalkulerer summen for hver kolonne som i setKolonner blir plassert i footeren til tabellen
   * @param {Array} data den aggregerte dataen <br/>
   * @param {String} id id'en til kolonnen vi summerer over <br/>
   * @param {String} navn navnet til kolonnen vi summerer over
   * @returns {number}
   * @public
   */
  kalkulerSum(data, id, navn) {
    let sum = 0;
    if (navn !== "Sum") {
      for (let i = 0; i < this.props.dataSett.data.length; i++) {
        if (navn === this.props.dataSett.data[i][id]) {
          sum += parseInt(this.props.dataSett.data[i].verdi);
        }
      }
    } else { // Sum kolonnen ligger kun i dataen vi har lagd, itererer derfor over den.
      for (let i = 0; i < data.length; i++) {
        if (data[i]["Sum"]) {
          sum += data[i]["Sum"]
        }
      }
    }
    return sum;
  }

  /**
   * Tar inn rad-/kolonnetittel og stripper den for kodeverkskode
   * @param tittel på formen <kode> - <navn>, eks. "1818 - Herøy"
   * @returns {String} Tittel på formen <navn>
   */
  static strippKodeFraTittel(tittel) {
      return tittel.replace(/^\d*\s-\s/, "");
  }

  /**
   * Denne metoden setter inn header-kolonnene
   * @param headerData
   * @param data
   * @returns {Array}
   */
  setKolonner(headerData, data) {
    let kolonner = [];
    headerData.map((kol, index) => {
      (
        kolonner.push({
          Header: (parseInt(kol.id) === FYLKE || parseInt(kol.id) === KOMMUNE) ? kol.navn : Krysstabell.strippKodeFraTittel(kol.navn),
          style: kol.type === "rad" ?
            {
              background: '#45515A',
              color: 'white'
            } :
            {},
          sticky: kol.type === "rad" ? "left" : "",
          Footer: (props) => (kol.type === "rad" && index === 0) ? "Sum" : (kol.type === "kolonne" ? this.kalkulerSum(data, kol.id, kol.navn) : ' '),
          accessor: kol.type === "rad" ? kol.id : kol.navn,
          Cell: props => {
            let celleverdi = props.row.values[kol.id] || props.row.values[kol.navn] || 0; // for å få 0 i alle ruter som ikke allerede har en verdi
            if (kol.type === "rad" && parseInt(kol.id) !== FYLKE && parseInt(kol.id) !== KOMMUNE) {
              celleverdi = Krysstabell.strippKodeFraTittel(celleverdi); /// Rad-tittel skal strippes for kode, med unntak av kommuner og fylker, der nummeret er relevant
            }
            return celleverdi;
          }
        })
      );
    });
    return (kolonner)
  }


  render() {
    const headerData = this.props.dataSett && this.restrukturerHeader();
    const data = this.props.dataSett && this.restrukturerData(headerData);
    const dataLengde = this.props.dataSett && data.length;

    return (
      <>
        {this.props.dataSett && !this.props.loading ? (
            <>
              {dataLengde > 100 && (
                <Row>
                  <Col>
                    <p>
                      <img src={warning} alt=""/>
                      <i aria-live="polite" role="alert" style={{marginLeft: "0.5rem"}}>Obs: Du har nå valgt en
                      kombinasjon av rader og kolonner som gir en veldig stor tabell!</i>
                    </p>
                  </Col>
                </Row>)}
              <Tabell unMemoData={data} unMemoColumns={this.setKolonner(headerData, data)}/> 
              <Row>
                <div className="tips-og-last-ned">
                  <p className="krysstabell-tips"><i>Tips: Hold inne <kbd>Shift</kbd> ved sortering av kolonner for å velge flere kolonner.</i></p>
                  <Nedlastning/>
                </div>
              </Row>
            </>)
          : (
            <Row className="krysstabell-rad justify-content-center align-content-center">
              <Spinner/>
            </Row>
          )}
      </>
    );
  }
}

