  import {
    RESET_SOKETO,
  SET_DATASETT,
  SET_FILTER_VERDI,
  SET_KOLONNE,
  SET_RAD_EN,
  SET_RAD_TO,
  SET_RAD_TRE,
} from "../actions/actionTypes";

const initialState = {
  filterETO: {
    person: {},
    enhet: {},
    ulykke: {}
  },
  raderOgKolonnerValgETO: {
    rad1: {
      id: 0,
      engelskNavn: null,
      navn: "År"
    },
    kolonne: {
      id: 41,
      engelskNavn: null,
      navn: "Skadegrad"
    }
  }
};


/**
 *
 * Reducer som oppdaterer filter
 */
const sokETO = (state = {
  ...initialState
  }, action) => {
  switch (action.type) {
    case SET_FILTER_VERDI:
      return {
        ...state,
        filterETO: {
          ...state.filterETO,
          [action.kategori]: {
            ...state.filterETO[action.kategori],
            [action.etoNavn]: action.data
          }
        }
      };

      case SET_RAD_EN:
        return {
          ...state,
          raderOgKolonnerValgETO: {
            ...state.raderOgKolonnerValgETO,
              rad1: action.data
            }};
      case SET_RAD_TO:
        return {
          ...state,
          raderOgKolonnerValgETO: {
            ...state.raderOgKolonnerValgETO,
            rad2: action.data
          }};
      case SET_RAD_TRE:
        return {
          ...state,
          raderOgKolonnerValgETO: {
            ...state.raderOgKolonnerValgETO,
            rad3: action.data
          }};
      case SET_KOLONNE:
        return {
          ...state,
          raderOgKolonnerValgETO: {
            ...state.raderOgKolonnerValgETO,
            kolonne: action.data
          }};
    case SET_DATASETT:
      return {
        ...state,
        datasett: action.data
      };
    case RESET_SOKETO:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};


export default sokETO;
