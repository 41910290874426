import React from 'react';
import './datafiltrering.css';
import Accordion from "../uukomponenter/Accordion";
import {Fylke, Kommune, Politidistrikt, Tettsted} from "./StedsInnhold";
import {Ar, Maned, Timetype, Ukedagstype} from "./TidInnhold";
import {Alvorlighetsgrad, Ulykkeskoder, Ulykkestyper} from "./UlykkestyperAlvorlighetsgradInnhold";
import {Foreforhold, Lysforhold, Temperatur, Verforhold} from "./KlimaInnhold";
import {
    Fartsgrense,
    FysiskMidtdeler,
    Kjorefeltstype, ReguleringIGangfelt,
    ReguleringIVegkryss,
    Stedsforhold,
    Vegdekke,
    Vegkategori,
    Vegtyper
} from "./VegInnhold";
import {
    Arsmodell,
    Drivstofftype,
    EnhetHovedkategori,
    Enhetstype,
    EnhetUnderkategori, PakjortTypeHinder,
    Registreringsland, Tilhenger, Tungtransport, Vogntog
} from "./EnhetInnhold";
import {Aldersgruppe, Aldersgruppe5arig, Kjonn, Plassering, Skadegrad, Trafikanttyper} from "./PersonInnhold";
import { connect } from 'react-redux';
import {selectFilterNullstilt} from "./datafiltreringSelector";
import {resetFilterNullstilt} from "../../actions/index";
import {sendSokETO, setFilterVisesITabell} from "../../actions";
import {selectSokETO, selectValgtFilter, selectFilterETO} from "./datafiltreringSelector";
import kalender from "../../ressurser/icons/calendar.svg";


/**
 * Placeholder for alle trekkspillere i filtreringsdelen.
 * Brukeren skal kunne velge hva slags data som skal filtreres bort.
 */


class DataFiltrering extends React.Component {

  componentDidUpdate(prevProps) {
    const {sokETO, sendSokETO, setFilterVisesITabell, valgtFilter} = this.props;
    if(prevProps.filterETO !== null && this.props.filterETO !== prevProps.filterETO){
      sendSokETO(sokETO);
      setFilterVisesITabell(valgtFilter);
    }
  }
  render(){
  return (
    <>
        <Accordion tittel="Sted" headerNiva="4" ikon="accordion__heading--marker" innhold={() => (
        <div className='kombinasjonsboks'>
          <Fylke/>
          <Kommune/>
          <Tettsted/>
          <Politidistrikt/>
          </div>
          )}/>

        <Accordion tittel="Tid" headerNiva="4" ikon="accordion__heading--calendar" innhold={() => (
        <div className='kombinasjonsboks'>
          <Ar/>
          <Maned/>
          <Ukedagstype/>
          <Timetype/>
          </div>
          )}/>
        <Accordion tittel="Ulykkestyper og alvorlighetsgrad" headerNiva="4" ikon="accordion__heading--accident" innhold={() => (
          <div className='kombinasjonsboks'>
            <Alvorlighetsgrad/>
            <Ulykkestyper/>
            <Ulykkeskoder/>
          </div>
          )}/>
        <Accordion tittel="Vær og føre" headerNiva="4" ikon="accordion__heading--weather" innhold={() => (
        <div className='kombinasjonsboks'>
          <Temperatur/>
          <Lysforhold/>
          <Foreforhold/>
          <Verforhold/>
          </div>
          )}/>
        <Accordion tittel="Veg" headerNiva="4" ikon="accordion__heading--veg" innhold={() => (
        <div className='kombinasjonsboks'>
          <Vegkategori/>
          <Vegtyper/>
          <Stedsforhold/>
          <Vegdekke/>
          <Kjorefeltstype/>
          <FysiskMidtdeler/>
          <ReguleringIVegkryss/>
          <ReguleringIGangfelt/>
          <Fartsgrense/>
          </div>
          )}/>
        <Accordion tittel="Enhet" headerNiva="4" ikon="accordion__heading--enheter" innhold={() => (
        <div className='kombinasjonsboks'>
          <EnhetHovedkategori/>
          <EnhetUnderkategori/>
          <Enhetstype/>
          <Registreringsland/>
          <Arsmodell/>
          <Drivstofftype/>
          <PakjortTypeHinder/>
          <Tilhenger/>
          <Tungtransport/>
          <Vogntog/>
          </div>
          )}/>
        <Accordion tittel="Person" headerNiva="4" ikon="accordion__heading--person" innhold={() => (
        <div className='kombinasjonsboks'>
          <Kjonn/>
          <Aldersgruppe/>
          <Aldersgruppe5arig/>
          <Trafikanttyper/>
          <Plassering/>
          <Skadegrad/>
          </div>
          )}/>
    </>
  );
}}

const mapDispatchToProps = dispatch => ({
  filterIkkeNullstilt: () => {
    dispatch(resetFilterNullstilt())
  },
    sendSokETO: (sokETO) => {
      dispatch(sendSokETO(sokETO))
    },
    setFilterVisesITabell: (data) => {
      dispatch(setFilterVisesITabell(data))
    }
});

const mapStateToProps = state => ({
  nullstillFilter: selectFilterNullstilt(state),
  sokETO: selectSokETO(state),
  valgtFilter: selectValgtFilter(state),
  filterETO: selectFilterETO(state),
});


export default connect(mapStateToProps, mapDispatchToProps)(DataFiltrering);
