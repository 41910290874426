import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import {faCarCrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './ingenTreff.css'

/**
 * Denne funksjonen rendrer en komponent som kan vises i stedet for krysstabell dersom det ikke er noe data i vise i tabellen
 * @param tekst teksten som skal vises under ikonet
 * @returns {*}
 */
export default function IngenTreff({tekst}){
    return(
        <Container fluid className="ingen-treff-container">
            <Row className=" justify-content-center align-content-center">
            <FontAwesomeIcon className="ingen-treff-ikon" icon={faCarCrash} color="charcoal" />
            </Row>
            <Row className="ingen-treff-tekst">
                <Col xs={8}>
                {tekst}
                </Col>
            </Row>
        </Container>
    )

}

