export const PERSONVERN = "Hovedformålet med behandling av informasjon om ulykkesinvolverte personer i ulykkesstatistikken, er å kunne arbeide på en vitenskapelig måte med å redusere antallet drepte og hardt skadde i vegtrafikken.\n" +
" Innsamlingen av data danner grunnlag for å utarbeide ulykkesstatistikk, og statistikken gir grunnlag for å anbefale trafikksikkerhetstiltak.\n" +
" Behandlingsgrunnlaget er GDPR artikkel 6 nr. 1 bokstav 'e' med vegtrafikkloven § 43b som supplerende rettsgrunnlag. For særlige kategorier personopplysninger er behandlingsgrunnlaget GDPR artikkel 6 nr. 1\n" +
" bokstav 'e' og GDPR artikkel 9 nr. 2 bokstav 'g' med vegtrafikkloven § 43b som supplerende rettsgrunnlag.\n" +
" Statens vegvesen behandler ikke direkte identifiserbare personopplysninger om ulykkes-involverte personer i ulykkesregisteret. Personopplysningene er avidentifisert i datasett som publiseres offentlig.\n" +
" Les mer om personvern her: "

export const KRYSSTABELL = "En krysstabell er en måte å fremstille eller ordne kategoriserte data på, og en enkel måte å presentere frekvensfordelinger. Du kan lage krysstabeller med opp til 4 ulike dimensjoner; maks 3 typer informasjon presentert i rader, og maks 1 type informasjon i kolonner.\n" +
    " Begrensninger som er satt, er av personvernhensyn eller av hensyn til at informasjonen ikke skal feiltolkes. Du kan laste ned tabellen i Excel, PDF og CSV-filer."

export const FEILKILDER = "Ikke alle ulykker som skjer på veg meldes til politiet og inkluderes i ulykkesstatistikken. Det er særlig mindre alvorlige ulykker som er underrepresenterte, og særlig ulykker med lettere skadde fotgjengere og syklister (der andre kjøretøy ikke har vært involvert). Skader der kun fotgjengere er involvert inngår ikke i definisjonen" +
    " av vegtrafikkulykke, da minst ett kjøretøy må være involvert i ulykken for at denne skal registreres."

export const BAKGRUNN = "Formålet med ulykkesstatistikken er å gi informasjon om trafikkulykker på norske veger. Statistikken over vegtrafikkulykker med personskade ble etablert i 1939. På denne tiden omfattet statistikken også alvorlige ulykker med materiell skade.\n" +
    " Meldeplikten til politiet for ulykker som ikke hadde ført til personskade ble stort sett opphevet i 1957. Fra 1964 ble bare ulykker med personskade tatt med i statistikken. I 1977 ble rapportskjemaet (Anmeldelse av vegtrafikkulykke) som politiet fyller ut fullstendig revidert, og ble gjort felles for politiet, Statistisk sentralbyrå (SSB) og vegmyndighetene.\n" +
    " \n Statens vegvesen har noe informasjon om noen ulykker tilbake til 1977. Disse (alle publiserte ulykker) er tilgjengeliggjort i andre informasjonsverktøy Statens vegvesen behandler, eksempelvis vegkart.no og Nasjonal vegdatabank. Offisielle ulykkestall i Norge publiseres av SSB."