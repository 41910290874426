import {SET_FILTER_VISES_I_TABELL} from "../actions/actionTypes";

const filterVisesITabell = (state = [], action) => {
  switch(action.type){
    case SET_FILTER_VISES_I_TABELL:
      return action.data;
    default:
      return state;
  }
};


export default filterVisesITabell;