import React, {useEffect, useRef, useState} from 'react';
import {Row, Col} from 'reactstrap';
import './raderOgKolonnerKlasse.css';
import {RadEn, RadTo, RadTre, Kolonne} from "./RaderOgKolonnerContainer";
import Knapp from '../uukomponenter/Knapp';
import * as constants from './RaderOgKolonnerKonstanter'
import {LITEN_KNAPP} from "../../ressurser/Konstanter";


/** RaderOgKolonnerRedux er en klasse som setter sammen funksjonaliteten til "Velg Rader og Kolonner". Komponenten er bygget opp av tittelinfoknapp fra /.Standardkomponenter/tittelinfoknapp,
 * LeggTilRadKnapp fra ./standardkomponenter/LeggTilRadKnapp og de 4 RaderOgKolonnerNedtrekkslistene definert i ./RaderOgKolonnerNedtrekkslisteRedux
 * */

export default function RaderOgKolonnerKlasse(props) {
    const [radToValgt, setRadToValgt] = useState(null)
    const [radTreValgt, setRadTreValgt] = useState(null)

    const selectRefRad1 = useRef()
    const selectRefRad2 = useRef()

    useEffect(() => {
        props.sendSokETO(props.sokETO);
    }, [props]);


    /** Funksjonen endrer lokal state slik at Rad 2 ikke vises og verdien settes til 'null' i redux store. Dersom det finnes en Rad 3 overføres satt verdi i Rad 3 til Rad 2
     * og Rad 3 slettes.
     * */
    const knappSlettet = (knapp) => {
        switch (knapp) {
            case "KnappTo":
                if (props.valgRadTo && props.valgRadTre) {
                    setRadToValgt(true)
                    setRadTreValgt(false)
                    selectRefRad2.current.focus()
                    props.setRadogKolonneTo(props.valgRadTre);
                    props.setRadogKolonneTre(null);
                } else if (props.valgRadTo && !props.valgRadTre) {
                    selectRefRad1.current.focus()
                    setRadToValgt(false)
                    props.setRadogKolonneTo(null);
                } else {
                    console.log("Error");
                }
                break;
            case "KnappTre":
                setRadToValgt(true)
                setRadTreValgt(false)
                selectRefRad2.current.focus()
                props.setRadogKolonneTre(null);
                break;
            default:
                break;
        }
    }

    /** Funksjonen endrer lokal state slik at Rad 2 og eller rad 3 vises dersom LeggTilRadKnapp trykkes.
     * */
    const knappTrykket = (knapp) => {
        switch (knapp) {
            case "KnappTo":
                setRadToValgt(true)
                break;
            case "KnappTre":
                setRadTreValgt(true)
                break;
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <fieldset>
                <legend style={{marginBottom: 0}}>
                    <h3 style={{marginTop: "1rem"}}>2. Velg rader og kolonner</h3>
                </legend>
                <p className='rader-og-kolonner-tekst'><i>Du kan maks legge til tre rader, og én kolonne.</i></p>
                <Row>
                    <Col className="pl-0">
                        <RadEn selectRef={selectRefRad1}/>
                        {!props.valgRadTo && props.valgRadEn ?
                            <Knapp
                                navn={constants.LEGG_TIL_RAD_TITTEL}
                                style={{justifyContent: "left", marginTop: 0}}
                                storrelse={LITEN_KNAPP}
                                handterKlikk={() => knappTrykket("KnappTo")}/>
                            : null}
                        {radToValgt || (props.valgRadTo) ?
                            <RadTo selectRef={selectRefRad2} knappSlettet={() => knappSlettet("KnappTo")}/> : null}
                        {props.valgRadTo && !props.valgRadTre ?
                            <Knapp
                                navn={constants.LEGG_TIL_RAD_TITTEL}
                                style={{justifyContent: "left", marginTop: 0}}
                                storrelse={LITEN_KNAPP}
                                handterKlikk={() => knappTrykket("KnappTre")}/>
                            : null}
                        {(radToValgt && radTreValgt) || (props.valgRadTo && props.valgRadTre) ?
                            <RadTre knappSlettet={() => knappSlettet("KnappTre")}/> : null}
                        <Kolonne/>
                    </Col>
                </Row>
            </fieldset>
        </React.Fragment>)

}





