import {selectSokETOResultat} from "../datafiltrering/datafiltreringSelector";
import {connect} from "react-redux";
import Krysstabell from './Krysstabell'
import {selectLoading} from "../midtdel/midtdelSelector";
import {selectSokRadEn, selectSokRadTo, selectSokRadTre} from '../raderogkolonner/raderOgKolonnerSelector';


const mapStateToProps = state => ({
    dataSett: selectSokETOResultat(state),
    loading: selectLoading(state),
    sokRad1: selectSokRadEn(state),
    sokRad2: selectSokRadTo(state),
    sokRad3: selectSokRadTre(state)

});

export const KrysstabellContainer = connect(
    mapStateToProps,
    null
)(Krysstabell);
