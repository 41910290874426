import {SET_LOADING, SET_INFO_TIL_BRUKER, SET_ALERT, SET_TAKK_TILBAKEMELDING, SET_VIS_TILBAKEMELDINGMODAL} from "../actions/actionTypes";


const tilbakemeldingBruker = (state = {tilbakemelding: null, loading: null}, action) =>{
    switch(action.type){
        case SET_INFO_TIL_BRUKER:
            return {
                ...state,
                tilbakemelding: action.data
            };
        case SET_LOADING:
            return{
                ...state,
                loading: action.loading,
            };
        case SET_ALERT:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    melding: action.melding,
                    farge: action.farge,
                    ikon: action.ikon,
                    ikonFarge: action.ikonFarge
                }
            };
        case SET_TAKK_TILBAKEMELDING:
            return {
                ...state,
                takkTilbakemelding: action.vis
            };
        case SET_VIS_TILBAKEMELDINGMODAL:
            return {
                ...state,
                visTilbakemeldingmodal: action.vis
            };
        default:
            return state;
    }
};

export default tilbakemeldingBruker;