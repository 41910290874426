export const URL_BACKEND = window.location.hostname.includes("localhost")
    ? "http://localhost:8080"
    : "https://" + window.location.hostname;

// export const nettverksvert = 'http://localhost:8080'; // Kan brukes for å teste mot lokal kjøring av Trine-repo
// export const nettverksvert = 'https://trine.utv.atlas.vegvesen.no'; // Kan brukes for lokal testing med data

export const URL_HENT_KODEVERK = URL_BACKEND + "/kodeverk/alle";

export const URL_FEATURE_TOGGLE = URL_BACKEND + "/feature-toggle";

export const URL_HENT_FEATURE_TOGGLE = URL_FEATURE_TOGGLE + "/beta";

export const URL_HENT_TRINE_BANNER_VEDLIKEHOLD = URL_BACKEND + "/vedlikehold/visBanner";

export const URL_HENT_TRINE_BANNER_TEKST = URL_BACKEND + "/vedlikehold/tekst";

export const URL_SEND_SOK_ETO = URL_BACKEND + "/sok";

export const URL_BRUKER = URL_BACKEND + "/bruker";

export const URL_HENT_OM_STATISTIKKEN_ETO = URL_BACKEND + "/omstatistikken";

export const URL_HENT_DEFINISJONER_ETO = URL_BACKEND + "/definisjoner";

export const URL_HENT_INFOTEKST = URL_BACKEND + "/informasjonstekst/hent";

export const URL_LAST_NED_PDF =  URL_BACKEND + "/pdf";

export const URL_LAST_NED_CSV = URL_BACKEND + "/csv";

export const URL_LAST_NED_XLSX = URL_BACKEND + "/xlsx";

export const URL_SEND_TILBAKEMELDING = URL_BACKEND + "/tilbakemelding";

export const URL_SEND_SPORSMAL = URL_BACKEND + "/sporsmal";

