import React from 'react';
import { connect } from 'react-redux';
import "./vedlikeholdBanner.css"
import {selectBannerVedlikehold} from "../raderogkolonner/raderOgKolonnerSelector";

class VedlikeholdBanner extends React.Component {
    render() {
        return <React.Fragment>
            {this.props.vedlikehold && this.props.vedlikehold.visBanner && <div className='header-vedlikehold'>
                <div className='header-vedlikehold-tekst'>{this.props.vedlikehold.tekst}</div>
            </div>}
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    vedlikehold: selectBannerVedlikehold(state)
});

export default connect(mapStateToProps)(VedlikeholdBanner);
