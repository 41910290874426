import React, {useCallback} from 'react';
import { Row, Container } from 'reactstrap';
import './informasjonstekst.css';
import { connect } from 'react-redux';
import {selectSistOppdatert, selectInformasjonstekst} from "./InformasjonstekstSelector";
import {selectBruker} from "../../components/loggingavpublikum/LoggingAvPublikumSelector";
import {
  INFORMASJONSTEKST_BETA,
} from "../../ressurser/Konstanter";
import {selectFeatureToggle} from "../raderogkolonner/raderOgKolonnerSelector";

/**
 * Informasjonstekst er en container som inneholder forklaringsteksten om hva nettsiden er ment til å brukes for. I dette tilfellet teksten rett under header.
 * Komponenten henter informasjon om når siste data ble hentet. 
 */

const Informasjonstekst = (props) => {

      const {featureToggle, informasjonstekst} = props;

      const fokuserOverskrift = useCallback((overskriftElement) => {
        if (overskriftElement && props.erBrukerValgt.bruker) {
          overskriftElement.focus();
        }
      });
  
   
      return (
            <div className="informasjonstekst">
                <h1 className='sokITrafikkuroverskrift' tabIndex="-1" ref={fokuserOverskrift}>Søk i Trafikkulykkesregisteret</h1>
                {featureToggle && featureToggle.beta &&
                    <p>
                        {INFORMASJONSTEKST_BETA}
                    </p>
                }
                <p>
                    {informasjonstekst.del1 + informasjonstekst.utvidet }
                </p>
            </div>
    );
  }

const mapStateToProps = state =>({
    sistOppdatert: selectSistOppdatert(state),
    featureToggle: selectFeatureToggle(state),
    informasjonstekst: selectInformasjonstekst(state),
    erBrukerValgt: selectBruker(state)
});

export const InformasjonstekstContainer = connect(
    mapStateToProps,
    null
)(Informasjonstekst);
