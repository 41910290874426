export const OM_RADER_OG_KOLONNER_TEKST = 'Her kan du velge opptil 3 rader og 1 kolonne som vises i tabellen.';
export const VELG_RADER_OG_KOLONNER_TITTEL='2. Velg rader og kolonner';
export const TITTELINFOKNAPP_RADER_OG_KOLONNER_ID ='tre';
export const LEGG_TIL_RAD_TITTEL = 'Legg til rad';
export const LEGG_TIL_RAD_HOVERTEKST = 'Klikk for å legge til enda en rad';

// Alle rader og kolonner som kan velges
export const AAR = 0;
export const MANED = 1;
export const UKEDAGSTYPE = 2;
export const TIMETYPE = 3;
export const FYLKE = 4;
export const KOMMUNE = 5;
export const TETTSTED = 6;
export const VAERFORHOLD = 7;
export const FOEREFORHOLD = 8;
export const LYSFORHOLD = 9;
export const TEMPERATUR = 10;
export const ALVORLIGHETSGRAD = 11;
export const ULYKKESTYPER = 12;
export const ULYKKESKODER = 13;
export const VEGKATEGORI = 14;
export const STEDSFORHOLD = 15;
export const VEGTYPE = 16;
export const VEGDEKKE = 17;
export const KJOEREFELTTYPE = 18;
export const FYSISK_MIDTDELER = 19;
export const REGULERING_I_VEGKRYSS = 20;
export const REGULERING_I_GANGFELT = 21;
export const FARTSGRENSE = 22;
export const ENHETSTYPE_HOVEDKATERGORI = 23;
export const ENHETSTYPE_UNDERKATERGORI = 24;
export const ENHETSTYPE = 25;
export const REGISTRERINGSLAND = 26;
export const AARSMODEL = 27;
export const DRIVSTOFFTYPE = 28;
export const PAAKJORT_HINDERTYPE = 29;
export const TILGHENGER = 30;
export const TUNGTRANSPORT = 31;
export const VOGNTOG = 32;
export const KJONN = 34;
export const ALDERSGRUPPE = 35;
export const ALDERSGRUPPE_5_AARIG = 36;
export const TRAFIKANTTYPE = 37;
export const PLASSERING = 39;
export const SKADEGRAD = 41;