import {sendSokETO, setDatasett, setKolonne, setRadEn, setRadTo, setRadTre} from "../../actions";
import {DATASETT} from "../../ressurser/Konstanter";
import {
  selectDatasett,
  selectKodeverk,
  selectSokETO
} from "../datafiltrering/datafiltreringSelector";
import {connect} from "react-redux";
import DatasettKomponent from './Datasett'
import {selectSokRadEn, selectSokRadTo, selectSokRadTre} from "../raderogkolonner/raderOgKolonnerSelector";



const datasettDispatch = dispatch => ({
  setValgtData: data => {
    dispatch(setDatasett(data));
  },
  setRad1: data => {
    dispatch(setRadEn(data));
  },
  setRad2: data => {
    dispatch(setRadTo(data));
  },
  setRad3: data => {
    dispatch(setRadTre(data));
  },

  setKolonne: data => {
    dispatch(setKolonne(data));
  },
  sendSokETO: (sokETO) => {
    dispatch(sendSokETO(sokETO))}
});
const datasettState = state => ({
  navn: DATASETT,
  datasett: selectDatasett(state),
  sokETO: selectSokETO(state),
  kodeverk: selectKodeverk(state),
  radEn: selectSokRadEn(state),
  radTo: selectSokRadTo(state),
  radTre: selectSokRadTre(state)
});

export const Datasett = connect(
    datasettState,
    datasettDispatch
)(DatasettKomponent);