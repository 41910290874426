import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import styled from 'styled-components';
import Knapp from './Knapp';
import "./tabell.css";
import { LITEN_KNAPP } from '../../ressurser/Konstanter';
import arrowDown from '../../ressurser/icons/arrow-down-white.svg'
import arrowUp from '../../ressurser/icons/arrow-up-white.svg'

const Styles = styled.div`
overflow: auto;
width: 100%;

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  background: #ececec; 
}
::-webkit-scrollbar-thumb {
  background: #cacaca; 
}
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa; 
}

.table {
  margin-bottom: 0;
  .th, 
  .tf {
    padding: 5px;
    position: sticky;
    z-index: 1;
    background-color: #45515a;
    color: #fff;
    &.klissete {
      z-index: 5 !important;
      overflow: hidden;
      :last-child {
        border-right: 1px solid #dee2e6;
      }
    }
    :not(.klissete) {
      border-left: 1px solid #dee2e6;
      overflow: hidden;
    }
  }
  .td {
    padding: 5px;
    border-right: 1px solid #dee2e6;
    &.klissete {
      border-right: 0;
      border-bottom: 0;
      :last-child {
        border-right: 1px solid #dee2e6;
      }
    }
    :not(.klissete) {
      border-left: 1px solid #dee2e6;
      overflow: hidden;
      text-align: right;
    }
  }
  .klissete {
    position: sticky;
    z-index: 4 !important;
    background-color: #45515a;
    color: #fff;
  }
  .th {
    top: 0;
    cursor: pointer;
    border-top: 0;
    &:hover {
      color: #dee2e6;
    }
  }
  .tf {
    bottom: 0;
    :not(.klissete) {
      text-align: right;
    }
  }
}
`;

function Tabell({unMemoColumns, unMemoData}) {

  const data = useMemo(() => unMemoData, [unMemoData])
  const columns = useMemo(() => unMemoColumns, [unMemoColumns])
  const [antallValgteKolonner, setAntallValgteKolonner] = useState(0);
  const [bredde1, setBredde1] = useState(0);
  const [bredde2, setBredde2] = useState(0);
  const [dimensjoner, setDimensjoner] = useState({
    height: window.innerHeight, width: window.innerWidth
  })
  const refForsteKolonne = useRef(null);
  const refAndreKolonne = useRef(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({columns, data, initialState: {pageIndex: 0, pageSize: 10}}, useSortBy, usePagination)

  //setter dimensjoner ved endring i vindusstørrelse
  useEffect(() => { 
    function handterZoom() {
      setDimensjoner({
        height: window.innerHeight,
        width: window.innerWidth
       })
    }
     window.addEventListener('resize', handterZoom);
     return () => window.removeEventListener('resize', handterZoom);
  });
  
  //oppdaterer bredder ved nye kolonner, endring av side, antall sider eller vindusstørrelse
  useEffect(() => {
    for(let i=1; i<antallValgteKolonner; i++) {
      if(i === 1) {
        setBredde1(refForsteKolonne ? refForsteKolonne.current.offsetWidth : undefined);
      }
      else if(i === 2) {
        setBredde2(refAndreKolonne ? refForsteKolonne.current.offsetWidth 
                                   + refAndreKolonne.current.offsetWidth
                                   : undefined)
      }
    }
  }, [antallValgteKolonner, pageSize, page, dimensjoner])

  //setter antall "sticky" kolonner (max 3 stk)
  useEffect(() => {
    let teller = 0;
    for(let i=0; i<3; i++){
      if(columns[i].sticky) teller++
    }
    setAntallValgteKolonner(teller);
  }, [columns])

  //henter referanser for sticky kolonner
  const hentRef = (i) => {
    return i == 0 ? refForsteKolonne : refAndreKolonne;
  }

  //henter bredder for sticky kolonner
  const getBredde = (i) => {
    if(i === 0) return 0;
    if(i === 1) return bredde1;
    if(i === 2) return bredde2;
  }

return (
  <>
    <Styles>
      <table {...getTableProps()} className="table">
        <caption className='caption' aria-hidden="true">
          <span style={{position: "sticky", left: '5px', color: "white"}}>Trafikkulykkesregister</span>
        </caption>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                    ref={i < antallValgteKolonner - 1 ? hentRef(i) : undefined}
                    aria-sort={column.isSorted
                      ? column.isSortedDesc
                      ? 'descending'
                      : 'ascending'
                      : 'none'}
                    scope="col"
                    style={i < antallValgteKolonner ? {left: getBredde(i)} : undefined} 
                    className={"th" + (i < antallValgteKolonner ? " klissete" : "")}>
                      <div className='header-tekst'>
                      <span>{column.render('Header')}</span>
                      <span aria-hidden="true">
                        {column.isSorted
                          ? column.isSortedDesc
                          ? <img src={arrowDown} alt=""/>
                          : <img src={arrowUp} alt=""/>
                          : '⇵'}
                      </span>
                      </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="body">
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  while(i < antallValgteKolonner) {
                    return (
                      <th {...cell.getCellProps()} 
                          role="rowheader"
                          scope="row"
                          style={{left: getBredde(i)}} 
                          className={"td klissete"}>
                            {cell.render('Cell')}
                      </th>
                  )
                  }
                  return (
                      <td {...cell.getCellProps()} 
                          className="td">
                            {cell.render('Cell')}
                      </td>
                  )
                })}
              </tr>
            )}
          )}
        </tbody>
        <tfoot>
          {footerGroups.map(group => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column, i)=> (
                <td {...column.getFooterProps()} 
                  style={i < antallValgteKolonner ? {left: getBredde(i)} : undefined} 
                  className={"tf" + (i < antallValgteKolonner ? " klissete" : "") }>
                    {column.render('Footer')}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </Styles>
    {/*<Row>*/}
    {/*  <Col xl={{size: '4', offset: '0'}} md={{size: '8', offset: '2'}} sm={{size: '10', offset: '1'}} xs={{size: '10', offset: '1'}}>*/}
      <div className='pagination'>
        <div className='forrigeknapp'>
          {canPreviousPage &&
            <Knapp navn="Forrige"
                   handterKlikk={() => previousPage()}
                   disabled={!canPreviousPage}
                   style={{marginTop: "10px", height: "50px"}}
                   storrelse={LITEN_KNAPP}
            />
          }
        </div>
        <span className='sidevelger'>
          Side{' '}
          <input
            type="number"
            name="number"
            aria-label="Velg side"
            aria-live="polite"
            min="1"
            max={pageCount.toString()}
            defaultValue={pageIndex + 1}
            value={pageIndex + 1}
            onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }
            }
            style={{ width: '85px', marginTop: "10px", fontSize: "13px", height: "50px"}}
          />
            {" av"} {pageCount}
        </span>{' '}
        <select
          className="antallradvelger"
          aria-label="Velg antall rader"
          value={pageSize}
          onChange={e => {
              setPageSize(Number(e.target.value))
            }
          }
            style={{height: "50px", marginTop: "10px", fontSize: "medium"}}
          >
            {[5, 10, 15, 20, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize} rader
              </option>
            ))}
          </select>
          <div className='nesteknapp'>
          {canNextPage &&
            <Knapp
              navn="Neste"
              handterKlikk={() => nextPage()}
              disabled={!canNextPage}
              style={{marginTop: "10px", height: "50px"}}
              storrelse={LITEN_KNAPP}/>}
          </div>
      </div>
  </>
    // </Row>
    );
};

export default Tabell;