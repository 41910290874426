import * as PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {Col, Row} from "reactstrap";
import selectStandardrapporter from '../redux/selectors';
import {URL_BACKEND} from '../../../middleware/konstanter';
import {EPOST_BESTILL_RAPPORT} from '../../../ressurser/Konstanter';
import mail from "../../../ressurser/icons/mail.svg";
import { faFilePdf, faFileWord} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Rapportliste extends React.PureComponent {

  static bestillRapport(rapporttype, arBehandlet, fylkeBehandlet, kommuneBehandlet) {
    const bodyTekst = `Trine: Vennligst lag følgende rapport: ${rapporttype} for år: ${arBehandlet}, fylke: ${fylkeBehandlet}, kommune: ${kommuneBehandlet}, `;
    const emne = `Trine: Opprett standardrapport ${rapporttype} for ${arBehandlet}, i ${fylkeBehandlet}, ${kommuneBehandlet}`;
    document.location.href = "mailto:" + EPOST_BESTILL_RAPPORT + "?subject="
        + encodeURIComponent(emne)
        + "&body=" + encodeURIComponent(bodyTekst);
  }

  render() {
    const {overskrift, rapportliste, standardrapporter, sokUtfort, kanBestilles} = this.props;
    const {tilgjengeligeRapporter, ar, fylke, kommune} = standardrapporter;

    if (kanBestilles && kanBestilles.length === 0) return null;

    let fylkeBehandlet = "Alle";
    let kommuneBehandlet = "Alle";
    let arBehandlet = null;
    if (fylke && fylke.label) {
      fylkeBehandlet = fylke.label.split("- ")[1];
    }
    if (kommune && kommune.label) {
      kommuneBehandlet = kommune.label.split("- ")[1];
    }
    if (ar && ar.label) {
      arBehandlet = ar.label;
    }
    const rapporter = Object.keys(rapportliste).map(rapport => {
      const tilgjengelig = tilgjengeligeRapporter.includes(rapport);
      const bestillingAktiv = kanBestilles.includes(rapport);
      if (!bestillingAktiv) return null;
// TODO: ikke aria live polite, men sett fokus til overskrift
      return (
          <React.Fragment key={rapport}>
            <Row style={{marginLeft:0}}>
              <Col>
              <div className="listerapport" aria-live="polite">
                  <h3 style={{fontSize:"1.3rem"}}>{rapportliste[rapport]}</h3>
                  {!sokUtfort && (
                      <p><i>Du må utføre et søk for å få tilgang til rapporter.</i></p>
                  )}
                  {sokUtfort && tilgjengelig && (
                        <ul>
                          <li style={{marginBottom: 0}}>
                            <a className="editorial-link" href="#" onClick={() => {window.location =
                                `${URL_BACKEND}/standardrapport/hent/pdf/${rapport}/${arBehandlet}` +
                                `/${fylkeBehandlet}/${kommuneBehandlet}`}}>
                              Last ned {rapportliste[rapport]} {"som PDF "}
                              <FontAwesomeIcon icon={faFilePdf} />
                            </a>
                          </li>
                          <li style={{marginTop: 5}}>
                            <a className="editorial-link" href="#" onClick={() => {window.location =
                                `${URL_BACKEND}/standardrapport/hent/docx/${rapport}/${arBehandlet}` +
                                `/${fylkeBehandlet}/${kommuneBehandlet}`}}>
                              Last ned {rapportliste[rapport]} {"som Word-dokument "} 
                              <FontAwesomeIcon icon={faFileWord} />
                            </a>
                          </li>
                        </ul>
                  )}
                  {sokUtfort && !tilgjengelig && (
                        <ul className="mb-4">
                          <li>
                            <a className="editorial-link" href="#" aria-label={"Bestill " + rapportliste[rapport] + " via e-post. Åpner e-post klient."} onClick={() => {Rapportliste.bestillRapport(rapport, arBehandlet, fylkeBehandlet, kommuneBehandlet)}}>
                              Bestill {rapportliste[rapport]}
                              <span> <img src={mail} alt="Mail"/></span>
                            </a>
                          </li>
                        </ul>
                  )}
              </div>
              </Col>
            </Row>
          </React.Fragment>
      );
    });

    return (
        <Col lg={5} className="rapportlister">
          <div className="mb-3 mt-3">
            <h2 className="heading-decoration">{overskrift}</h2>
            {rapporter}
          </div>
          <br/>
        </Col>
    );
  }
}

Rapportliste.propTypes = {
  sokUtfort: PropTypes.bool.isRequired,
  overskrift: PropTypes.string.isRequired,
  rapportliste: PropTypes.object.isRequired,
  standardrapporter: PropTypes.object.isRequired,
  kanBestilles: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  standardrapporter: selectStandardrapporter(state)
});

export default connect(mapStateToProps)(Rapportliste);