import { combineReducers } from 'redux'
import sokETO from './sokETOReducer'
import kodeverk from './kodeverkReducer'
import sokETOResultat from './sokETOResultReducer'
import tilbakemeldingBruker from './tilbakemeldingBrukerReducer'
import valgtFilter from "./valgtFilterReducer";
import filterVisesITabell from "./filterVisesITabellReducer";
import bruker from './brukerReducer'
import omStatistikkenKodeverk from './omStatistikkenReducer';
import standardrapporter from './standardrapporterReducer';
import featureToggle from "./featureToggleReducer";
import nullstillFilter from "./nullstillFilterReducer";
import informasjonstekst from "./informasjonstekstReducer";
import valgtSide from "./valgtSideReducer";


export default combineReducers({
  kodeverk,
  sokETO,
  sokETOResultat,
  bruker,
  tilbakemeldingBruker,
  standardrapporter,
  valgtFilter,
  filterVisesITabell,
  omStatistikkenKodeverk,
  featureToggle,
  nullstillFilter,
  informasjonstekst,
  valgtSide
})
