import React, {useState} from 'react';
import PropTypes from 'prop-types';

const Tekstinput = ({ navn, verdi, oppdaterFelt, maxLength, disabled, logiskSjekk, ledetekst, feilmelding, logiskSjekkOnBlur, inputRef, type}) => {

    const [aktiverFeilmelding, setAktiverFeilmelding] = useState(false);

    const handleChange = (nyVerdi) => {
        oppdaterFelt(nyVerdi.substring(0, maxLength));
        if(!logiskSjekk || logiskSjekkOnBlur){
            return;
        }
        if (logiskSjekk(nyVerdi)) {
            setAktiverFeilmelding(false);
        } else {
            setAktiverFeilmelding(true);
        }
    };

    const handleLogiskSjekkOnBlur = (nyVerdi) => {
        if(!logiskSjekk) return;
        if (logiskSjekk(nyVerdi) || (nyVerdi==0 && verdi==0)){
            setAktiverFeilmelding(false);
        
        } else {
            setAktiverFeilmelding(true);
        }
    }


    return (
            <div className="form-element">
                <label className="form-label" htmlFor={navn}>{navn}</label>
                <input
                    className={aktiverFeilmelding ? `form-input__text input--xlarge form-input__text--error` : `form-input__text input--xlarge`}
                    type={type}
                    onChange={(e) => handleChange(e.target.value)}
                    id={navn}
                    value={verdi || ""}
                    disabled={disabled}
                    placeholder={ledetekst}
                    onBlur={(e) => handleLogiskSjekkOnBlur(e.target.value)}
                    autoComplete="none"
                    autoCorrect="none"
                    ref={inputRef}
                />
                <span aria-live='polite'> 
                {aktiverFeilmelding ?
                    <div id="feilmelding">
                        <div className="form-error-message input--xlarge">
                            <p className="form-error-message__text">
                                {feilmelding}
                            </p>
                        </div>
                    </div>
                    :
                    <></>
                }
                </span>
            </div>
    )
};

Tekstinput.propTypes = {
    navn: PropTypes.string.isRequired,
    verdi: PropTypes.string,
    oppdaterFelt: PropTypes.func.isRequired,
    maxLength: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    logiskSjekk: PropTypes.func,
    ledetekst: PropTypes.string,
    feilmelding: PropTypes.string,
    logiskSjekkOnBlur:PropTypes.bool,
    type: PropTypes.string
};

Tekstinput.defaultProps = {
    verdi: '',
    disabled: false,
    logiskSjekk: null,
    ledetekst: "",
    feilmelding: "",
    logiskSjekkOnBlur: false,
    type: "text"
};


export default Tekstinput;