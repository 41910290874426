import {connect} from 'react-redux';
import FilterNedtrekksliste from '../standardkomponenter/FilterNedtrekksliste';
import {
  ARSMODELL,
  ARSMODELL_ETO_NAVN,
  DRIVSTOFFTYPE,
  DRIVSTOFFTYPE_ETO_NAVN,
  ENHET_ETO_NAVN,
  ENHETSTYPE,
  ENHETSTYPE_ETO_NAVN,
  ENHETSTYPE_HOVEDKATEGORI,
  ENHETSTYPE_HOVEDKATEGORI_ETO_NAVN,
  ENHETSTYPE_UNDERKATEGORI,
  ENHETSTYPE_UNDERKATEGORI_ETO_NAVN,
  MERKE_KJORETOY,
  MERKE_KJORETOY_ETO_NAVN,
  PAKJORT_TYPE_HINDER,
  PAKJORT_TYPE_HINDER_ETO_NAVN,
  REGISTRERINGSLAND,
  REGISTRERINGSLAND_ETO_NAVN,
  TILHENGER,
  TILHENGER_ETO_NAVN,
  TUNGTRANSPORT,
  TUNGTRANSPORT_ETO_NAVN,
  VOGNTOG,
  VOGNTOG_ETO_NAVN
} from "../../ressurser/Konstanter";
import {
  selectArsmodell,
  selectDrivstofftype,
  selectEnhetstype,
  selectEnhetstypeHovedkategori,
  selectEnhetstypeUnderkategori,
  selectMerkeKjoretoy,
  selectPakjortTypeHinder,
  selectRegistreringsland,
  selectTilhenger,
  selectTungtransport,
  selectVogntog
} from "./datafiltreringSelector";
import {setFilterVerdi, setValgtFilter} from "../../actions";

const enhetFilterDispatch = dispatch => ({
  setFilter: (etoNavn, data) => {
    dispatch(setFilterVerdi(ENHET_ETO_NAVN, etoNavn, data));
  },
  setValg: (navn, valg) => {
    dispatch(setValgtFilter(navn, valg));
  }
});

const enhetstypehovedkategoriState = state => ({
  navn: ENHETSTYPE_HOVEDKATEGORI,
  etoNavn: ENHETSTYPE_HOVEDKATEGORI_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectEnhetstypeHovedkategori(state)
});
export const EnhetHovedkategori = connect(
    enhetstypehovedkategoriState,
    enhetFilterDispatch
)(FilterNedtrekksliste);


const enhetstypeunderkategoriState = state => ({
  navn: ENHETSTYPE_UNDERKATEGORI,
  etoNavn: ENHETSTYPE_UNDERKATEGORI_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectEnhetstypeUnderkategori(state)
});
export const EnhetUnderkategori = connect(
    enhetstypeunderkategoriState,
    enhetFilterDispatch
)(FilterNedtrekksliste);

const enhetstypeState = state => ({
  navn: ENHETSTYPE,
  etoNavn: ENHETSTYPE_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectEnhetstype(state)
});
export const Enhetstype = connect(
    enhetstypeState,
    enhetFilterDispatch
)(FilterNedtrekksliste);


const registreringslandState = state => ({
  navn: REGISTRERINGSLAND,
  etoNavn: REGISTRERINGSLAND_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectRegistreringsland(state)
});
export const Registreringsland = connect(
    registreringslandState,
    enhetFilterDispatch
)(FilterNedtrekksliste);


const arsmodellState = state => ({
  navn: ARSMODELL,
  etoNavn: ARSMODELL_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectArsmodell(state),
  visUtenKode: true
});
export const Arsmodell = connect(
    arsmodellState,
    enhetFilterDispatch
)(FilterNedtrekksliste);


const merkekjoretoyState = state => ({
  navn: MERKE_KJORETOY,
  etoNavn: MERKE_KJORETOY_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectMerkeKjoretoy(state)
});
export const MerkeKjoretoy = connect(
    merkekjoretoyState,
    enhetFilterDispatch
)(FilterNedtrekksliste);


const drivstofftypeState = state => ({
  navn: DRIVSTOFFTYPE,
  etoNavn: DRIVSTOFFTYPE_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectDrivstofftype(state)
});
export const Drivstofftype = connect(
    drivstofftypeState,
    enhetFilterDispatch
)(FilterNedtrekksliste);


const pakjorttypehinderState = state => ({
  navn: PAKJORT_TYPE_HINDER,
  etoNavn: PAKJORT_TYPE_HINDER_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectPakjortTypeHinder(state)
});
export const PakjortTypeHinder = connect(
    pakjorttypehinderState,
    enhetFilterDispatch
)(FilterNedtrekksliste);


const tilhengerState = state => ({
  navn: TILHENGER,
  etoNavn: TILHENGER_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectTilhenger(state)
});
export const Tilhenger = connect(
    tilhengerState,
    enhetFilterDispatch
)(FilterNedtrekksliste);


const tungtransportState = state => ({
  navn: TUNGTRANSPORT,
  etoNavn: TUNGTRANSPORT_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectTungtransport(state)
});
export const Tungtransport = connect(
    tungtransportState,
    enhetFilterDispatch
)(FilterNedtrekksliste);


const vogntogState = state => ({
  navn: VOGNTOG,
  etoNavn: VOGNTOG_ETO_NAVN,
  erMulti: true,
  valgmuligheter: selectVogntog(state)
});
export const Vogntog = connect(
    vogntogState,
    enhetFilterDispatch
)(FilterNedtrekksliste);
