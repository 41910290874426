import {SET_KODEVERK} from "../actions/actionTypes";

const kodeverk = (state = {}, action) =>{

    switch(action.type){
        case SET_KODEVERK:
            // Muterer data objectet og fjerner "hibernateLazyInitializer" da denne medfører feil i parsing av Json i senere ledd backend
            const dataCopy = action.data;
            if (action.type === SET_KODEVERK) {
                if (dataCopy && dataCopy.filterETO && dataCopy.filterETO.ulykke && dataCopy.filterETO.ulykke.ulykkeskoder) {
                    dataCopy.filterETO.ulykke.ulykkeskoder.forEach(ulykkeskode =>
                      delete ulykkeskode.ulykkestypeUnderkategori.hibernateLazyInitializer)
                }
            }
            return dataCopy;
        default:
            return state;
    }
};

export default kodeverk;