import React, { useCallback, useEffect} from 'react';
import Tilbakeknapp from "../uukomponenter/Tilbakeknapp";
import {useDispatch} from "react-redux";
import {setValgtSide} from "../../actions";
import "./nettstedskart.css"
import {Link} from "react-router-dom";

const Nettstedskart = () => {
    const dispatch = useDispatch();

    const fokuserOverskrift = useCallback((overskriftElement) => {
        if (overskriftElement) {
            overskriftElement.focus();
        }
    });

    useEffect(()=>{
        document.title = "Nettstedskart | Trine";
        dispatch(setValgtSide("nettstedskart"));
    })

    return (
        <>
            <div className="nettstedskart-side">
                <Tilbakeknapp/>
                <h1 ref={fokuserOverskrift} tabIndex="-1" className="normaltekst">Nettstedskart</h1>
                <p className="normaltekst">
                    Her finner du en liste over innholdet på trine.atlas.vegvesen.no.
                </p>
                <div style={{marginLeft: "1rem"}}>
                    <ul style={{listStyleType: "disc"}}>
                        <li style={{marginBottom: "1rem"}}>
                            <Link  to="/">
                                <p className="nettstedskart-lenke">Trafikkulykkesregisteret</p>
                            </Link>
                        </li>
                        <li style={{marginBottom: "1rem"}}>
                            <Link  to="/om">
                                <p className="nettstedskart-lenke">Om statistikker</p>
                            </Link>
                        </li>
                        <li style={{marginBottom: "1rem"}}>
                            <Link  to="/rapporter">
                                <p className="nettstedskart-lenke">Standardrapporter</p>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Nettstedskart;
