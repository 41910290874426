import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import SlettKnapp from "./raderogkolonnerknapper/SlettKnapp"
import './raderOgKolonnerNedtrekksliste.css';
import {genererFeilmeldingRadOgKolonne, logiskKontrollRadOgKolonner} from "../standardkomponenter/LogiskKontroll";
import NedtrekksListe from "../uukomponenter/NedtrekksListe";

/** FilterNedtrekksliste er en wrapper rundt Nedtrekksliste og sender og mottar oppdateringer til og fra Redux store*/
export default class RaderOgKolonnerNedtrekksliste extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            feilmelding: ""
        });
        this.onChange = this.onChange.bind(this);
        this.formaterKodeverk = this.formaterKodeverk.bind(this);
        this.formaterVisuellVerdi = this.formaterVisuellVerdi.bind(this);
    }

    /** onChange tar seg av det som skjer i det bruker gjør endringer i Rader og kolonner nedtrekksliste
     * setRadOgKolonne(data) brukes til å oppdatere redux store
     * this.setState({verdi: data}) oppdaterer lokal variabel som bestemmer hva som vises i listen
     * @param {]
     * @public
     */
    onChange(data) {
        const {setRadOgKolonne, valgmuligheter} = this.props;
        let tranformertData = null;
        valgmuligheter.forEach(kodeverk => {
            if (data.value === kodeverk.id) {
                tranformertData = {
                    id: kodeverk.id,
                    navn: kodeverk.navn
                }
            }
        });
        setRadOgKolonne(tranformertData);
    }

    /**
     * Funksjonen gjør om kodeverket hentet fra redux til formatet det skal vises i rader og kolonner nedtrekkslisten
     */
    formaterKodeverk(valgmuligheter) {
        return valgmuligheter.map(a => ({
            value: a.id,
            label: a.id + " - " + a.navn
        }));
    }

    /**
     * Funksjonen gjør om verdi satt i redux til formatet det skal vises i rader og kolonner nedtrekkslisten
     */
    formaterVisuellVerdi(verdi) {
        return {
            value: verdi.id,
            label: verdi.id + " - " + verdi.navn
        }
    }

    /**
     *  Funksjonen setter ønsket startverdi som vises i nedtrekkslisten
     */
    setStartverdi(verdi, setRadOgKolonne, valgmuligheter, startverdi, kodeverk) {
        if (verdi === null && kodeverk !== null) {
            setRadOgKolonne(valgmuligheter[startverdi]);
        }
    }

    /**
     * Funksjon som skjekker logisk kontroll og setter inn feilmelding hvis det er noen
     *
     * @param kodeverk Kodeverket er valgmulighetene bare formatert til formatet som blir vist i listen
     * @public
     */
    sjekkLogiskKontroll(kodeverk) {
        const {navn, verdi, valgmuligheter} = this.props;
        if (this.props.sokETO.raderOgKolonnerValgETO && verdi) {
            let feilmelding = genererFeilmeldingRadOgKolonne(verdi);
            if (feilmelding !== undefined && feilmelding !== this.state.feilmelding) {
                this.setState({
                    feilmelding: feilmelding
                });
            }
            let ulovligIndex = logiskKontrollRadOgKolonner(navn, valgmuligheter, this.props.sokETO.raderOgKolonnerValgETO);
            if (ulovligIndex !== -1 && ulovligIndex !== undefined) {
                kodeverk[ulovligIndex] = {
                    value: kodeverk[ulovligIndex].value,
                    label: kodeverk[ulovligIndex].label,
                    tillat: false
                };
            }
        }
    }

    /**
     *
     * @returns {<Nedtrekksliste/> med gitt props}
     */
    render() {
        const {navn, valgmuligheter, startverdi, verdi, setRadOgKolonne, selectRef} = this.props;
        const kodeverk = valgmuligheter ? this.formaterKodeverk(valgmuligheter) : null;
        const visuellVerdi = verdi ? this.formaterVisuellVerdi(verdi) : null;
        this.setStartverdi(verdi, setRadOgKolonne, valgmuligheter, startverdi, kodeverk);

        this.sjekkLogiskKontroll(kodeverk);
        if (this.props.soppelbotte !== true) {
            return (
                <React.Fragment>
                    <Col className="mt-1 pl-0">
                        <NedtrekksListe
                            selectRef={selectRef}
                            navn={navn}
                            verdi={visuellVerdi}
                            valgmuligheter={kodeverk}
                            onChange={this.onChange}
                            feilmelding={this.state.feilmelding}
                            ingenKodeverk={kodeverk === null}
                        />
                    </Col>
                </React.Fragment>)
        } else {
            return (
                <React.Fragment>
                    <Col className="mt-3 pl-0">
                        <NedtrekksListe
                            selectRef={selectRef}
                            navn={navn}
                            verdi={visuellVerdi}
                            valgmuligheter={kodeverk}
                            onChange={this.onChange}
                            feilmelding={this.state.feilmelding}
                            ingenKodeverk={kodeverk === null}
                            slettKnapp={<SlettKnapp handterKlikk={this.props.knappSlettet} hoverTekst={"Fjern "+ navn}/>}
                        />
                    </Col>
                </React.Fragment>
            )
        }
    }
}

RaderOgKolonnerNedtrekksliste.propTypes = {
    /** Teksten over nedtrekkslisten som beskriver hva nedtrekkslisten filtrerer på*/
    navn: PropTypes.string.isRequired,
    /** Valgmulighetene tilgjengelig i nedtrekkslisten*/
    valgmuligheter: PropTypes.array
};