import {connect} from "react-redux";
import FilterOversiktKomponent from "./FilterOversikt";
import {
  selectSokkolonne,
  selectSokRadEn,
  selectSokRadTo,
  selectSokRadTre
} from "../raderogkolonner/raderOgKolonnerSelector"
import {
  selectDatasett, selectFilterVisesITabell, selectSokETOResultat,
} from "../datafiltrering/datafiltreringSelector";
import {selectKodeverkFilterETO, selectSokETOResultatFilterETO} from "../datafiltrering/datafiltreringSelector";

const filterState = state => ({
  datasett: selectDatasett(state),
  rad1: selectSokRadEn(state),
  rad2: selectSokRadTo(state),
  rad3: selectSokRadTre(state),
  kolonne: selectSokkolonne(state),
  sokETOResultat: selectSokETOResultat(state),
  sokETOResultatFilterETO: selectSokETOResultatFilterETO(state),
  kodeverkFilterETO: selectKodeverkFilterETO(state)
});

export const FilterOversikt = connect(
    filterState,
    null
)(FilterOversiktKomponent);
