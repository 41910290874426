import React, {useEffect, useLayoutEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';

const Tekstboks = (props) => {
    const {navn, verdi, inputId, oppdaterFelt, maxLength, ledetekst, logiskSjekk, feilmelding, disabled, inputRef, visFeilmelding} = props;

    const [aktiverFeilmelding, setAktiverFeilmelding] = useState(false);

    useLayoutEffect(()=>{
        if(visFeilmelding == aktiverFeilmelding) return;
        setAktiverFeilmelding(visFeilmelding);
    },[visFeilmelding])

    const handleChange = (nyVerdi) => {
        oppdaterFelt(nyVerdi.substring(0, maxLength));
        if (logiskSjekk(nyVerdi)) {
            setAktiverFeilmelding(false);
        } else {
            setAktiverFeilmelding(true);
        }
    };


    return (
        <div className="form-element">
            <label className="form-label" htmlFor={inputId}>{navn}</label>
            <textarea
                className={aktiverFeilmelding ? `form-input__text input--xlarge form-input__text--error` : `form-input__text input--xlarge`}
                id={inputId}
                onChange={e => handleChange(e.target.value)}
                value={verdi}
                rows={6}
                placeholder={ledetekst}
                disabled={disabled}
                ref={inputRef}
            />
            <span aria-live='polite'>  {aktiverFeilmelding ?
                <div id="feilmelding">
                    <div className="form-error-message input--xlarge">
                        <p className="form-error-message__text">
                            {feilmelding}
                        </p>
                    </div>
                </div>
                :
                <></>
            }</span>
          
        </div>
    )
};

Tekstboks.propTypes = {
    navn: PropTypes.string.isRequired,
    oppdaterFelt: PropTypes.func.isRequired,
    inputId: PropTypes.string.isRequired,
    verdi: PropTypes.string,
    maxLength: PropTypes.number.isRequired,
    ledetekst: PropTypes.string,
    logiskSjekk: PropTypes.func,
    feilmelding: PropTypes.string,
    disabled: PropTypes.bool,
    visFeilmelding: PropTypes.bool
};

Tekstboks.defaultProps = {
    verdi: '',
    ledetekst: "",
    logiskSjekk: null,
    feilmelding: "",
    disabled: false,
    visFeilmelding: false
};

export default Tekstboks;