import {SET_VALGT_FILTER} from "../actions/actionTypes";



const valgtFilter = (state = [], action) => {
  switch(action.type){
    case SET_VALGT_FILTER:
      return {
        ...state,
        [action.navn] : action.data
      };
    default:
      return state;
  }
};


export default valgtFilter;