import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {ANTALL_PERSONSKADER, ANTALL_PERSONSKADEULYKKER} from "../../ressurser/Konstanter";
import {ENDREDE_FELTER, NYE_FELTER} from "../../ressurser/EndredeKodeverk";
import "./filterOversikt.css"
import down from "../../ressurser/icons/arrow-down.svg"
import up from "../../ressurser/icons/arrow-up.svg"

/*
Komponent som er koblet sammen  med FilterOversiktInnhold
* */
export default class FilterOversikt extends React.Component {
  constructor(props) {
    super(props);
    this.genererVisningsfilter = this.genererVisningsfilter.bind(this);
    this.kapitaliser = this.kapitaliser.bind(this);
    this.state = {
      oversiktFilter: "",
      visLangtFilter: false
    };
  };

  componentDidUpdate(prevProps) {
    let resultat = this.genererVisningsfilter();
    if (prevProps.sokETOResultatFilterETO !== null && prevProps.sokETOResultatFilterETO !== this.props.sokETOResultatFilterETO) {
      this.setState({oversiktFilter: resultat});
    }
  };

  /**
   * Går gjennom FilterETO og henter ut alle delene som har fått en verdi, altså er i bruk.
   * Basert på dette objektet genereres JSX
   * @returns {Array}
   */
  genererVisningsfilter() {
    const {sokETOResultatFilterETO} = this.props;
    let valgteFilterJSX = [];
    let valgteFilter = {};
    if (sokETOResultatFilterETO) {
      for (let mainObj of Object.entries(sokETOResultatFilterETO)) {
        let middleObject = [];
        for (let minorObj of Object.entries(mainObj[1])) { // minor er en del, slik som "kjønn", mens middle er en samling av minors
          if (minorObj[1] !== null) {
            middleObject.push(minorObj);
          }
        }
        valgteFilter[mainObj[0]] = middleObject;
      }
      for (let topElement of Object.entries(valgteFilter)) {
          for (let i = 0; i < topElement[1].length; i++) {
            valgteFilterJSX.push(<p className='mb-0'><b>
              {this.kapitaliser(topElement[1][i][0])}
              {NYE_FELTER.includes(this.kapitaliser(topElement[1][i][0])) ? ' (ny 2020)' : ''}
              {ENDREDE_FELTER.includes(this.kapitaliser(topElement[1][i][0])) ? ' (endret 2020)' : ''}
              </b></p>);
            for (let j = 0; j < topElement[1][i][1].length; j++) {
              if (topElement[1][i][1][j] && topElement[1][i][1][j].navn) {
                valgteFilterJSX.push(<p className='filtre mb-0'>
                  {topElement[1][i][1][j].navn}
                </p>)
              }
            }
          }
      }
    }
    return valgteFilterJSX;
  }

  /**
   * Sjekker strengens som sendes inn skal endres til et penere visningsnavn.
   * Spesial tilfeller er håndtert i switch blokk, mens default er at strengen kapitaliseres
   * @param s
   * @returns {string}
   */
  kapitaliser(s) {
    if (typeof s !== 'string') return '';
    // Først gjør string sjekk:
    switch (s.toLocaleLowerCase()) {
      case 'kjonn':
        return "Kjønn";
      case 'trafikanttyper':
        return "Trafikanttype";
      case 'femarigalderintervaller':
        return "Aldersgruppe 5-årig";
      case 'ssbalderintervaller':
        return "Aldersgruppe";
      case 'enhetstypehovedkategorier':
        return "Enhetstype hovedkategori";
      case 'enhetstypeunderkategorier':
        return "Enhetstype underkategori";
      case 'enhetstyper':
        return "Enhetstype";
      case 'arsmodeller':
        return "Årsmodeller";
      case 'maneder':
        return "Måneder";
      case 'tettsteder':
        return "Tettsted";
      case 'politidistrikt':
        return "Politidistrikt";
      case 'verforhold':
        return "Værforhold";
      case 'foreforhold':
        return "Føreforhold";
      case 'kjorefelttyper':
        return "Kjørefelttype";
      case 'reguleringvegkryss':
        return "Regulering i vegkryss";
      case 'reguleringgangfelt':
        return 'Regulering i gangfelt';
      case 'ar':
        return "År";
      case 'temperaturintervaller':
        return "Temperatur";
      default:
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
  }

  render() {
    const { visLangtFilter, oversiktFilter} = this.state;
    const {rad1, rad2, rad3, kolonne, datasett, sokETOResultat} = this.props;
    const visSkjulFilter = visLangtFilter ? "Skjul filtre " : "Vis alle filtre ";

    return (
      <Container fluid>
        <Row className="mb-2">
          <Col className="ml-4">
            <h3>Datasett</h3>
            <p>
              {(sokETOResultat && datasett && datasett === ANTALL_PERSONSKADER) ? "Personskader" : null}
              {(sokETOResultat && datasett && datasett === ANTALL_PERSONSKADEULYKKER) ? "Personskadeulykker" : null}
            </p>
          </Col>
          <Col className="ml-2">
            <h3>Rad</h3>
            <p className="mb-0">{sokETOResultat && rad1 && "Rad 1: " + rad1.navn}</p>
            <p className="mb-0">{rad2 && "Rad 2: " + rad2.navn}</p>
            <p className="mb-0">{rad3 && "Rad 3: " + rad3.navn}</p>
          </Col>
          <Col className="ml-2">
            <h3>Kolonne</h3>
            <p>{sokETOResultat && kolonne && kolonne.navn}</p>
          </Col>
          <Col className=" ml-2">
            {oversiktFilter && oversiktFilter.length > 0 &&
            <>
              <h3>Filter</h3>
              <p hidden={!oversiktFilter || !(oversiktFilter && oversiktFilter.length > 4)} title="Vis mer"
                 className="visMer" id="toggleButton" style={{marginBottom: "0.5rem"}} onClick={() => this.setState({visLangtFilter: !visLangtFilter})}
              >
                {visSkjulFilter} <span>{visLangtFilter ? <img src={up} alt=""/> : <img src={down} alt=""/>}</span>
              </p>
              <div hidden={!visLangtFilter && (oversiktFilter && oversiktFilter.length > 4)}>
                {oversiktFilter}
              </div>
            </>
            }
          </Col>
        </Row>
      </Container>
    )
  };
}
