import React from 'react';
import {Col, Row} from 'reactstrap';
import '../../standardkomponenter/trekkspill.css';
import {Ar, Fylke} from './RapportFilterInnhold';

export default class RapportFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Row>
          <Col className="pl-0" xs="12" lg="12" xl="12">
            <Ar />
          </Col>
          <Col className="pl-0" xs="12" lg="12" xl="12">
            <Fylke/>
          </Col>
        </Row>
      </div>
    );
  }
}

