import {connect} from 'react-redux';
import FilterNedtrekksliste from '../standardkomponenter/FilterNedtrekksliste';
import {
    AR,
    AR_ETO_NAVN,
    MANED,
    MANED_ETO_NAVN,
    TIMETYPE,
    TIMETYPE_ETO_NAVN,
    UKEDAG,
    UKEDAGSTYPE_ETO_NAVN,
    ULYKKE_ETO_NAVN
} from "../../ressurser/Konstanter";
import {selectAr, selectKodeverk, selectManed, selectSokETO, selectTimetype, selectUkedagstype} from "./datafiltreringSelector";
import {setFilterVerdi, setValgtFilter} from "../../actions";

const ulykkeFilterDispatch = dispatch => ({
    setFilter: (etoNavn, data) => {
        dispatch(setFilterVerdi(ULYKKE_ETO_NAVN, etoNavn, data));
    },
    setValg: (navn, valg) => {
        dispatch(setValgtFilter(navn, valg));
    }
});

const arState = state => ({
    navn: AR,
    etoNavn: AR_ETO_NAVN,
    erMulti: true,
    valgmuligheter: selectAr(state),
    visUtenKode: true
});
export const Ar = connect(
    arState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const manedState = state => ({
    navn: MANED,
    etoNavn: MANED_ETO_NAVN,
    erMulti: true,
    filter: selectSokETO(state),
    valgmuligheter: selectManed(state),
    kodeverkAlle: selectKodeverk(state)
});
export const Maned = connect(
    manedState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const ukedagstypeState = state => ({
    navn: UKEDAG,
    etoNavn: UKEDAGSTYPE_ETO_NAVN,
    erMulti: true,
    filter: selectSokETO(state),
    valgmuligheter: selectUkedagstype(state),
    kodeverkAlle: selectKodeverk(state)
});
export const Ukedagstype = connect(
    ukedagstypeState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);


const timetypeState = state => ({
    navn: TIMETYPE,
    etoNavn: TIMETYPE_ETO_NAVN,
    erMulti: true,
    valgmuligheter: selectTimetype(state)
});
export const Timetype = connect(
    timetypeState,
    ulykkeFilterDispatch
)(FilterNedtrekksliste);