export default function selectStandardrapporter(state) {
  return state.standardrapporter;
}

/* denne selector sjekker først om det er noen fylker valgt, hvis ja, så selecter den bare de kommuner som er i det valgte fylket */
export const selectKommuneRapport = state => {
  let kodeverk = null;
  let geografiskForholdKodeverk = null;
  let filtrerteKommuner = [];

  if (state.kodeverk && state.kodeverk.filterETO && state.kodeverk.filterETO.ulykke) {

    geografiskForholdKodeverk = state.kodeverk.filterETO.ulykke.geografiskForhold;
    kodeverk = state.kodeverk.filterETO.ulykke.kommuner;

    if (state.standardrapporter && state.standardrapporter.fylke && state.standardrapporter.fylke !== "Alle") {

      const fylkesNummer = state.standardrapporter.fylke.label.substring(0,2); // Må trekke ut første siffer fra Label for å finne fylkesnummer:

      geografiskForholdKodeverk.forEach(gk => {
          if (gk.fylkesNummer.toString() === fylkesNummer) {
            kodeverk.forEach(k => {
              if (gk.kommuneNummer === k.kode) {
                filtrerteKommuner.push(k)
              }
            })
          }
        }
      );
      return filtrerteKommuner.sort(sorterKodeverkKommune)
    }
    return kodeverk.sort(sorterKodeverkKommune)
  }
  return kodeverk;
};

const sorterKodeverkKommune = (a, b) => {
  let sorteringen = 0;
  if (a.kommuneNummer >= b.kommuneNummer) {
    sorteringen = 1;
  } else if (a.kommuneNummer <= b.kommuneNummer) {
    sorteringen = -1
  }
  return sorteringen
};
