import tidsbegrensetHent from '../tidsbegrensetHent';
import {URL_BACKEND} from '../../../middleware/konstanter';

export const SETT_AR = "SETT_AR";
export const SETT_FYLKE = "SETT_FYLKE";
export const SETT_KOMMUNE = "SETT_KOMMUNE";
export const SETT_BY = "SETT_BY";
export const SETT_BYDEL = "SETT_BYDEL";
export const SETT_AR_PUBLISERT = "SETT_AR_PUBLISERT";
export const HENTER_STANDARDRAPPORTER = "HENTER_STANDARDRAPPORTER";
export const MOTTA_TILGJENGELIGE_RAPPORTER = "MOTTA_TILGJENGELIGE_RAPPORTER";
export const MOTTA_FYLKER = "MOTTA_FYLKER";

export function settAr(ar) {
  return {
    type: SETT_AR,
    ar
  };
}

export function settFylke(fylke) {
  return {
    type: SETT_FYLKE,
    fylke
  };
}

export function settKommune(kommune) {
  return {
    type: SETT_KOMMUNE,
    kommune
  };
}

export function settHentStandardrapporter(henter) {
  return {
    type: HENTER_STANDARDRAPPORTER,
    henter
  };
}

export function mottaTilgjengeligeRapporter(rapporter, datotid) {
  return {
    type: MOTTA_TILGJENGELIGE_RAPPORTER,
    rapporter,
    datotid
  };
}

export function hentTilgjengeligeRapporter(ar, fylke, kommune) {
  return (dispatch) => {
    dispatch(settHentStandardrapporter(true));
    const header = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    return tidsbegrensetHent(
      fetch(URL_BACKEND + "/standardrapport/hentTilgjengelige/" + ar + "/" + fylke + "/" + kommune, {
        method: "GET",
        headers: header,
      })
    )
      .then(
        svar => {
          dispatch(settHentStandardrapporter(false));
          if (svar.ok) return svar.json();
          return [];
        })
      .then(data => {
        const datotid = new Date();
        dispatch(mottaTilgjengeligeRapporter(data, datotid));
      })
      .catch(error => {
        console.error("Klarte ikke å hente filterkriterier: ", error);
      });
  };
}

