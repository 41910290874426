import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Linkkort = ({ tittel, ikon, ikonTekst, link, id }) => {

    //sjekker om kortet linker til en ekstern side eller ikke
    const erEksternNettside = (url) => {
        try { 
            return Boolean(new URL(url)); 
        }
        catch(e){ 
            return false; 
        }
    }

    //returnerer kortets hovedelementer (uavhengig av linktype)
    const hovedInnhold = () => {
        return (
            <>
                <h2><span>{tittel}</span></h2>
                <div className="link-card__img-container">
                    <img className="icon" src={ikon} alt="" width="200"/>
                </div>
            </>
        )
    }
        
    return (
        <article className="link-card link-card--emphasised" style={{height:"inherit"}}>
            {erEksternNettside(link) ? 
                (<a href={link} target="_blank" rel="noopener noreferrer" aria-describedby={id}>
                    {hovedInnhold()}
                </a>) 
                :
                (<Link aria-describedby={id} to={link}>
                    {hovedInnhold()}
                </Link>)}
            <p id={id}>{ikonTekst}</p>
        </article>
    );
};

Linkkort.propTypes = {
    tittel: PropTypes.string.isRequired,
    ikon: PropTypes.string.isRequired,
    ikonTekst: PropTypes.string,
    link: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired

}

Linkkort.defaultProps = {
    ikonTekst: ""
}

export default Linkkort;