import {
  SET_BRUKER
} from "../actions/actionTypes";


/**
 *
 * Reducer som oppdaterer logget person
 */
const bruker = (state = [], action) => {
  switch (action.type) {
    case SET_BRUKER:
      return {
            bruker: action.data
          };
      default:
          return state;

  }
};

export default bruker;