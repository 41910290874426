import React from 'react';

const Skiplenke = () => {
    return (
        <div className="site-skip-links">
            <a href="#main" className="skiplinks _js-skiplink-main"> 
                <span>Hopp til innhold</span>
            </a>
        </div>
    );
};

export default Skiplenke;