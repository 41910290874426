export const selectBrukerTyper = state => {
  let brukertyper = null;
  if (state.kodeverk && state.kodeverk.brukertyper) {
    brukertyper = state.kodeverk.brukertyper;
  }
  return brukertyper;
};

export const selectBruker = state => {
  let brukertyper = null;
  if (state.bruker) {
    brukertyper = state.bruker;
  }
  return brukertyper;
};
